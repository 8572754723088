import { watch } from 'vue';
import { tryOnUnmounted, useScrollLock } from '@vueuse/core';

import { UseToggle, useToggle } from './useToggle';

export type UseModal = {
  open: UseToggle['value'];
  toggleOpen: UseToggle['toggle'];
  setOpen: UseToggle['setOn'];
  setClosed: UseToggle['setOff'];
};

const modals: UseModal[] = [];

export const useModal = (): UseModal => {
  const { value: open, toggle: toggleOpen, setOn: setOpen, setOff: setClosed } = useToggle();

  const modal: UseModal = {
    open,
    toggleOpen,
    setOpen,
    setClosed,
  };

  modals.push(modal);

  const scrollLock = useScrollLock(document.body);

  watch(open, () => {
    const isAnyOpen = Boolean(modals.find((modal) => modal.open.value));

    if (isAnyOpen) {
      scrollLock.value = true;
    } else {
      scrollLock.value = false;
    }
  });

  tryOnUnmounted(() => {
    setClosed();
  });

  return modal;
};
