import { HazardType, HazardView, MapMode, SelectedHazardViewType, hazardTypeRoute, hazardTypeView, useHazardsStore } from '@/features';
import { showBothHazardView } from '@/common';
import { LocationQuery, LocationQueryValue, RouteParams } from 'vue-router';

/**
 * Handles the request for a hazard by its ID.
 * Additionally, it sets the active view, date, and selected hazard in the store.
 * @param params
 * @param query
 */
export const handleRouteChanges = async (params: RouteParams, query: LocationQuery) => {
  const hazardsStore = useHazardsStore();

  const hazardType = parseRouteParams(params?.hazardType);
  const hazardId = parseRouteParams(params?.hazardId);
  const view = parseQueryParams(query?.view);
  const date = parseQueryParams(query?.date);
  const display = parseQueryParams(query?.display);

  // View
  let sanitisedView = Object.values(HazardView).find((v) => v.toLowerCase() === view?.toLowerCase());

  // If the view is set to 'both' and the screen size is too small(mobile), default to 'map'
  // this needs to be done here as this value is used further down in the code
  if (!showBothHazardView.value && sanitisedView === HazardView.Both) {
    sanitisedView = HazardView.Map;
  }

  if (sanitisedView) {
    hazardsStore.setActiveView(sanitisedView);
  }

  // Date
  const sanitisedDate = Number(date);

  // Display
  const sanitisedDisplay = Object.values(SelectedHazardViewType).find((d) => d.toLowerCase() === display?.toLowerCase());

  // Hazard type
  const sanitisedHazardType = getSanitisedHazardType(hazardType);

  if (sanitisedHazardType) {
    switch (sanitisedHazardType) {
      case HazardType.TotalFireBans:
        hazardsStore.setMapMode(MapMode.FireBan);
        break;
      case HazardType.FireDangerRating:
        hazardsStore.setMapMode(MapMode.FireDanger);
        break;
    }

    if (sanitisedDate >= 0) {
      hazardsStore.setSelectedDateIndex(sanitisedDate);
    }

    if (hazardId) {
      hazardsStore.handleSelectedHazard({
        hazardType: sanitisedHazardType,
        hazardId,
        eventId: '',
        viewType: sanitisedDisplay ?? hazardTypeView(sanitisedHazardType),
      });
    }
  }
};

/**
 * Parses the route parameters and return as a single string.
 * @param param - The route parameter to parse
 * @returns The parsed route parameter
 */
const parseRouteParams = (param: string | string[]): string | undefined => {
  if (Array.isArray(param)) {
    return param[0];
  }

  return param;
};

/**
 * Parses the query parameters and returns as a single string.
 * @param param - The query parameter to parse.
 * @returns The parsed query parameter.
 */
const parseQueryParams = (param: LocationQueryValue | LocationQueryValue[]): string | undefined => {
  if (Array.isArray(param)) {
    return param[0] ? param[0] : undefined;
  }

  return param ?? undefined;
};

export const getSanitisedHazardType = (hazardType?: string | string[]): HazardType | undefined => {
  if (!hazardType?.length) return undefined;

  const type = Array.isArray(hazardType) ? hazardType?.at(0) : hazardType;
  return Object.values(HazardType).find((hType) => {
    if (hType === HazardType.TotalFireBans) {
      return type?.toLowerCase() === hazardTypeRoute(hType);
    }

    if (hType === HazardType.FireDangerRating) {
      return type?.toLowerCase() === hazardTypeRoute(hType);
    }

    if (hType === HazardType.PrescribedBurns) {
      return type?.toLowerCase() === hazardTypeRoute(hType);
    }

    return hType.toLowerCase() === type?.toLowerCase();
  });
};

/**
 * Maps the hazard type to the route title.
 */
export const HazardTypeToRouteTitleMap: Record<HazardType, string> = {
  [HazardType.Warning]: 'Warnings',
  [HazardType.Incident]: 'Incidents',
  [HazardType.Closure]: 'Closures',
  [HazardType.Centre]: 'Centres',
  [HazardType.Event]: 'Events',
  [HazardType.TotalFireBans]: 'Total Fire Bans',
  [HazardType.FireDangerRating]: 'Fire Danger Ratings',
  [HazardType.PrescribedBurns]: 'Prescribed Burns',
};
