import { HazardType } from '@/features';

export enum SelectedHazardViewType {
  Modal = 'modal',
  Popup = 'popup',
}

export type SelectedHazard = {
  hazardId: string;
  hazardType: HazardType;
  eventId: string;
  viewType?: SelectedHazardViewType;
};
