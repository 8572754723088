// timerService.ts
import { ref, onUnmounted } from 'vue';

// This constant is used to set the interval of the timer.
// It is set to 30 seconds.
const TIME_INTERVAL = 30000;

const timeTrigger = ref(Date.now());

const startTimer = () => {
  const interval = setInterval(() => {
    timeTrigger.value = Date.now();
  }, TIME_INTERVAL);

  onUnmounted(() => {
    clearInterval(interval);
  });
};

const setTimeTrigger = (value: number) => (timeTrigger.value = value);

export { timeTrigger, startTimer, setTimeTrigger };
