import { RouteConfig } from '@/common';

export const hamburgerMenuRoutes: RouteConfig[] = [
  {
    title: 'Prepare',
    link: 'https://www.dfes.wa.gov.au/emergencywa/prepare',
    routeType: 'external',
  },
  {
    title: 'Warnings & Incidents',
    link: '/',
    routeType: 'internal',
  },
  {
    title: 'Recovery',
    link: 'https://www.dfes.wa.gov.au/emergencywa/recovery',
    routeType: 'external',
  },
  {
    title: 'Australian Warning System',
    link: 'https://emergency.wa.gov.au/aws',
    routeType: 'external',
  },
  {
    title: 'Map icons',
    link: '/map-icons',
    routeType: 'internal',
  },
  {
    title: 'Map guide',
    link: '/map-guide',
    routeType: 'internal',
  },
  {
    title: 'View text version of warnings and incidents',
    link: '/text-version',
    routeType: 'internal',
  },
  {
    title: 'About this site',
    link: '/about',
    routeType: 'internal',
  },
  {
    title: 'FAQ',
    link: 'https://www.dfes.wa.gov.au/emergencywa/faq',
    routeType: 'external',
  },
];

export const headerNavBarRoutes: RouteConfig[] = [
  {
    title: 'Prepare',
    link: 'https://www.dfes.wa.gov.au/emergencywa/prepare',
    routeType: 'external',
  },
  {
    title: 'Warnings and incidents',
    link: '/',
    routeType: 'internal',
  },
  {
    title: 'Recovery',
    link: 'https://www.dfes.wa.gov.au/emergencywa/recovery',
    routeType: 'external',
  },
  {
    title: 'Australian Warning System',
    link: 'https://emergency.wa.gov.au/aws',
    routeType: 'external',
  },
];
