import { GeocodeRepository, GeocodingResponseFeatures, GeocodingResponseModel, MapboxDataSource } from '@/features/hazards';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/lib/function';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const formatAddress = (features: GeocodingResponseFeatures, separator: string) => {
  const compactedAddress = `${features.properties.name}${separator}${features.properties.place_formatted}`.replace(
    'Western Australia',
    'WA'
  );
  return compactedAddress.substring(0, compactedAddress.length - ', Australia'.length);
};

export const useGeocodeStore = defineStore('geocodeStore', () => {
  // TODO: refact to use entity instead of model
  const geocoding = ref<GeocodingResponseModel>();
  const geocodedPosition = ref<GeocodingResponseModel>();

  const mapboxDataSource = new MapboxDataSource();
  const geocodeRepository = new GeocodeRepository(mapboxDataSource);

  const isLoading = ref(false);

  const clearGeocoding = () => (geocoding.value = undefined);

  const getForwardGeocoding = async (query: string) => {
    isLoading.value = true;

    const maybeGeocode = await geocodeRepository.getForwardGeocoding(query);
    pipe(
      maybeGeocode,
      fold(
        () => {
          // toast.addToast({
          //   text: mapErrorToMessage(error),
          //   scheme: 'error',
          // });
        },
        (geocodeResponseModel) => {
          geocoding.value = geocodeResponseModel;
        }
      )
    );

    isLoading.value = false;
  };

  const getReverseGeocoding = async (latitude: number, longitude: number) => {
    const maybeGeocode = await geocodeRepository.getReverseGeocoding(latitude, longitude);
    pipe(
      maybeGeocode,
      fold(
        () => {
          // toast.addToast({
          //   text: mapErrorToMessage(error),
          //   scheme: 'error',
          // });
        },
        (geocodeResponseModel) => {
          geocodedPosition.value = geocodeResponseModel;
        }
      )
    );
  };

  return {
    isLoading,
    geocoding,
    geocodedPosition,
    clearGeocoding,
    getForwardGeocoding,
    getReverseGeocoding,
  };
});
