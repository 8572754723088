<template>
  <div class="flex w-full grow flex-col gap-4 overflow-y-auto p-4">
    <div class="flex justify-between gap-2">
      <div class="flex gap-4">
        <div class="flex items-center">
          <img class="size-10" :src="selectedPrescribedBurn?.icon" alt="" />
        </div>
        <div class="flex flex-1 flex-col">
          <div class="text-wrap text-xl font-medium text-type-primary">
            {{ selectedPrescribedBurn?.title }}
          </div>
          <div class="text-base text-type-secondary">
            {{ selectedPrescribedBurn?.burnLocation }}
          </div>
        </div>
      </div>

      <button
        v-if="!hideCloseButton"
        class="flex size-6 min-h-6 min-w-6 cursor-pointer items-center justify-center"
        @click="map.closePopup()"
      >
        <close-rounded aria-label="Close" class="*:fill-type-primary" />
      </button>
    </div>

    <div class="h-px w-full bg-grey-35"></div>

    <ul class="ml-2 grow list-inside list-disc text-base text-type-secondary">
      <li v-if="selectedPrescribedBurn?.burnStatus">
        <span>Status: {{ selectedPrescribedBurn.burnStatus }}</span>
      </li>
      <li v-if="selectedPrescribedBurn?.burnStatus">
        <span>Location: {{ selectedPrescribedBurn.burnLocation }}</span>
      </li>
      <li>
        <span>Managed by: Parks and Wildlife</span>
      </li>
      <template v-if="showAdditionalInformation">
        <li v-if="selectedPrescribedBurn?.burnTargetDate">
          <span>Targeted date: {{ selectedPrescribedBurn.burnTargetDate }}</span>
        </li>
        <li v-if="selectedPrescribedBurn?.burnEstimatedStart">
          <span>Estimated start: {{ selectedPrescribedBurn?.burnEstimatedStart }}</span>
        </li>
        <li v-if="selectedPrescribedBurn?.burnArea">
          <span>Indicative area: {{ parseFloat(selectedPrescribedBurn?.burnArea).toFixed(1) }}</span>
        </li>
        <li v-if="selectedPrescribedBurn?.burnPlannedAreaToday">
          <span>Planned area for today: {{ selectedPrescribedBurn?.burnPlannedAreaToday }}</span>
        </li>
        <li v-if="selectedPrescribedBurn?.burnPlannedDistanceToday">
          <span>Planned distance for today: {{ selectedPrescribedBurn?.burnPlannedDistanceToday }}</span>
        </li>
        <li v-if="selectedPrescribedBurn?.burnForestBlocks">
          <span>Forest blocks: {{ selectedPrescribedBurn?.burnForestBlocks }}</span>
        </li>
        <li v-if="selectedPrescribedBurn?.burnPurpose">
          <span>Purpose: {{ selectedPrescribedBurn?.burnPurpose }}</span>
        </li>
      </template>
    </ul>

    <ui-map-popup-footer
      v-if="showFooter"
      left-text="Zoom map"
      right-text="FAQ"
      :left-callback="
        () =>
          map.flyToPosition({
            zoom: 13,
            center: (selectedPrescribedBurn?.pointFeature?.geometry as Point).coordinates as LngLatLike,
          })
      "
      :right-callback="navigateToFAQ"
    />
  </div>
</template>

<script setup lang="ts">
import CloseRounded from '@/common/assets/icons/close-rounded.svg';
import { useHazardsStore, useMapStore } from '@/features/hazards';
import { Point } from 'geojson';
import { LngLatLike } from 'mapbox-gl';
import { computed, ref } from 'vue';

const map = useMapStore();
const hazards = useHazardsStore();

withDefaults(
  defineProps<{
    showFooter?: boolean;
    hideCloseButton?: boolean;
  }>(),
  {
    showFooter: true,
    hideCloseButton: false,
  }
);

const showAdditionalInformation = ref(false);

const selectedPrescribedBurn = computed(() => {
  return hazards?.hazards?.prescribedBurns.find((prescribedBurn) =>
    [map.selectedPopupHazard?.hazardId, hazards.selectedHazard?.hazardId].includes(prescribedBurn.id)
  );
});

const navigateToFAQ = () => {
  window.open('https://www.dfes.wa.gov.au/emergencywa/faq');
};
</script>
