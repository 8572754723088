import { Configuration } from './types';

/**
 * Internal UAT environment configuration.
 * This points at the UAT environment (i.e. all services referenced below are running/hosted in the UAT environment).
 */
export const config: Partial<Configuration> = {
  portalApi: 'https://ewa.api.uat.portal.genvis.com/v1',
  remoteHazardAssets: {
    iconAssetsPathSvg: 'https://uat.kudo.genvis.com/assets/icons/map/pins',
    iconAssetsPathPng: 'https://uat.kudo.genvis.com/assets/icons/map/pins/40x40',
  },
  geospatialBucket: 'https://d19wxpgkc9zeiw.cloudfront.net',
  externalTileBaseUrl: 'https://d9q2jja3olk39.cloudfront.net',
  webTemplateStyleSheetUrl: 'https://uat.kudo.genvis.com/assets/styles/ewa-webtemplate.css',
  environment: 'uat',
};
