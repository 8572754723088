<template>
  <teleport to="body">
    <transition
      class="transition-opacity duration-150"
      enter-from-class="opacity-0"
      enter-to-class="opacity-1"
      leave-from="opacity-1"
      leave-to-class="opacity-0"
    >
      <div
        v-if="modal.open.value"
        v-bind="$attrs"
        class="fixed left-0 top-0 z-modal flex h-dvh w-screen flex-col bg-[rgba(0,0,0,0.25)] py-8"
      >
        <focus-trap v-on-click-outside="onClickOutside" :active="modal.open.value">
          <div class="m-auto overflow-y-auto rounded-lg bg-white shadow-md" :class="`${sizeClass}`">
            <slot name="header">
              <gv-modal-header :modal="modal" :title="title" />
            </slot>

            <slot />
            <slot name="footer" />
          </div>
        </focus-trap>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts" setup>
import { PropType, computed, watch } from 'vue';
import { FocusTrap } from 'focus-trap-vue';
import { vOnClickOutside } from '@vueuse/components';

import { UseModal } from '@/common/composables/useModal';
import { Size } from './types';
import { usePoller } from '@/common/composables';
import { Poller } from '@/features';

const emit = defineEmits(['open', 'close']);

const props = defineProps({
  modal: {
    type: Object as PropType<UseModal>,
    required: true,
  },
  size: {
    type: String as PropType<Size>,
    default: 'md',
  },
  title: {
    type: String,
  },
  customSizeClass: {
    type: String,
  },
  closeOnClickOutside: {
    type: Boolean,
    default: true,
  },
});

const poller = usePoller();

const sizeClasses: Record<Size, string> = {
  sm: 'w-[22.5rem]',
  md: 'w-[30rem]',
  lg: 'w-[34.5rem]',
  xl: 'h-full mx-[2rem]',
  custom: '',
};

const sizeClass = computed(() => sizeClasses[props.size] || props.customSizeClass);

watch(props.modal.open, () => {
  emit(props.modal.open.value ? 'open' : 'close');

  if (props.modal.open.value) {
    poller.changeInterval(Poller.fetchAllHazards, 3 * 60 * 1000); // 3 minutes
  } else {
    poller.changeInterval(Poller.fetchAllHazards, 60 * 1000); // 1 minute
  }

  poller.startPoller(Poller.fetchAllHazards);
});

const onClickOutside = () => {
  props.modal.setClosed();
};
</script>
