<template>
  <div class="flex flex-col gap-2">
    <div class="flex justify-between gap-2">
      <div class="text-wrap text-base font-semibold text-type-primary">
        {{ issuingAgencyContent }}
      </div>

      <button class="flex size-6 min-h-6 min-w-6 cursor-pointer items-center justify-center" @click="emit('close')">
        <close-rounded aria-label="Close" class="*:fill-type-primary" />
      </button>
    </div>

    <div v-if="eventNote" class="text-base font-semibold text-error" v-html="useSanitiseHtml(eventNote)"></div>
    <div class="text-sm text-type-secondary">Issued {{ publishedAtFormatted }}</div>
  </div>
</template>

<script setup lang="ts">
import { useSanitiseHtml } from '@/common';
import CloseRounded from '@/common/assets/icons/close-rounded.svg';
import { EventEntity } from '@/features/hazards';
import { DateTime } from 'luxon';
import { computed } from 'vue';

const props = defineProps<{
  event?: EventEntity;
}>();

const emit = defineEmits<(event: 'close') => void>();

const publishedAtFormatted = computed(() => {
  if (!props.event?.publishedAt) {
    return '';
  }

  const timestamp = DateTime.fromISO(props.event?.publishedAt);

  const format = timestamp.toFormat('d LLLL yyyy h:mm a');
  return format;
});

const eventNote = computed(() => {
  return props.event?.rawEvent?.['event-note'];
});

const issuingAgencyContent = computed(() => {
  const issuingAgency = props.event?.rawEvent?.['issuing-agency'];

  if (!issuingAgency) {
    return '';
  }

  return `The following alert has been issued by the ${issuingAgency}.`;
});
</script>
