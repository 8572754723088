<template>
  <router-link v-if="routeType === 'internal'" :to="link" replace>
    <div class="flex justify-between border-b border-b-grey-100 pb-4 hover:bg-grey-15 active:bg-grey-15">
      <div class="font-semibold text-type-secondary">
        {{ title }}
      </div>
      <gv-icon name="chevron_right" class="fill-grey-400" />
    </div>
  </router-link>

  <a v-else-if="routeType === 'external'" :href="link" target="_blank" rel="noopener noreferrer">
    <div class="flex justify-between border-b border-b-grey-100 pb-4 hover:bg-grey-15 active:bg-grey-15">
      <div class="font-semibold text-type-secondary">
        {{ title }}
      </div>
      <gv-icon name="chevron_right" class="fill-grey-400" />
    </div>
  </a>

  <button v-else class="flex justify-between border-b border-b-grey-100 pb-4" @click="emit('clickModal')">
    <div class="flex justify-between border-b border-b-grey-100 pb-4 hover:bg-grey-15 active:bg-grey-15">
      <div class="font-semibold text-type-secondary">
        {{ title }}
      </div>
      <gv-icon name="chevron_right" class="fill-grey-400" />
    </div>
  </button>
</template>

<script setup lang="ts">
import { RouteConfig } from '@/common/types';

defineProps<RouteConfig>();

const emit = defineEmits(['clickModal']);
</script>
