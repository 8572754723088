import { MapboxSource, useMapStore } from '@/features/hazards';
import { Polygon } from 'geojson';
import { LngLatLike, Marker } from 'mapbox-gl';
import { h, render } from 'vue';
import UiMapIncidentAreaMarker from '../components/UiMap/UiMapIncidentAreaMarker/UiMapIncidentAreaMarker.vue';

/**
 * TODO: Potentially refactor this function to be more generic and reusable?
 * Incident hazards that have a polygon feature need to be accompanied by a marker
 * that represents the last time the feature was updated.
 *
 * Lacking any other information, the marker will be placed at the center of the polygon.
 *
 * This function is a copy of the updateClusterMarkers function, with the necessary
 * changes to work with incident areas.
 */
export const updateIncidentAreaMarkers = async () => {
  const map = useMapStore();
  const mapbox = map.mapbox!;

  const newMarkers: Record<string, Marker> = {};
  const source = mapbox.getSource(MapboxSource.hazardsFireShapePolygonsSource);

  const shouldShowMarkers = mapbox.getZoom() > 10;

  if (!source || source.type != 'geojson') return;

  const features = mapbox.querySourceFeatures(MapboxSource.hazardsFireShapePolygonsSource);

  if (shouldShowMarkers) {
    // for every feature on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {
      const coordinates = (feature.geometry as Polygon).coordinates[0][0] as LngLatLike;
      const incidentId: string = feature.properties!.id;

      let marker = map.incidentAreaMarkers[incidentId];

      if (!marker) {
        const el = document.createElement('div');
        el.className = 'fire-shape-marker';

        marker = map.incidentAreaMarkers[incidentId] = new Marker(el).setLngLat(coordinates);

        const popupComponent = h(UiMapIncidentAreaMarker, {
          incidentId,
          marker,
          coordinates,
        });
        render(popupComponent, el);
      }

      newMarkers[incidentId] = marker;
      if (!map.incidentAreaMarkersOnScreen[incidentId]) marker.addTo(mapbox);
    }
  }

  // for every marker we've added previously, remove those that are no longer visible
  for (const id in map.incidentAreaMarkersOnScreen) {
    if (!newMarkers[id] || !shouldShowMarkers) map.incidentAreaMarkersOnScreen[id].remove();
  }
  map.incidentAreaMarkersOnScreen = newMarkers;
};
