<template>
  <footer class="hidden h-footer justify-between bg-primary-500 px-6 text-white md:flex">
    <div class="flex items-center">
      <div class="flex items-center gap-4">
        <router-link :to="textVersionButtonContent.routePath">
          <button class="flex h-8 items-center gap-1 rounded-[4px] border border-white px-2">
            <gv-icon :name="textVersionButtonContent.icon" class="fill-white" />
            <div>{{ textVersionButtonContent.content }}</div>
          </button>
        </router-link>
        <nav class="flex">
          <div v-for="(appRoute, index) in footerRoutes" :key="appRoute.title" class="flex">
            <ui-footer-nav-item v-if="appRoute.routeType == 'internal'" :link="appRoute.link" :title="appRoute.title" />
            <a v-else-if="appRoute.routeType == 'external'" class="underline" :href="appRoute.link" target="_blank" external>{{
              appRoute.title
            }}</a>
            <div v-if="index !== footerRoutes.length - 1" class="mx-4 h-6 w-px bg-primary-100" />
          </div>
        </nav>
      </div>
    </div>

    <div class="flex items-center">
      <ui-social />
    </div>
  </footer>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { footerRoutes } from './index';

const route = useRoute();

const textVersionButtonContent = computed(() => {
  if (route.fullPath.startsWith('/text-version')) {
    return {
      content: 'View full site',
      icon: 'web',
      routePath: '/',
    };
  }
  return {
    content: 'View text version of warnings and incidents',
    icon: 'abc',
    routePath: '/text-version',
  };
});
</script>
