<template>
  <ul class="ml-2 grow list-inside list-disc text-base text-type-secondary">
    <li v-if="reportedAtFormatted">Time reported: {{ reportedAtFormatted }}</li>
    <li>Last updated: <ui-relative-time :time="selectedIncident?.updatedAt" /></li>
    <li>Response: {{ selectedIncident?.incidentStatus }}</li>
    <li v-if="selectedIncident?.reportedNear">Reported near: {{ selectedIncident?.reportedNear }}</li>
    <li v-else-if="selectedIncident?.reportedIn">Reported in: {{ selectedIncident?.reportedIn }}</li>
  </ul>
</template>

<script setup lang="ts">
import { getDatetimeOrdinalSuffix } from '@/common/helpers';
import { useHazardsStore, useMapStore } from '@/features/hazards';
import { DateTime } from 'luxon';
import { computed } from 'vue';

const map = useMapStore();
const hazards = useHazardsStore();

const selectedIncident = computed(() => {
  return hazards?.hazards?.incidents.find((incident) =>
    [hazards.selectedHazard?.hazardId, map.selectedPopupHazard?.hazardId].includes(incident.id)
  );
});

const reportedAtFormatted = computed(() => {
  if (!selectedIncident?.value?.reportedAt) {
    return '';
  }

  const timestamp = DateTime.fromISO(selectedIncident.value.reportedAt);
  // time in am/pm format
  const format1 = timestamp.toFormat('h:mm');
  const format2 = timestamp.toFormat('a').toLowerCase();

  // day
  const format3 = timestamp.toFormat('EEEE d');
  const format4 = getDatetimeOrdinalSuffix(timestamp.day);

  // month
  const format5 = timestamp.toFormat('MMMM');

  const formattedDateTime = `${format1}${format2}, ${format3}${format4} ${format5}`;

  return formattedDateTime;
});
</script>
