<template>
  <link rel="preload stylesheet" as="style" :href="config.webTemplateStyleSheetUrl" />
  <ui-view-provider>
    <ui-app-header />
    <main id="main-page-content-view">
      <router-view class="h-page-mobile md:h-page" :aria-hidden="menuExpanded" />
    </main>
    <ui-app-footer />
  </ui-view-provider>

  <gv-toasts class="fixed left-1/2 top-header z-toasts w-[500px] -translate-x-1/2" />
</template>

<script setup lang="ts">
import { useConfig, useEventBus } from './composables';
import { useHazardsStore } from './features';
import { ref } from 'vue';

useHazardsStore();
const config = useConfig();

const eventBus = useEventBus();

const menuExpanded = ref(false);

eventBus.onActionWithParameters('mobile-nav-menu-state', (params) => {
  if (params.value !== undefined) {
    menuExpanded.value = params.value;
  }
});
</script>
