import { AxiosSingleton } from '@/common';
import { GetFireDangersRatingsModel, GetPrescribedBurnsModel, S3HazardsDataSource } from '@/features/hazards';
import { decode } from '@msgpack/msgpack';
import { Axios } from 'axios';
import { container } from 'tsyringe';

export class RemoteS3HazardsDataSource implements S3HazardsDataSource {
  api: Axios;

  constructor() {
    this.api = container.resolve(AxiosSingleton).s3;
  }

  async getPrescribedBurns(): Promise<GetPrescribedBurnsModel> {
    const res = await this.api.get('/prescribed-burns-today.dat', {
      responseType: 'arraybuffer',
    });

    return decode(res.data) as GetPrescribedBurnsModel;
  }

  async getFireDangerRatings(): Promise<GetFireDangersRatingsModel> {
    const res = await this.api.get('/fdr-compressed.dat', {
      responseType: 'arraybuffer',
    });

    return decode(res.data) as GetFireDangersRatingsModel;
  }
}
