import mitt from 'mitt';
import { Ref, ref } from 'vue';

export type Events = {
  action: string;
  'action-with-parameters': {
    action: string;
    params: any;
  };
  'action-with-event': {
    action: string;
    event: Event;
  };
  'action-with-websocket': {
    action: string;
    ws?: WebSocket;
    event?: Event | CloseEvent | MessageEvent<any>;
  };
};

const emitter = ref(mitt<Events>());

type ActionMap<T> = Map<string, T[]>;

const actions = ref<ActionMap<() => void>>(new Map());
const actionsWithParameters = ref<ActionMap<(params: any) => void>>(new Map());
const actionsWithEvent = ref<ActionMap<(event: Event) => void>>(new Map());
const actionsWithWebSocket = ref<ActionMap<(ws?: WebSocket, event?: Event | CloseEvent | MessageEvent<any>) => void>>(new Map());

export const useEventBus = () => {
  const setup = () => {
    if (!emitter.value.all.has('action')) {
      emitter.value.on('action', (action) => {
        for (const listener of actions.value.get(action) ?? []) {
          listener();
        }
      });
    }

    if (!emitter.value.all.has('action-with-parameters')) {
      emitter.value.on('action-with-parameters', ({ action, params }): void => {
        for (const listener of actionsWithParameters.value.get(action) ?? []) {
          listener(params);
        }
      });
    }

    if (!emitter.value.all.has('action-with-event')) {
      emitter.value.on('action-with-event', ({ action, event }) => {
        for (const listener of actionsWithEvent.value.get(action) ?? []) {
          listener(event);
        }
      });
    }

    if (!emitter.value.all.has('action-with-websocket')) {
      emitter.value.on('action-with-websocket', ({ action, ws, event }) => {
        for (const listener of actionsWithWebSocket.value.get(action) ?? []) {
          listener(ws, event);
        }
      });
    }
  };

  const addActionListener = <T>(map: Ref<ActionMap<T>>, action: string, fn: T) => {
    map.value.set(action, [...(map.value.get(action) ?? []), fn]);
  };

  const clearActionListeners = <T>(map: Ref<ActionMap<T>>, action: string) => {
    map.value.delete(action);
  };

  const onAction = (action: string, fn: () => void) => {
    addActionListener(actions, action, fn);
  };

  const offAction = (action: string) => {
    clearActionListeners(actions, action);
  };

  const onActionWithParameters = (action: string, fn: (params: any) => void) => {
    addActionListener(actionsWithParameters, action, fn);
  };

  const offActionWithParameters = (action: string) => {
    clearActionListeners(actionsWithParameters, action);
  };

  const onActionWithEvent = (action: string, fn: (event: Event) => void) => {
    addActionListener(actionsWithEvent, action, fn);
  };

  const offActionWithEvent = (action: string) => {
    clearActionListeners(actionsWithEvent, action);
  };

  const onActionWithWebSocket = (action: string, fn: (ws?: WebSocket, event?: Event | CloseEvent | MessageEvent<any>) => void) => {
    addActionListener(actionsWithWebSocket, action, fn);
  };

  const offActionWithWebSocket = (action: string) => {
    clearActionListeners(actionsWithWebSocket, action);
  };

  setup();

  return {
    ...emitter.value,
    onAction,
    offAction,
    onActionWithParameters,
    offActionWithParameters,
    onActionWithEvent,
    offActionWithEvent,
    onActionWithWebSocket,
    offActionWithWebSocket,
  };
};
