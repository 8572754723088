import {
  HazardsDataSource,
  MockHazardsDataSource,
  MockS3HazardsDataSource,
  RemoteHazardsDataSource,
  S3HazardsDataSource,
  RemoteS3HazardsDataSource,
} from '@/features';
import { singleton } from 'tsyringe';

@singleton()
export class DatasourceSingleton {
  public hazards: HazardsDataSource;
  public s3Hazards: S3HazardsDataSource;

  constructor() {
    if (import.meta.env.VITE_MOCK_DATA) {
      this.hazards = new MockHazardsDataSource();
      this.s3Hazards = new MockS3HazardsDataSource();
    } else {
      this.hazards = new RemoteHazardsDataSource();
      this.s3Hazards = new RemoteS3HazardsDataSource();
    }
  }
}
