import { useConfig } from '@/composables';
import axios, { AxiosInstance } from 'axios';
import { singleton } from 'tsyringe';

const config = useConfig();

@singleton()
export class AxiosSingleton {
  public ewa: AxiosInstance;
  public s3: AxiosInstance;

  constructor() {
    this.ewa = axios.create({
      baseURL: config.value.portalApi,
    });
    this.s3 = axios.create({
      baseURL: config.value.geospatialBucket,
    });
  }
}
