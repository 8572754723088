import { AxiosSingleton } from '@/common/di';
import {
  GetCentresModel,
  GetClosuresModel,
  GetEventsModel,
  GetIncidentsModel,
  GetWarningByIdModel,
  GetWarningsModel,
  HazardsDataSource,
} from '@/features/hazards';
import { Axios } from 'axios';
import { container } from 'tsyringe';
import { GetTotalFireBansModel } from '../models/hazards/get-fireban-model';

export class RemoteHazardsDataSource implements HazardsDataSource {
  api: Axios;

  constructor() {
    this.api = container.resolve(AxiosSingleton).ewa;
  }

  async getClosures(): Promise<GetClosuresModel[]> {
    const res = await this.api.get('/closures');
    return res.data.closures;
  }

  async getCentres(): Promise<GetCentresModel[]> {
    const res = await this.api.get('/centres');
    return res.data.centres;
  }

  async getWarnings(): Promise<GetWarningsModel[]> {
    const res = await this.api.get('/warnings');
    return res.data.warnings;
  }

  async getIncidents(): Promise<GetIncidentsModel[]> {
    const res = await this.api.get('/incidents');
    return res.data.incidents;
  }

  async getEvents(): Promise<GetEventsModel[]> {
    const res = await this.api.get('/events');
    return res.data.events;
  }

  async getTotalFireBans(): Promise<GetTotalFireBansModel[]> {
    const res = await this.api.get('/total-fire-bans');
    return res.data.totalFireBans;
  }

  async getWarningById(warningId: string): Promise<GetWarningByIdModel> {
    const res = await this.api.get(`/warnings/${warningId}`);
    return res.data;
  }
}
