import { ref } from 'vue';

export type Poller = {
  interval: number;
  callback: () => void;
  instance?: NodeJS.Timeout;
};

const pollers = ref<Record<string, Poller>>({});

export const usePoller = () => {
  const registerPoller = (key: string, callback: () => void, interval: number) => {
    const poller = pollers.value[key];

    if (poller) {
      return;
    }

    pollers.value[key] = {
      interval,
      callback,
    };
  };

  const startPoller = (key: string) => {
    const poller = pollers.value[key];

    if (!poller) return;

    if (poller.instance) {
      clearInterval(poller.instance);
    }

    poller.instance = setInterval(() => {
      poller.callback();
    }, poller.interval);
  };

  const stopPoller = (key: string) => {
    clearInterval(pollers.value[key].instance);
    pollers.value[key].instance = undefined;
  };

  const togglePoller = (key: string) => {
    if (pollers.value[key].instance) {
      stopPoller(key);
    } else {
      startPoller(key);
    }
  };

  const changeInterval = (key: string, interval: number) => {
    const poller = pollers.value[key];

    if (!poller) {
      return;
    }

    poller.interval = interval;
  };

  const isPolling = (key: string) => {
    const poller = pollers.value[key];

    if (!poller) {
      return false;
    }

    return poller.instance !== undefined;
  };

  return {
    registerPoller,
    startPoller,
    stopPoller,
    togglePoller,
    isPolling,
    changeInterval,
  };
};
