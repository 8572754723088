import configurations from '@/config';
import { computed } from 'vue';
import { useEnvironment } from './environment';

export const useConfig = () => {
  const environment = useEnvironment();

  console.debug({ environment, hostname: window.location.hostname });

  return computed(() => {
    return configurations[environment.value];
  });
};
