import FdrCatastrophic from '@/common/assets/icons/hazards/fire-ratings/fire-rating-catastrophic.png';
import FdrExtreme from '@/common/assets/icons/hazards/fire-ratings/fire-rating-extreme.png';
import FdrHigh from '@/common/assets/icons/hazards/fire-ratings/fire-rating-high.png';
import FdrModerate from '@/common/assets/icons/hazards/fire-ratings/fire-rating-moderate.png';
import FdrNoRating from '@/common/assets/icons/hazards/fire-ratings/fire-rating-no-rating.png';

export const FDR_HAZARD_ICONS = {
  'ew-fdr-catastrophic': FdrCatastrophic,
  'ew-fdr-extreme': FdrExtreme,
  'ew-fdr-high': FdrHigh,
  'ew-fdr-moderate': FdrModerate,
  'ew-fdr-no-rating': FdrNoRating,
};
