import { useConfig } from '@/composables';
import { WESTERN_AUSTRALIA_BOUNDING_BOX } from '@/features';
import axios, { AxiosError } from 'axios';
import { GeocodingResponseModel } from '../models/geocoding-model';
import lgaCentres from '@/scripts/lga-centres.json';
import Fuse from 'fuse.js';
import { toSentenceCase } from '@/common/helpers';
const config = useConfig();

// TODO: extract to config
const mapboxAxios = axios.create({
  baseURL: 'https://api.mapbox.com/search/geocode/v6/',
});

const lgaFuzzer = new Fuse(lgaCentres.data.features, { keys: ['properties.searchName'], threshold: 0.3 });

export class MapboxDataSource {
  async getForwardGeocoding(searchTerm: string) {
    try {
      const lgaResults = lgaFuzzer.search(searchTerm).splice(0, 3);

      const lgaAsResponse = lgaResults.map((lga) => {
        const lgaSentenceCase = toSentenceCase(lga.item.properties.searchName)
          .split(' ')
          .map((word, i) => (i === 1 ? word.toLowerCase() : word))
          .join(' ');

        return {
          type: 'Feature',
          geometry: '',
          properties: {
            name: lgaSentenceCase,
            place_formatted: 'Western Australia, Australia',
            coordinates: {
              longitude: lga.item.geometry.coordinates[0],
              latitude: lga.item.geometry.coordinates[1],
            },
          },
        };
      });

      const response = await mapboxAxios.get<GeocodingResponseModel>('forward', {
        params: {
          q: searchTerm,
          access_token: config.value.mapbox.token,
          country: 'au',
          types: ['place', 'locality', 'neighborhood', 'street', 'address'],
          bbox: WESTERN_AUSTRALIA_BOUNDING_BOX,
          autocomplete: true,
          limit: 10,
        },
      });

      response.data.features = [...lgaAsResponse, ...response.data.features] as any;

      return response.data;
    } catch (e) {
      throw new Error((e as AxiosError).code);
    }
  }

  async getReverseGeocoding(latitude: number, longitude: number) {
    try {
      const response = await mapboxAxios.get<GeocodingResponseModel>('reverse', {
        params: {
          latitude,
          longitude,
          access_token: config.value.mapbox.token,
          country: 'au',
          types: ['street', 'address'],
        },
      });

      return response.data;
    } catch (e) {
      throw new Error((e as AxiosError).code);
    }
  }
}
