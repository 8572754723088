import { Configuration } from './types';

export const config: Partial<Configuration> = {
  portalApi: 'https://api.emergencywa.genvis.com/v1',
  sentry: {
    dsn: 'https://cf40a211f5f4e6805072722487e2373b@o4507253793816576.ingest.de.sentry.io/4507255077339216',
  },
  webTemplateStyleSheetUrl: 'https://genvis.com/assets/styles/ewa-webtemplate.css',
  environment: 'prd-gv',
};
