<template>
  <div class="flex w-full grow flex-col gap-4 overflow-y-auto p-4">
    <div class="flex justify-between gap-2">
      <div class="flex flex-1 gap-4">
        <div class="flex items-center">
          <img class="size-10" :src="selectedIncident?.icon" alt="" />
        </div>
        <div class="flex flex-1 flex-col">
          <div class="text-wrap text-xl font-medium text-type-primary">
            {{ selectedIncident?.title }}
          </div>
          <div class="text-base text-type-secondary">
            {{ selectedIncident?.description }}
          </div>
          <div class="text-xs font-medium text-type-secondary"><ui-relative-time :time="selectedIncident?.updatedAt" /></div>
        </div>
      </div>

      <button
        v-if="!hideCloseButton"
        class="flex size-6 min-h-6 min-w-6 cursor-pointer items-center justify-center"
        @click="map.closePopup()"
      >
        <close-rounded aria-label="Close" class="*:fill-type-primary" />
      </button>
    </div>

    <div class="h-px w-full bg-grey-35"></div>

    <ui-incident-content />

    <ui-map-popup-footer
      v-if="showFooter"
      left-text="Zoom map"
      right-text="FAQ"
      :left-callback="
        () =>
          map.flyToPosition({
            zoom: 13,
            center: (selectedIncident?.pointFeature?.geometry as Point).coordinates as LngLatLike,
          })
      "
      :right-callback="navigateToFAQ"
    />
  </div>
</template>

<script setup lang="ts">
import CloseRounded from '@/common/assets/icons/close-rounded.svg';
import { useHazardsStore, useMapStore } from '@/features/hazards';
import { Point } from 'geojson';
import { LngLatLike } from 'mapbox-gl';
import { computed } from 'vue';

withDefaults(
  defineProps<{
    showFooter?: boolean;
    hideCloseButton?: boolean;
  }>(),
  {
    showFooter: true,
    hideCloseButton: false,
  }
);

const map = useMapStore();
const hazards = useHazardsStore();

const selectedIncident = computed(() => {
  return hazards?.hazards?.incidents.find((incident) =>
    [map.selectedPopupHazard?.hazardId, hazards.selectedHazard?.hazardId].includes(incident.id)
  );
});

const navigateToFAQ = () => {
  window.open('https://www.dfes.wa.gov.au/emergencywa/faq');
};
</script>
