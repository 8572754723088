<template>
  <div class="flex w-full grow flex-col gap-4 overflow-y-auto p-4">
    <div class="flex flex-row justify-between">
      <div class="flex items-center gap-4">
        <img class="size-10" :src="scheme?.icon" alt="" />
        <div class="flex flex-1 flex-col">
          <gv-type uplifted-variant="subtitle-sm" :text="selectedDangerRating?.title" breakpoint="none" />
          <div class="text-xs text-type-secondary xs:text-base">
            <gv-type uplifted-variant="body-1" :text="forecast?.fireDanger" />
          </div>
        </div>
      </div>

      <button class="flex size-6 min-h-6 min-w-6 cursor-pointer items-center justify-center" @click="mapStore.closePopup()">
        <close-rounded aria-label="Close" class="*:fill-type-primary" />
      </button>
    </div>

    <div class="h-px w-full bg-grey-35"></div>

    <div class="flex max-h-[344px] flex-col gap-y-4 overflow-y-auto">
      <div>
        <gv-type uplifted-variant="heading" :text="`Danger rating: ${forecast?.fireDanger}`" breakpoint="none" />
        <ul class="ml-2 grow list-inside list-disc text-base text-type-secondary">
          <li>{{ date }}</li>
        </ul>
      </div>

      <div>
        <gv-type uplifted-variant="heading" text="Local government areas affected" breakpoint="none" />
        <ul class="ml-2 grow list-inside list-disc text-base text-type-secondary">
          <li v-for="lga of lgas" :key="lga">{{ lga }}</li>
        </ul>
      </div>

      <div class="flex justify-center py-2">
        <img
          v-show="forecast?.fireDanger === 'No Rating'"
          src="@/common/assets/images/fire-danger-rating-modal/no-rating.png"
          class="w-48"
          alt="fire danger no rating"
        />
        <img
          v-show="forecast?.fireDanger === 'Moderate'"
          src="@/common/assets/images/fire-danger-rating-modal/moderate.png"
          class="w-48"
          alt="fire danger moderate rating"
        />
        <img
          v-show="forecast?.fireDanger === 'High'"
          src="@/common/assets/images/fire-danger-rating-modal/high.png"
          class="w-48"
          alt="fire danger high rating"
        />
        <img
          v-show="forecast?.fireDanger === 'Extreme'"
          src="@/common/assets/images/fire-danger-rating-modal/extreme.png"
          class="w-48"
          alt="fire danger extreme rating"
        />
        <img
          v-show="forecast?.fireDanger === 'Catastrophic'"
          src="@/common/assets/images/fire-danger-rating-modal/catastrophic.png"
          class="w-48"
          alt="fire danger catastrophic rating"
        />
      </div>
    </div>

    <ui-map-popup-footer
      :show-top-border="false"
      left-text="Zoom map"
      right-text="View more"
      :left-callback="
        () => {
          mapStore.flyToPosition({
            zoom: 13,
            center: coordinates as LngLatLike,
          });
        }
      "
      :right-callback="
        () => {
          toggleSelectedHazard({
            hazardId: selectedDangerRating?.id!,
            eventId: '',
            hazardType: selectedDangerRating?.entityType!,
          });
        }
      "
    />
  </div>
</template>

<script setup lang="ts">
import { SelectedHazard, SelectedHazardViewType, useHazardsStore, useMapStore } from '@/features';
import districtLgaMapping from '@/scripts/map-district-to-lga/district-to-lga-mapping.json';
import { Point, pointOnFeature } from '@turf/turf';
import { DateTime } from 'luxon';
import { LngLatLike } from 'mapbox-gl';

import { computed } from 'vue';
import CloseRounded from '@/common/assets/icons/close-rounded.svg';

const mapStore = useMapStore();
const hazards = useHazardsStore();

const selectedDangerRating = computed(() => {
  // we are not actually matching the id, just the name of the lga here.
  return hazards?.hazards?.fireDangerRatings.find((fdr) => fdr.id === mapStore.selectedPopupHazard?.hazardId);
});

const date = computed(() => {
  const today = DateTime.now();

  const in2Days = today.plus({ days: 2 });
  const in3Days = today.plus({ days: 3 });

  const datesArray = ['Today', 'Tomorrow', in2Days.toFormat('dd MMM'), in3Days.toFormat('dd MMM')];

  return datesArray[hazards.selectedDateIndex];
});

const scheme = computed(() => {
  return selectedDangerRating.value?.pointFeature?.properties?.schemes[hazards.selectedDateIndex];
});

const forecast = computed(() => {
  return selectedDangerRating.value?.pointFeature?.properties?.forecasts[hazards.selectedDateIndex];
});

const coordinates = computed(() => {
  const feature = selectedDangerRating.value?.polygonFeatures?.at(0);

  return pointOnFeature(feature?.geometry as Point).geometry.coordinates;
});

const lgas = computed(() => {
  const district = selectedDangerRating.value?.title;
  if (!district) return [];

  return districtLgaMapping[district as keyof typeof districtLgaMapping] ?? [];
});

const toggleSelectedHazard = (hazard: SelectedHazard) => {
  hazards.toggleSelectHazard({ ...hazard, viewType: SelectedHazardViewType.Modal });
};
</script>
