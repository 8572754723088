import { HAZARD_MARKERS, OtherIncidentSubType, Scheme } from '@/features/hazards';

// TODO: This is probably safe to remove since we have introduced the usage of the `incident-icon` property
type OtherIncidentsToSchemeMap = Record<OtherIncidentSubType, Scheme>;

export const otherIncidentsToSchemeMap: OtherIncidentsToSchemeMap = {
  [OtherIncidentSubType.SmellOfGasOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-smell-of-gas'],
  },
  [OtherIncidentSubType.StructureDamageOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-structure-damage'],
  },
  [OtherIncidentSubType.ActiveAlarmOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-active-alarm'],
  },
  [OtherIncidentSubType.BurnOffOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-burn-off'],
  },
  [OtherIncidentSubType.FuelLeakOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-fuel-leak'],
  },
  [OtherIncidentSubType.ReportOfSmokeOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-report-of-smoke'],
  },
  [OtherIncidentSubType.RoadCrashOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-road-crash'],
  },
  [OtherIncidentSubType.VehicleFireOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-vehicle-fire'],
  },
  [OtherIncidentSubType.FireOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-fire'],
  },
  [OtherIncidentSubType.PrescribedBurnOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-prescribed-burn'],
  },
  [OtherIncidentSubType.FloodOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-flood'],
  },
  [OtherIncidentSubType.CycloneOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-cyclone'],
  },
  [OtherIncidentSubType.EarthquakeOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-earthquake'],
  },
  [OtherIncidentSubType.TsunamiOtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-tsunami'],
  },
};
