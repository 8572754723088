import { ApiFetchError, BaseError } from '@/common/errors';
import { GeocodeDataSource, GeocodingResponseModel } from '@/features/hazards';
import { captureException } from '@sentry/vue';
import { Either, left, right } from 'fp-ts/lib/Either';

// TODO: make an abstract class in domain repository and implement it here
export class GeocodeRepository {
  dataSource: GeocodeDataSource;

  constructor(dataSource: GeocodeDataSource) {
    this.dataSource = dataSource;
  }

  // TODO: The response should be a bespoke entity (like GeocodingResponseEntity)
  async getForwardGeocoding(searchTerm: string): Promise<Either<BaseError, GeocodingResponseModel>> {
    try {
      const response = await this.dataSource.getForwardGeocoding(searchTerm);
      return right(response);
    } catch (e) {
      captureException(e);

      return left(new ApiFetchError('addresses'));
    }
  }

  async getReverseGeocoding(latitude: number, longitude: number): Promise<Either<BaseError, GeocodingResponseModel>> {
    try {
      const response = await this.dataSource.getReverseGeocoding(latitude, longitude);
      return right(response);
    } catch (e) {
      captureException(e);

      return left(new ApiFetchError('addresses'));
    }
  }
}
