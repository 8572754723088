import { ref } from 'vue';
import { v4 } from 'uuid';

import { Toast } from '../components/types';

const toasts = ref<Toast[]>([]);

export const useToasts = () => {
  const addToast = (toast: Omit<Toast, 'id'>, duration = 4000) => {
    if (toast.suppressIfOffline && !navigator.onLine) {
      return;
    }

    const id = v4();

    toasts.value.unshift({
      id,
      ...toast,
    });

    if (!toast.persistent) {
      setTimeout(() => {
        closeToast(id);
      }, duration);
    }

    return id;
  };

  const closeToast = (id: Toast['id']) => {
    toasts.value = toasts.value.filter((toast) => toast.id !== id);
  };

  return {
    toasts,
    addToast,
    closeToast,
  };
};
