<template>
  <component :is="is" v-if="!isEmpty" :class="`${schemeClass} ${variantClass} ${wrapClass} ${sizeClass} ${alignClass}`">
    <slot>
      {{ text }}
    </slot>
  </component>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { useBreakpoints } from '@vueuse/core';
import { Align, DeprecatedVariant, Scheme, Size, Variant, Wrap } from './types';

import { BREAKPOINTS } from '@/constants';
import { name } from './constants';

export default defineComponent({
  name,
  props: {
    text: String,
    scheme: {
      type: String as PropType<Scheme>,
      default: 'primary',
    },
    breakpoint: {
      type: String as PropType<keyof typeof BREAKPOINTS | 'none' | 'always'>,
      default: 'sm',
    },
    variant: {
      type: String as PropType<DeprecatedVariant>,
      default: 'body-md',
    },
    upliftedVariant: {
      type: String as PropType<Variant>,
      default: 'deprecated',
    },
    size: {
      type: String as PropType<Size>,
      default: 'content',
    },
    wrap: {
      type: String as PropType<Wrap>,
      default: 'wrap',
    },
    align: {
      type: String as PropType<Align>,
      default: 'left',
    },
    domElement: {
      type: String as PropType<keyof HTMLElementTagNameMap> | undefined,
      default: undefined,
    },
  },
  setup(props, { slots }) {
    const breakpoints = useBreakpoints(BREAKPOINTS);
    const isMobile = computed(() =>
      props.breakpoint == 'none' ? false : props.breakpoint == 'always' || breakpoints.smaller(props.breakpoint)
    );

    const isEmpty = computed(() => (slots.default || props.text ? false : true));

    const schemeClasses: Record<Scheme, string> = {
      primary: 'text-type-primary',
      secondary: 'text-type-secondary',
      tertiary: 'text-type-tertiary',
      custom: '',
    };

    const deprecatedVariantClasses: Record<DeprecatedVariant, string> = {
      'display-xl': 'text-3xl sm:text-6xl font-visby font-extrabold',
      'display-lg': 'text-2xl sm:text-4xl font-visby font-extrabold',
      'display-md': 'text-lg sm:text-2xl font-visby font-bold',
      'display-sm': 'text-base sm:text-xl font-proxima-nova font-normal',
      'subtitle-xl': 'text-4xl sm:text-5xl font-medium leading-[3.5rem] font-proxima-nova',
      'subtitle-lg': 'text-2xl sm:text-[2rem] leading-10 font-proxima-nova',
      'subtitle-md': 'text-xl sm:text-2xl font-proxima-nova',
      'subtitle-sm': 'text-lg sm:text-xl font-proxima-nova',
      heading: 'text-base font-proxima-nova leading-6 font-semibold',
      subheading: 'text-xs font-proxima-nova leading-4 font-semibold uppercase',
      button: 'text-sm font-proxima-nova leading-6 font-medium',
      'body-lg': 'text-base sm:text-lg font-proxima-nova leading-6',
      'body-md': 'text-base font-proxima-nova leading-6',
      'body-sm': 'text-sm font-proxima-nova leading-6',
      'body-xs': 'text-xs font-proxima-nova leading-6',
      caption: 'text-xs font-proxima-nova leading-4',
      custom: '',
    };

    const nonBreakpointVariantClasses: Record<Variant, string> = {
      'display-xl': 'text-[3.75rem] leading-[1.2] font-visby font-[800]',
      'display-lg': 'text-[2.5rem] leading-[1.2] font-visby font-[800]',
      'display-sm': 'text-[1.5rem] leading-[1.2] font-visby font-[700]',
      'subtitle-xl': 'text-[2.5rem] leading-[1.5] font-proxima-nova font-[500]',
      'subtitle-lg': 'text-[1.875rem] leading-[1.5] font-proxima-nova font-[500]',
      'subtitle-md': 'text-[1.5rem] leading-[1.5] font-proxima-nova font-[500]',
      'subtitle-sm': 'text-[1.25rem] leading-[1.3] font-proxima-nova font-[500]',
      heading: 'text-[1rem] leading-[1.4] font-proxima-nova font-[600]',
      subheading: 'text-[0.75rem] leading-[1.4] font-proxima-nova font-[600] uppercase',
      button: 'text-[0.875rem] leading-[1.4] font-proxima-nova font-[500]',
      'body-1': 'text-[1rem] leading-[1.4] font-proxima-nova font-[400]',
      'mono-1': 'text-[1rem] leading-[1.4] font-plex-mono font-[400]',
      'body-2': 'text-[0.875rem] leading-[1.4] font-proxima-nova font-[400]',
      'mono-2': 'text-[0.875rem] leading-[1.4] font-plex-mono font-[400]',
      caption: 'text-[0.75rem] leading-[1.4] font-proxima-nova font-[400]',
      deprecated: '',
    };

    const breakpointVariantClasses: Record<Variant, string> = {
      'display-xl': 'text-[2rem] leading-[1.2] font-visby font-[800]',
      'display-lg': 'text-[1.5rem] leading-[1.2] font-visby font-[800]',
      'display-sm': 'text-[1.125rem] leading-[1.2] font-visby font-bold',
      'subtitle-xl': 'text-[1.5rem] leading-[1.5] font-proxima-nova font-[400]',
      'subtitle-lg': 'text-[1.25rem] leading-[1.5] font-proxima-nova font-[400]',
      'subtitle-md': 'text-[1.125rem] leading-[1.5] font-proxima-nova font-[400]',
      'subtitle-sm': 'text-[1rem] leading-[1.3] font-proxima-nova font-[400]',
      heading: 'text-[0.875rem] leading-[1.4] font-proxima-nova font-[600]',
      subheading: 'text-[0.6875rem] leading-[1.4] font-proxima-nova font-[600] uppercase',
      button: 'text-[0.9375rem] leading-[1.4] font-proxima-nova font-[500]',
      'body-1': 'text-[0.875rem] leading-[1.4] font-proxima-nova font-[400]',
      'mono-1': 'text-[0.875rem] leading-[1.4] font-ibm-plex-mono font-[400]',
      'body-2': 'text-[0.75rem] leading-[1.4] font-proxima-nova font-[400]',
      'mono-2': 'text-[0.75rem] leading-[1.4] font-ibm-plex-mono font-[400]',
      caption: 'text-[0.6rem] leading-[1.4] font-proxima-nova font-[400]',
      deprecated: '',
    };

    const variantToElement: Record<DeprecatedVariant, string> = {
      'display-xl': 'h1',
      'display-lg': 'h2',
      'display-md': 'h3',
      'display-sm': 'h4',
      'subtitle-xl': 'h2',
      'subtitle-lg': 'h3',
      'subtitle-md': 'h4',
      'subtitle-sm': 'h5',
      heading: 'h5',
      subheading: 'h6',
      button: 'span',
      'body-lg': 'p',
      'body-md': 'p',
      'body-sm': 'p',
      'body-xs': 'p',
      caption: 'caption',
      custom: 'p',
    };

    const wrapClasses: Record<Wrap, string> = {
      wrap: 'whitespace-normal',
      nowrap: 'whitespace-nowrap',
      truncate: 'truncate',
    };

    const sizeClasses: Record<Size, string> = {
      full: 'inline w-full',
      content: 'block',
    };

    const alignClasses: Record<Align, string> = {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
    };

    const variantClasses = computed(() =>
      isMobile.value ? breakpointVariantClasses[props.upliftedVariant] : nonBreakpointVariantClasses[props.upliftedVariant]
    );

    return {
      isEmpty,
      isMobile,
      is: props.domElement ?? computed(() => variantToElement[props.variant]),
      schemeClass: computed(() => schemeClasses[props.scheme]),
      variantClass: computed(() =>
        props.upliftedVariant == 'deprecated' ? deprecatedVariantClasses[props.variant] : variantClasses.value
      ),
      wrapClass: computed(() => wrapClasses[props.wrap]),
      sizeClass: computed(() => sizeClasses[props.size]),
      alignClass: computed(() => alignClasses[props.align]),
    };
  },
});
</script>
