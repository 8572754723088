import { HazardType, HazardView, SelectedHazardViewType, useHazardsStore } from '@/features';

export const hazardTypeView = (hazardType: HazardType) => {
  const hazardsStore = useHazardsStore();
  const isNotListView = hazardsStore.activeView !== HazardView.List;

  switch (hazardType) {
    case HazardType.TotalFireBans:
    case HazardType.Incident:
    case HazardType.FireDangerRating:
    case HazardType.PrescribedBurns:
      return isNotListView ? SelectedHazardViewType.Popup : SelectedHazardViewType.Modal;

    default:
      return SelectedHazardViewType.Modal;
  }
};
