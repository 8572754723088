<template>
  <transition-group
    data-cy="gvToasts"
    tag="ul"
    move-class="transition-all"
    leave-active-class="transition-all absolute"
    enter-active-class="transition-all"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <gv-toast v-for="toast in toasts" :key="toast.id" class="mt-4 w-full duration-500" v-bind="toast" @close="closeToast(toast.id)" />
  </transition-group>
</template>

<script setup lang="ts">
import { useToasts } from '@/common/composables/useToasts';

const { toasts, closeToast } = useToasts();
</script>
