<template>
  <gv-bottom-sheet :bottom-sheet="bottomSheets.emergencyBottomSheet">
    <ui-emergency-bottom-sheet />
  </gv-bottom-sheet>
  <gv-bottom-sheet :bottom-sheet="bottomSheets.enableGeolocateBottomSheet">
    <ui-enable-geolocate-bottom-sheet />
  </gv-bottom-sheet>
  <slot></slot>
</template>

<script setup lang="ts">
import { useBottomSheet } from '@/common';
import * as Symbols from '@/common/constants/symbols';
import { provide } from 'vue';

const bottomSheets = {
  emergencyBottomSheet: useBottomSheet(),
  enableGeolocateBottomSheet: useBottomSheet(),
};

provide(Symbols.EmergencyBottomSheet, bottomSheets?.emergencyBottomSheet);
provide(Symbols.EnableGeolocateBottomSheet, bottomSheets?.enableGeolocateBottomSheet);
</script>
