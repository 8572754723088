<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-row items-center justify-between rounded-t-lg">
      <div class="flex size-6 items-center" />
      <gv-type uplifted-variant="subtitle-sm" breakpoint="none">Enable location services</gv-type>
      <gv-button class="flex" variant="text" scheme="custom" size="custom" type="button" @click="bottomSheet.setClosed()">
        <gv-icon name="close" size="md" class="justify-self-end" aria-label="Close" @click="bottomSheet.setClosed()" />
      </gv-button>
    </div>

    <div class="flex flex-col items-center gap-4 text-center">
      <gv-type uplifted-variant="body-1" breakpoint="none">
        To use location-based features, please enable location services for your browser in your device settings.
      </gv-type>
      <button
        class="flex h-12 w-full flex-row items-center justify-center gap-1 rounded-md bg-[#384C66] px-4 text-white 3xs:w-[360px]"
        @click="bottomSheet.setClosed()"
      >
        <gv-type text="Continue" class="text-white" uplifted-variant="button" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import * as Symbols from '@/common/constants/symbols';
import { inject } from 'vue';

const bottomSheet = inject(Symbols.EnableGeolocateBottomSheet)!;
</script>
