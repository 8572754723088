<template>
  <span>{{ relativeTime }}</span>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import { computed, onMounted, watch } from 'vue';
import { timeTrigger, startTimer, setTimeTrigger } from '@/features';
import { toSentenceCase } from '@/common';
import { RelativeDateTimeFormat } from '.';

const props = withDefaults(
  defineProps<{
    time?: string;
    timeText?: string;
    relativeDateTimeFormat?: RelativeDateTimeFormat;
  }>(),
  {
    time: undefined,
    timeText: undefined,
    relativeDateTimeFormat: RelativeDateTimeFormat.default,
  }
);

const relativeTime = computed(() => {
  if (props.timeText) return props.timeText;

  if (!props.time) {
    return '';
  }

  const parsedDate = DateTime.fromISO(props.time);

  // The DateTime to use as the basis to which this time is compared
  const base = DateTime.fromMillis(timeTrigger.value);

  const relativeDate = (() => {
    if (props.relativeDateTimeFormat === RelativeDateTimeFormat.calendar) {
      const relativeDateTime = parsedDate.toRelativeCalendar({ base }) ?? '';

      // We need to ensure that the first letter is capitalised when the
      // relative date is in calendar format and has a value of either `today`,`tomorrow`
      // or `yesterday`.
      return ['today', 'tomorrow', 'yesterday'].includes(relativeDateTime) ? toSentenceCase(relativeDateTime) : relativeDateTime;
    }

    return parsedDate.toRelative({ base });
  })();

  return relativeDate ?? '';
});

onMounted(() => startTimer());

watch([() => props.time, () => props.timeText], () => setTimeTrigger(Date.now()));
</script>
