import { config as dev } from './dev';
import { config as local } from './local';
import { config as prd } from './prd';
import { config as prdGv } from './prd-gv';
import { config as prdTraining } from './prd-training';
import { Configuration, Environment } from './types';
import { config as uat } from './uat';
import { config as baseConfig } from './base-config';
import { config as preflight } from './preflight';
import { config as preflightUat } from './preflight-uat';
import { config as preflightDev } from './preflight-dev';

import merge from 'deepmerge';

const configurations: Record<Environment, Partial<Configuration>> = {
  local,
  dev,
  uat,
  preflight,
  'preflight-uat': preflightUat,
  'preflight-dev': preflightDev,
  prd,
  'prd-training': prdTraining,
  'prd-gv': prdGv,
};

Object.entries(configurations).forEach(([key, config]) => {
  const enrichedConfig = merge(baseConfig, config);

  configurations[key as Environment] = enrichedConfig;
});

export default configurations as Record<Environment, Configuration>;
