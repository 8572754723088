import abc from '@/common/assets/icons/material/rounded/abc.svg';
import add from '@/common/assets/icons/material/rounded/add.svg';
import add_box from '@/common/assets/icons/material/rounded/add_box.svg';
import check_circle from '@/common/assets/icons/material/rounded/check_circle.svg';
import chevron_left from '@/common/assets/icons/material/rounded/chevron_left.svg';
import chevron_right from '@/common/assets/icons/material/rounded/chevron_right.svg';
import contrast from '@/common/assets/icons/material/rounded/contrast.svg';
import close from '@/common/assets/icons/material/rounded/close.svg';
import error from '@/common/assets/icons/material/rounded/error.svg';
import expand_less from '@/common/assets/icons/material/rounded/expand_less.svg';
import expand_more from '@/common/assets/icons/material/rounded/expand_more.svg';
import filter_alt from '@/common/assets/icons/material/rounded/filter_alt.svg';
import indeterminate_check_box from '@/common/assets/icons/material/rounded/indeterminate_check_box.svg';
import info from '@/common/assets/icons/material/rounded/info.svg';
import location_on from '@/common/assets/icons/material/rounded/location_on.svg';
import near_me from '@/common/assets/icons/material/rounded/near_me.svg';
import remove from '@/common/assets/icons/material/rounded/remove.svg';
import replay from '@/common/assets/icons/material/rounded/replay.svg';
import search from '@/common/assets/icons/material/rounded/search.svg';
import warning from '@/common/assets/icons/material/rounded/warning.svg';
import web from '@/common/assets/icons/material/rounded/web.svg';
import zoom_out_map from '@/common/assets/icons/material/rounded/zoom_out_map.svg';
import open_in_full from '@/common/assets/icons/material/sharp/open_in_full.svg';
import open_with from '@/common/assets/icons/material/sharp/open_with.svg';

import call_fill from '@/common/assets/icons/material/rounded/fill/call_fill.svg';
import check_circle_fill from '@/common/assets/icons/material/rounded/fill/check_circle_fill.svg';
import error_fill from '@/common/assets/icons/material/rounded/fill/error_fill.svg';
import info_fill from '@/common/assets/icons/material/rounded/fill/info_fill.svg';
import near_me_fill from '@/common/assets/icons/material/rounded/fill/near_me_fill.svg';
import warning_fill from '@/common/assets/icons/material/rounded/fill/warning_fill.svg';
import cancel_fill from '@/common/assets/icons/material/rounded/fill/cancel_fill.svg';

export const symbolsMap: Record<string, string> = {
  abc,
  add,
  add_box,
  check_circle,
  chevron_left,
  chevron_right,
  close,
  contrast,
  error,
  expand_less,
  expand_more,
  filter_alt,
  indeterminate_check_box,
  info,
  location_on,
  near_me,
  remove,
  replay,
  search,
  warning,
  web,
  zoom_out_map,
  open_in_full,
  open_with,
};

export const filledSymbolsMap: Record<string, string> = {
  call: call_fill,
  check_circle: check_circle_fill,
  error: error_fill,
  info: info_fill,
  near_me: near_me_fill,
  cancel_fill,
  warning: warning_fill,
};

export const sizes = ['2xs', 'xs', 'sm', 'md', 'lg', 'xl', 'custom'] as const;
export const weights = ['200', '300'] as const;
export const grades = ['-25', '0', '200'] as const;
