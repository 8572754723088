import { UnimplementedError } from '@/common/errors';
import {
  GetCentresModel,
  GetClosuresModel,
  GetEventsModel,
  GetIncidentsModel,
  GetWarningByIdModel,
  GetWarningsModel,
  HazardsDataSource,
} from '@/features/hazards';
import { GetTotalFireBansModel } from '../models/hazards/get-fireban-model';

export class MockHazardsDataSource implements HazardsDataSource {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getWarningById(_warningId: string): Promise<GetWarningByIdModel> {
    throw new Error('Method not implemented.');
  }

  async getClosures(): Promise<GetClosuresModel[]> {
    throw new UnimplementedError('getClosures');
  }

  async getCentres(): Promise<GetCentresModel[]> {
    throw new UnimplementedError('getCentres');
  }

  async getWarnings(): Promise<GetWarningsModel[]> {
    return [] as GetWarningsModel[];
  }

  async getIncidents(): Promise<GetIncidentsModel[]> {
    return [] as GetIncidentsModel[];
  }

  async getEvents(): Promise<GetEventsModel[]> {
    throw new UnimplementedError('getEvents');
  }

  async getTotalFireBans(): Promise<GetTotalFireBansModel[]> {
    throw new UnimplementedError('getTotalFireBans');
  }
}
