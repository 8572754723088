import { HazardType } from '@/features';

export const hazardTypeRoute = (hazardType: HazardType) => {
  switch (hazardType) {
    case HazardType.TotalFireBans:
      return 'total-fire-bans';
    case HazardType.FireDangerRating:
      return 'fire-danger-ratings';
    case HazardType.PrescribedBurns:
      return 'prescribed-burns';

    default:
      return hazardType.toLowerCase();
  }
};
