<template>
  <div v-show="menuOpen" class="absolute left-0 z-50 h-page-mobile min-w-full overflow-y-auto bg-white">
    <div class="mx-6 mb-2 mt-6 text-2xl font-medium">Menu</div>
    <div class="mx-6 mb-4 mt-6 flex flex-col gap-4">
      <div v-for="appRoute in hamburgerMenuRoutes" :key="appRoute.title">
        <ui-header-hamburger-menu-nav-item v-bind="appRoute" @click="menuOpen = !menuOpen" />
      </div>
      <div class="flex h-14 items-center justify-end">
        <ui-social />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core';
import { hamburgerMenuRoutes } from './index';
import { watch } from 'vue';
import { BREAKPOINTS_INT } from '@/constants';

const { width } = useWindowSize();

watch(
  () => width.value,
  () => {
    if (width.value > BREAKPOINTS_INT.md) {
      menuOpen.value = false;
    }
  }
);

const menuOpen = defineModel<boolean>();
</script>
