<template>
  <image class="flex size-10 cursor-pointer items-center justify-center rounded-full" @click="onClick">
    <img class="marker size-8" :src="icon" :alt="props.feature.properties?.['icon-name'] + ' icon'" />
  </image>
</template>

<script setup lang="ts">
import { HazardType, useMapStore } from '@/features/hazards';
import { HAZARD_MARKERS } from '@/features/hazards/constants';
import { Feature, GeoJsonProperties, Geometry, Point } from 'geojson';
import { LngLatLike, Marker } from 'mapbox-gl';

const map = useMapStore();
const props = defineProps<{
  marker: Marker;
  feature: Feature<Geometry, GeoJsonProperties>;
}>();

const icon = HAZARD_MARKERS[props.feature.properties?.['icon-name'] as keyof typeof HAZARD_MARKERS];

const onClick = async () => {
  map.interactingWithMarker = true;
  const {
    id: hazardId,
    entityType: hazardType,
    eventId,
  } = props.feature.properties as { eventId: string; id: string; entityType: HazardType };

  const coordinates = (props.feature?.geometry as Point | undefined)?.coordinates as LngLatLike | undefined;
  if (!coordinates) {
    return;
  }

  const selectedHazard = {
    hazardId,
    hazardType,
    eventId,
  };

  await map.setSelectedPopupHazard(selectedHazard);

  const popup = await map.createPopup({
    coordinates: coordinates,
    popupElementProps: selectedHazard,
  });

  props.marker.setPopup(popup);
  props.marker.togglePopup();
};
</script>
