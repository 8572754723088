import { createGtm } from '@gtm-support/vue-gtm';
import 'reflect-metadata';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import '@/css/tailwind.css';
import '@/css/colors.css';
import '@/css/fonts.css';
import '@/css/default.theme.css';
import '@/css/theme.ewa.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { GTM_ID } from './constants/gtm';
import { initSentry } from './composables';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

initSentry(app);

app.use(router);
app.use(pinia);
app.use(
  createGtm({
    id: GTM_ID,
    vueRouter: router,
  })
);

app.mount('#app');
