/**
 * Convert a string to sentence case.
 * @example - toSentenceCase('BROOMEHILL-TAMBELLUP') => 'Broomehill-Tambellup'
 * @param str
 * @returns
 */
export const toSentenceCase = (str: string): string => {
  return str
    .split('-')
    .map((part) =>
      part
        .split(' ')
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join(' ')
    )
    .join('-');
};
