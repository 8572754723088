import { Configuration } from './types';

export const config: Configuration = {
  portalApi: 'https://api.emergency.wa.gov.au/v1',
  mapbox: {
    token: 'pk.eyJ1Ijoicm9iZ2VudmlzIiwiYSI6ImNqajVqN2x4bDBjMzMzbHBlNHE3MGh3NjUifQ.KjxadPDKl38aMhzH3jVWpQ',
  },
  remoteHazardAssets: {
    iconAssetsPathSvg: 'https://genvis.com/assets/icons/map/pins',
    iconAssetsPathPng: 'https://genvis.com/assets/icons/map/pins/40x40',
  },
  geospatialBucket: 'https://d3d5cp23hc6r4p.cloudfront.net',
  externalTileBaseUrl: 'https://d2gpn6hl4gh0ni.cloudfront.net',
  webTemplateStyleSheetUrl: 'https://genvis.com/assets/styles/ewa-webtemplate.css',
  sentry: {
    // The production DSN is different from the base configuration. This is because this has
    // been setup with custom production-specific settings on genvis.sentey.io.
    dsn: 'https://49bc98a2766fe6c2d605f8353b010a2b@o4507253793816576.ingest.de.sentry.io/4507255077339216',
  },
  environment: 'prd',
};
