export enum OtherIncidentSubType {
  SmellOfGasOtherIncidentEntity = 'Smell of Gas',
  StructureDamageOtherIncidentEntity = 'Structure Damage',
  ActiveAlarmOtherIncidentEntity = 'Active Alarm',
  BurnOffOtherIncidentEntity = 'Burn Off',
  FuelLeakOtherIncidentEntity = 'Fuel Leak',
  ReportOfSmokeOtherIncidentEntity = 'Report of Smoke',
  RoadCrashOtherIncidentEntity = 'Road Crash',
  VehicleFireOtherIncidentEntity = 'Vehicle Fire',
  FireOtherIncidentEntity = 'Fire',
  PrescribedBurnOtherIncidentEntity = 'Prescribed Burn',
  FloodOtherIncidentEntity = 'Flood',
  CycloneOtherIncidentEntity = 'Cyclone',
  EarthquakeOtherIncidentEntity = 'Earthquake',
  TsunamiOtherIncidentEntity = 'Tsunami',
}
