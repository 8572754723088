import { useConfig } from '@/composables';

const remoteHazardAssetsUrl = useConfig().value.remoteHazardAssets.iconAssetsPathSvg;

export const WARNING_HAZARD_ICONS = {
  // Bushfire
  'ew-bushfire--emergency-warning': `${remoteHazardAssetsUrl}/ew-bushfire--emergency-warning.svg?url`,
  'ew-bushfire--watch-and-act': `${remoteHazardAssetsUrl}/ew-bushfire--watch-and-act.svg?url`,
  'ew-bushfire--advice': `${remoteHazardAssetsUrl}/ew-bushfire--advice.svg?url`,

  // Coastal Hazards
  'ew-coastal-hazard--emergency-warning': `${remoteHazardAssetsUrl}/ew-coastal-hazard--emergency-warning.svg`,
  'ew-coastal-hazard--watch-and-act': `${remoteHazardAssetsUrl}/ew-coastal-hazard--watch-and-act.svg`,
  'ew-coastal-hazard--advice': `${remoteHazardAssetsUrl}/ew-coastal-hazard--advice.svg`,

  // Cyclone
  'ew-cyclone--emergency-warning': `${remoteHazardAssetsUrl}/ew-cyclone--emergency-warning.svg?url`,
  'ew-cyclone--watch-and-act': `${remoteHazardAssetsUrl}/ew-cyclone--watch-and-act.svg?url`,
  'ew-cyclone--advice': `${remoteHazardAssetsUrl}/ew-cyclone--advice.svg?url`,

  // Damaging Winds
  'ew-damaging-winds--emergency-warning': `${remoteHazardAssetsUrl}/ew-damaging-winds--emergency-warning.svg?url`,
  'ew-damaging-winds--watch-and-act': `${remoteHazardAssetsUrl}/ew-damaging-winds--watch-and-act.svg?url`,
  'ew-damaging-winds--advice': `${remoteHazardAssetsUrl}/ew-damaging-winds--advice.svg?url`,

  // Earthquake
  'ew-earthquake-alert': `${remoteHazardAssetsUrl}/ew-earthquake-alert.svg?url`,
  'ew-earthquake-final-alert': `${remoteHazardAssetsUrl}/ew-earthquake-final-alert.svg?url`,

  // Flood
  'ew-flood--emergency-warning': `${remoteHazardAssetsUrl}/ew-flood--emergency-warning.svg?url`,
  'ew-flood--watch-and-act': `${remoteHazardAssetsUrl}/ew-flood--watch-and-act.svg?url`,
  'ew-flood--advice': `${remoteHazardAssetsUrl}/ew-flood--advice.svg?url`,

  // Hazardous Materials
  'ew-hazmat-controlled-contained': `${remoteHazardAssetsUrl}/ew-hazmat-controlled-contained.svg?url`,
  'ew-hazmat-general-warning': `${remoteHazardAssetsUrl}/ew-hazmat-general-warning.svg?url`,
  'ew-hazardous-materials-warning': `${remoteHazardAssetsUrl}/ew-hazardous-materials-warning.svg?url`,

  // Heatwave
  'ew-heatwave--emergency-warning': `${remoteHazardAssetsUrl}/ew-heatwave--emergency-warning.svg?url`,
  'ew-heatwave--watch-and-act': `${remoteHazardAssetsUrl}/ew-heatwave--watch-and-act.svg?url`,
  'ew-heatwave--advice': `${remoteHazardAssetsUrl}/ew-heatwave--advice.svg?url`,

  // Human Pandemic Warning / State-wide Warning
  'ew-state-wide-warning': `${remoteHazardAssetsUrl}/ew-warning-state-wide.svg?url`,
  'ew-human-pandemic-warning': `${remoteHazardAssetsUrl}/ew-human-pandemic-warning.svg?url`,

  // Smoke or Other Alert
  'ew-smoke-alert': `${remoteHazardAssetsUrl}/ew-smoke-alert.svg?url`,
  'ew-other-warning': `${remoteHazardAssetsUrl}/ew-other-warning.svg?url`,

  // Storm
  'ew-storm--emergency-warning': `${remoteHazardAssetsUrl}/ew-storm--emergency-warning.svg?url`,
  'ew-storm--advice': `${remoteHazardAssetsUrl}/ew-storm--advice.svg?url`,
  'ew-storm--watch-and-act': `${remoteHazardAssetsUrl}/ew-storm--watch-and-act.svg?url`,

  // Storm Surge
  'ew-storm-surge--emergency-warning': `${remoteHazardAssetsUrl}/ew-storm-surge--emergency-warning.svg?url`,
  'ew-storm-surge--watch-and-act': `${remoteHazardAssetsUrl}/ew-storm-surge--watch-and-act.svg?url`,
  'ew-storm-surge--advice': `${remoteHazardAssetsUrl}/ew-storm-surge--advice.svg?url`,

  // Structure Fire
  'ew-structure-fire-warning': `${remoteHazardAssetsUrl}/ew-structure-fire-warning.svg?url`,

  // Tsunami
  'ew-tsunami-all-clear': `${remoteHazardAssetsUrl}/ew-tsunami-warning.svg?url`,
  'ew-tsunami-land-warning': `${remoteHazardAssetsUrl}/ew-tsunami-warning.svg?url`,
  'ew-tsunami-marine-warning': `${remoteHazardAssetsUrl}/ew-tsunami-warning.svg?url`,
  'ew-tsunami-watch': `${remoteHazardAssetsUrl}/ew-tsunami-warning.svg?url`,
  'ew-tsunami-warning': `${remoteHazardAssetsUrl}/ew-tsunami-warning.svg?url`,

  // Power Outage
  'ew-power-outage': `${remoteHazardAssetsUrl}/ew-power-outage.svg?url`,

  // Total Fire Ban
  'ew-total-fire-ban': `${remoteHazardAssetsUrl}/ew-total-fire-ban.svg?url`,

  // Community Information
  'ew-community-information': `${remoteHazardAssetsUrl}/ew-community-information.svg?url`,

  // General Warnings
  'ew-advice': `${remoteHazardAssetsUrl}/ew-advice.svg?url`,
  'ew-alert': `${remoteHazardAssetsUrl}/ew-alert.svg?url`,
  'ew-warning-location-specific': `${remoteHazardAssetsUrl}/ew-warning-location-specific.svg?url`,
  'ew-warning-state-wide': `${remoteHazardAssetsUrl}/ew-warning-state-wide.svg?url`,
};
