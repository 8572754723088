import { GetFireDangersRatingsModel, GetPrescribedBurnsModel, S3HazardsDataSource } from '@/features/hazards';

export class MockS3HazardsDataSource implements S3HazardsDataSource {
  async getFireDangerRatings(): Promise<GetFireDangersRatingsModel> {
    return { type: 'FeatureCollection', features: [] };
  }

  async getPrescribedBurns(): Promise<GetPrescribedBurnsModel> {
    return { type: 'FeatureCollection', features: [] };
  }
}
