import { toSentenceCase } from '.';

/**
 * Compose a more readable LGA name for the TFB by
 * sentence casing the LGA.
 *
 * If the LGA is not available, it will return 'Unknown'.
 *
 * @see - toSentenceCase
 * @param lga
 * @returns
 */
export const composeFireBanLga = (lga?: string): string => {
  return toSentenceCase(lga?.split(',')?.at(0) ?? 'Unknown');
};
