import { TableHeader, TableRow } from '@/common';

export enum HazardView {
  Both = 'Both',
  Map = 'Map',
  List = 'List',
}

export enum MapMode {
  Default = 'Default',
  FireBan = 'FireBan',
  FireDanger = 'FireDanger',
}

export interface HazardTable {
  label: string;
  headers: TableHeader[];
  rows: TableRow[];
}
