<template>
  <!-- See for more info on the purpose of v-bind here - https://vuejs.org/guide/components/attrs.html#attribute-inheritance-on-multiple-root-nodes-->
  <button v-bind="$attrs" class="flex h-10 items-center gap-1 rounded-3xl bg-red px-2 text-white 2xs:h-12 lg:px-4" @click="onClick">
    <gv-icon name="call" class="w-6 fill-white" fill />
    <div class="hidden lg:block">Call 000 in a life threatening emergency</div>
    <div class="lg:hidden">Call 000</div>
  </button>
  <ui-emergency-modal :modal="emergencyModal" />
</template>

<script setup lang="ts">
import * as Symbols from '@/common/constants/symbols';
import { BREAKPOINTS } from '@/constants';
import { useModal } from '@/common/composables/useModal';
import { useBreakpoints } from '@vueuse/core';
import { inject } from 'vue';

const emergencyModal = useModal();

const injected = {
  emergencyBottomSheet: inject(Symbols.EmergencyBottomSheet)!,
};

const onClick = () => {
  if (isBreakpointMd.value) {
    injected.emergencyBottomSheet.setOpen();
    return;
  }
  emergencyModal.setOpen();
};

const breakpoints = useBreakpoints(BREAKPOINTS);
const isBreakpointMd = breakpoints.smaller('md');
</script>
