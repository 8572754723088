import { ref } from 'vue';

export type UseToggleOptions = {
  initialValue?: boolean;
};

export type UseToggle = ReturnType<typeof useToggle>;

export const useToggle = (options?: UseToggleOptions) => {
  const value = ref<boolean>(options?.initialValue ?? false);

  const toggle = () => {
    value.value = !value.value;
  };

  const setOn = () => {
    value.value = true;
  };

  const setOff = () => {
    value.value = false;
  };

  return {
    value,
    toggle,
    setOn,
    setOff,
  };
};
