export enum EventSubType {
  BushfireEventEntity = 'events_bushfire',
  HazardousMaterialsEventEntity = 'events_hazardous-materials',
  OtherEventEntity = 'events_other',
  StormEventEntity = 'events_storm',
  StructureFireEventEntity = 'events_structure-fire',
  TsunamiEventEntity = 'events_tsunami',
  CycloneEventEntity = 'events_cyclone',
  EarthquakeEventEntity = 'events_earthquake',
  FloodEventEntity = 'events_flood',
  HumanPandemicEventEntity = 'events_human-pandemic',
  HumanEpidemicEventEntity = 'events_human-epidemic',
  RescueEventEntity = 'events_rescue',
  StormDamageEventEntity = 'events_storm-damage',
  SmokeAlertEventEntity = 'events_smoke-alert',
  TotalFireBanEventEntity = 'events_total-fire-ban',
  HeatwaveEventEntity = 'events_heatwave',
  PandemicEventEntity = 'events_pandemic',
  SchoolClosureEventEntity = 'events_school-closure',
  PowerOutageEventEntity = 'events_power-outage',
  EvacuationCentreEventEntity = 'events_evacuation-centre',
  OtherDBCAEventEntity = 'events_other-event--dbca',
  OtherDFESEventEntity = 'events_other-event--dfes',
}
