import { RouteConfig } from '@/common';

export const footerRoutes: RouteConfig[] = [
  {
    title: 'About this site',
    link: '/about',
    routeType: 'internal',
  },
  {
    title: 'FAQ',
    link: 'https://www.dfes.wa.gov.au/emergencywa/faq',
    routeType: 'external',
  },
  {
    title: 'Disclaimer',
    link: '/about#disclaimer',
    routeType: 'internal',
  },
];
