<template>
  <component :is="fill ? filledSymbolsMap[name] : symbolsMap[name]" :style="{ height: sizeValue, width: sizeValue }"></component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { Size } from './types';
import { filledSymbolsMap, symbolsMap } from './constants';

export type GvIconProps = {
  name: string;
  fill?: boolean;
  size?: Size;
};

const props = withDefaults(defineProps<GvIconProps>(), {
  fill: false,
  size: 'md',
});

const sizeMap: Record<Size, string> = {
  '2xs': '12',
  xs: '16',
  sm: '20',
  md: '24',
  lg: '40',
  xl: '48',
  custom: '',
};

const sizeValue = computed(() => sizeMap[props.size]);
</script>
