import { ClosureSubType, HAZARD_MARKERS, Scheme } from '@/features/hazards';

type ClosuresToSchemeMap = Record<ClosureSubType, Scheme>;

export const closuresToSchemeMap: ClosuresToSchemeMap = {
  [ClosureSubType.SchoolClosureEntity]: {
    icon: HAZARD_MARKERS['ew-school-closure'],
  },
  [ClosureSubType.RoadClosureEntity]: {
    icon: HAZARD_MARKERS['ew-road-closure'],
  },
  [ClosureSubType.FacilityOrParkClosureEntity]: {
    icon: HAZARD_MARKERS['ew-facility-or-park-closure'],
  },
};
