<template>
  <button class="marker rounded-sm bg-grey-25 p-1 text-type-link underline focus-visible:ring-primary" @click="openPopup">
    {{ hazard?.polygonFeatures?.at(0)?.properties?.fireShapeLabel }}
  </button>
</template>

<script setup lang="ts">
import { useHazardsStore, useMapStore } from '@/features';
import { LngLatLike, Marker } from 'mapbox-gl';
import { computed } from 'vue';

import UiMapIncidentAreaPopup from '../UiMapPopup/tooltip/UiMapIncidentAreaPopup.vue';

const props = defineProps<{
  coordinates: LngLatLike;
  marker: Marker;
  incidentId: string;
}>();

const map = useMapStore();
const hazardsStore = useHazardsStore();

const hazard = computed(() => {
  return hazardsStore.hazards?.incidents.find((incident) => incident.id === props.incidentId);
});

const openPopup = async () => {
  map.interactingWithMarker = true;
  map.setSelectedPopupHazard(null);
  if (props.marker.getPopup()?.isOpen()) return;

  const popup = await map.createPopup({
    type: UiMapIncidentAreaPopup,
    coordinates: props.coordinates,
    options: {
      closeButton: false,
    },
  });

  props.marker.setPopup(popup);
  props.marker.togglePopup();
};
</script>
