export enum UiListTileLeadingColor {
  Black,
  Gray,
  Blue,
  Yellow,
  Orange,
  Red,
}

export enum UiListTileBackgroundColor {
  Pink,
  Orange,
}

export enum UiListSectionType {
  Warnings = 'Warnings',
  CentersAndClosures = 'Centres and Closures',
  Incidents = 'Incidents',
  PrescribedBurnsAndBurnOffs = 'Prescribed Burns and Burn Offs',
  NaturalHazards = 'Natural Hazards',
  FireDangerRatings = 'Fire Danger Ratings',
  TotalFireBans = 'Total Fire Bans',
}

// TODO: Strongly type this (IncidentEntity['incidentType']) instead of using string
export const naturalHazardTypes = ['Storm', 'Tsunami', 'Cyclone', 'Earthquake', 'Flood', 'Coastal Hazard', 'Damaging Winds', 'Storm Surge'];
