import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { FireDangerGuide, MapGuideFireDanger, MapGuidePage, fireDangerGuides } from '@/features';

export const useMapGuideStore = defineStore('mapGuideStore', () => {
  const mapGuidePage = ref(MapGuidePage.Polygons);
  const mapGuideFireDanger = ref(MapGuideFireDanger.Moderate);

  const fireDangerGuide = computed<FireDangerGuide>(() => fireDangerGuides[mapGuideFireDanger.value]);

  return { mapGuidePage, mapGuideFireDanger, fireDangerGuide };
});
