import { FdrSubType, HAZARD_MARKERS, PolygonScheme, Scheme } from '@/features/hazards';

export type FireDangerWarningsScheme = Scheme & {
  polygonScheme: PolygonScheme;
};

type FireDangerWarningsToSchemeMap = Record<FdrSubType, FireDangerWarningsScheme>;
export const fireDangerWarningsToSchemeMap: FireDangerWarningsToSchemeMap = {
  [FdrSubType.CatastrophicFdrEntity]: {
    icon: HAZARD_MARKERS['ew-fdr-catastrophic'],
    polygonScheme: {
      fill: '#AD0909',
      'fill-opacity': 0.2,
      stroke: '#000000',
      'stroke-width': 1,
    },
  },
  [FdrSubType.ExtremeFdrEntity]: {
    icon: HAZARD_MARKERS['ew-fdr-extreme'],
    polygonScheme: {
      fill: '#F78100',
      'fill-opacity': 0.5,
      stroke: '#000000',
      'stroke-width': 1,
    },
  },
  [FdrSubType.HighFdrEntity]: {
    icon: HAZARD_MARKERS['ew-fdr-high'],
    polygonScheme: {
      fill: '#FEDD3A',
      'fill-opacity': 0.5,
      stroke: '#000000',
      'stroke-width': 1,
    },
  },
  [FdrSubType.ModerateFdrEntity]: {
    icon: HAZARD_MARKERS['ew-fdr-moderate'],
    polygonScheme: {
      fill: '#64BF30',
      'fill-opacity': 0.5,
      stroke: '#000000',
      'stroke-width': 1,
    },
  },
  [FdrSubType.NoRatingFdrEntity]: {
    icon: HAZARD_MARKERS['ew-fdr-no-rating'],
    polygonScheme: {
      fill: '#FFFFFF',
      'fill-opacity': 0.5,
      stroke: '#000000',
      'stroke-width': 1,
    },
  },
};
