<template>
  <button
    class="flex h-18 w-18 cursor-pointer select-none flex-col items-center bg-primary-400 text-white"
    :aria-expanded="menuOpen"
    @click="menuOpen = !menuOpen"
  >
    <div class="mb-0.5 flex size-full select-none flex-col items-center justify-center gap-0.5 text-[15px]">
      Menu
      <div class="h-[13px]" aria-disabled="true">
        <drag-handle-icon v-show="!menuOpen" />
        <close-icon v-show="menuOpen" />
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import DragHandleIcon from '@/common/assets/icons/drag-handle.svg';
import CloseIcon from '@/common/assets/icons/close.svg';

const menuOpen = defineModel<boolean>();
</script>
