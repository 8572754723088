<template>
  <div class="size-8">
    <gv-image
      class="absolute top-0 size-8 cursor-pointer transition-all"
      :src="clusterIcon"
      alt="cluster marker"
      :class="{
        'pointer-events-none opacity-0': clusterExpanded,
      }"
      @click="onClick"
    ></gv-image>

    <button class="absolute top-0 flex size-4 cursor-pointer items-center justify-center rounded-full bg-indigo-450" @click="onClick">
      <div class="mt-[2px] font-proxima-nova text-2xs font-bold text-white">
        {{ badgeNumber }}
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import { HAZARD_MARKERS, useMapStore } from '@/features/hazards';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { LngLatBoundsLike, LngLatLike, Marker } from 'mapbox-gl';
import { computed, ref } from 'vue';
import { bbox } from '@turf/turf';

const map = useMapStore();
const props = defineProps<{
  coords: LngLatLike;
  marker: Marker;
  sortedClusterHazards: Feature<Geometry, GeoJsonProperties>[];
  clusterLeaves: Feature<Geometry, GeoJsonProperties>[];
}>();

const clusterExpanded = ref(false);

const clusterIcon = computed(() => HAZARD_MARKERS[props.sortedClusterHazards[0].properties?.['icon-name'] as keyof typeof HAZARD_MARKERS]);

const badgeNumber = computed(() => props.clusterLeaves.length);

const onClick = async () => {
  map.interactingWithMarker = true;

  zoomCluster();
};

const zoomCluster = async () => {
  const bounds = bbox({
    type: 'FeatureCollection',
    features: props.clusterLeaves,
  });

  map.mapbox?.fitBounds(bounds as LngLatBoundsLike, {
    padding: {
      top: 20,
      bottom: 90,
      left: 20,
      right: 80,
    },
    speed: 5,
  });
};
</script>
