export enum IncidentSubType {
  // Bushfire
  BushfireIncidentEntity = 'incidents_bushfire',

  // Coastal Hazards
  CoastalHazardIncidentEntity = 'incidents_coastal-hazard',

  // Cyclone
  CycloneIncidentEntity = 'incidents_cyclone',

  // Damaging Winds
  DamagingWindsIncidentEntity = 'incidents_damaging-winds',

  // Earthquake
  EarthquakeIncidentEntity = 'incidents_earthquake',

  // Flood
  FloodIncidentEntity = 'incidents_flood',

  // Hazardous Materials
  HazardousMaterialsIncidentEntity = 'incidents_hazardous-materials',

  // Human Pandemic
  HumanPandemicIncidentEntity = 'incidents_human-pandemic',

  // Human Epidemic
  HumanEpidemicIncidentEntity = 'incidents_human-epidemic',

  // Other Incident
  OtherIncidentEntity = 'incidents_other',

  // Rescue
  RescueIncidentEntity = 'incidents_rescue',

  // Storm
  StormIncidentEntity = 'incidents_storm',

  // Storm Surge
  StormSurgeIncidentEntity = 'incidents_storm-surge',

  // Storm Damage
  StormDamageIncidentEntity = 'incidents_storm-damage',

  // Structure Fire
  StructureFireIncidentEntity = 'incidents_structure-fire',

  // Tsunami
  TsunamiIncidentEntity = 'incidents_tsunami',
}
