// TODO: Revisit these when implementing the fire danger ratings feature
import FireRatingCatastrophic from '@/common/assets/icons/hazards/fire-ratings/fire-rating-catastrophic.png';
import FireRatingExtreme from '@/common/assets/icons/hazards/fire-ratings/fire-rating-extreme.png';
import FireRatingHigh from '@/common/assets/icons/hazards/fire-ratings/fire-rating-high.png';
import FireRatingModerate from '@/common/assets/icons/hazards/fire-ratings/fire-rating-moderate.png';
import FireRatingNoRating from '@/common/assets/icons/hazards/fire-ratings/fire-rating-no-rating.png';

import {
  BushfireSubType,
  CentreSubType,
  ClosureSubType,
  CoastalHazardSubType,
  CycloneSubType,
  DamagingWindsSubType,
  EarthquakeSubType,
  EntitySubType,
  FloodSubType,
  HAZARD_MARKERS,
  HazardSubType,
  HazardType,
  HeatwaveSubType,
  HumanPandemicSubType,
  MapboxLayer,
  OtherSubType,
  PowerSubType,
  SmokeSubType,
  StormSubType,
  StormSurgeSubType,
  StructureFireSubType,
  TotalFireBanSubType,
  TsunamiSubType,
} from '@/features';

export enum ToolbarMode {
  None = 'None',
  Filters = 'Filters',
  Overlays = 'Overlays',
}

export enum OverlayType {
  DistrictBoundaries = 'District boundaries',
  Weather = 'Weather',
}

export enum OverlaySubType {
  // District Boundaries
  DFESRegions = 'DFES regions',
  LocalGovernments = 'Local Governments',
  Pastoral = 'Pastoral',
  // Weather
  CycloneTrack = 'Cyclone track',
  RainRadar = 'Rain radar',
  ThunderstormTracker = 'Thunderstorm tracker',
  Satellite = 'Satellite',
  WindDirection = 'Wind direction and speed',
  FloodCatchments = 'Flood catchments',
}

// TODO: extract
export interface Dropdown {
  placeholder: string;
  sections: DropdownGroup[];
}
export interface DropdownGroup {
  label: string;
  items: DropdownItem[];

  hazardType?: HazardType;

  count?: number;
}
export interface DropdownItem {
  label: string;
  image?: string;
  overlay?: MapboxLayer;

  hazardType?: HazardType;
  hazardSubType?: (typeof EntitySubType)[keyof typeof EntitySubType][];

  count?: number;
}

export type DropdownSelected = Record<string, Record<string, boolean>>;

export const typeSearchPlaceholder = 'Search filters';

// TODO: Dynamically define these based on the warning hazard types?
export const typeSections: DropdownGroup[] = [
  {
    label: 'Warnings',
    items: [
      { label: 'Bushfire', hazardType: HazardType.Warning, hazardSubType: Object.values(BushfireSubType) },
      { label: 'Coastal Hazard', hazardType: HazardType.Warning, hazardSubType: Object.values(CoastalHazardSubType) },
      { label: 'Cyclone', hazardType: HazardType.Warning, hazardSubType: Object.values(CycloneSubType) },
      { label: 'Damaging Winds', hazardType: HazardType.Warning, hazardSubType: Object.values(DamagingWindsSubType) },
      { label: 'Earthquake', hazardType: HazardType.Warning, hazardSubType: Object.values(EarthquakeSubType) },
      { label: 'Flood', hazardType: HazardType.Warning, hazardSubType: Object.values(FloodSubType) },
      { label: 'Hazardous Materials', hazardType: HazardType.Warning, hazardSubType: Object.values(HazardSubType) },
      { label: 'Heatwave', hazardType: HazardType.Warning, hazardSubType: Object.values(HeatwaveSubType) },
      { label: 'Human Pandemic', hazardType: HazardType.Warning, hazardSubType: Object.values(HumanPandemicSubType) },
      { label: 'Power Outage', hazardType: HazardType.Warning, hazardSubType: Object.values(PowerSubType) },
      { label: 'Smoke', hazardType: HazardType.Warning, hazardSubType: Object.values(SmokeSubType) },
      { label: 'Storm', hazardType: HazardType.Warning, hazardSubType: Object.values(StormSubType) },
      { label: 'Storm Surge', hazardType: HazardType.Warning, hazardSubType: Object.values(StormSurgeSubType) },
      { label: 'Structure Fire', hazardType: HazardType.Warning, hazardSubType: Object.values(StructureFireSubType) },
      { label: 'Tsunami', hazardType: HazardType.Warning, hazardSubType: Object.values(TsunamiSubType) },
      {
        label: 'Other',
        hazardType: HazardType.Warning,
        hazardSubType: [...Object.values(TotalFireBanSubType), ...Object.values(OtherSubType)],
      },
    ],
  },
  { label: 'Incidents', items: [], hazardType: HazardType.Incident },
  { label: 'Natural Hazards', items: [], hazardType: HazardType.Incident },
  {
    label: 'Centres and Closures',
    items: [
      { label: 'School Closure', hazardType: HazardType.Closure, hazardSubType: [ClosureSubType.SchoolClosureEntity] },
      { label: 'Road Closure', hazardType: HazardType.Closure, hazardSubType: [ClosureSubType.RoadClosureEntity] },
      { label: 'Facility and Park Closure', hazardType: HazardType.Closure, hazardSubType: [ClosureSubType.FacilityOrParkClosureEntity] },
      { label: 'Evacuation Centre', hazardType: HazardType.Centre, hazardSubType: [CentreSubType.EvacuationCentreEntity] },
      { label: 'Respite Centre', hazardType: HazardType.Centre, hazardSubType: [CentreSubType.EvacuationRespiteCentreEntity] },
    ],
  },
  { label: 'Prescribed Burns/Burn Offs', items: [], hazardType: HazardType.PrescribedBurns },
];

export const overlaySearchPlaceholder = 'Search map layers';

export const overlaySections: DropdownGroup[] = [
  {
    label: OverlayType.DistrictBoundaries,
    items: [
      { label: OverlaySubType.DFESRegions, overlay: MapboxLayer.dfesRegionsLayer },
      { label: OverlaySubType.LocalGovernments, overlay: MapboxLayer.lgaLayer },
      { label: OverlaySubType.Pastoral, overlay: MapboxLayer.pastoralLayer },
    ],
  },
  {
    label: OverlayType.Weather,
    items: [
      { label: OverlaySubType.CycloneTrack, overlay: MapboxLayer.cycloneTrackLayer },
      { label: OverlaySubType.RainRadar, overlay: MapboxLayer.rainRadarLayer },
      { label: OverlaySubType.ThunderstormTracker, overlay: MapboxLayer.thunderstormTrackerLayer },
      { label: OverlaySubType.Satellite, overlay: MapboxLayer.weatherSatelliteLayer },
      { label: OverlaySubType.WindDirection, overlay: MapboxLayer.windDirectionLayer },
      { label: OverlaySubType.FloodCatchments, overlay: MapboxLayer.floodCatchmentLayer },
    ],
  },
];

export const dropdowns: Record<Exclude<ToolbarMode, ToolbarMode.None>, Dropdown> = {
  [ToolbarMode.Filters]: {
    placeholder: typeSearchPlaceholder,
    sections: typeSections,
  },
  [ToolbarMode.Overlays]: {
    placeholder: overlaySearchPlaceholder,
    sections: overlaySections,
  },
};

// TODO: Dynamically define these based on the warning hazard types?
export const layerSections: DropdownGroup[] = [
  {
    label: 'Warning icons',
    items: [
      // Bushfire
      { label: 'Bushfire Emergency Warning', image: HAZARD_MARKERS['ew-bushfire--emergency-warning'] },
      { label: 'Bushfire Watch and Act', image: HAZARD_MARKERS['ew-bushfire--watch-and-act'] },
      { label: 'Bushfire Advice', image: HAZARD_MARKERS['ew-bushfire--advice'] },

      // Coastal Hazard
      { label: 'Coastal Hazard Emergency Warning', image: HAZARD_MARKERS['ew-coastal-hazard--emergency-warning'] },
      { label: 'Coastal Hazard Watch and Act', image: HAZARD_MARKERS['ew-coastal-hazard--watch-and-act'] },
      { label: 'Coastal Hazard Advice', image: HAZARD_MARKERS['ew-coastal-hazard--advice'] },

      // Cyclone
      { label: 'Cyclone Emergency Warning', image: HAZARD_MARKERS['ew-cyclone--emergency-warning'] },
      { label: 'Cyclone Watch and Act', image: HAZARD_MARKERS['ew-cyclone--watch-and-act'] },
      { label: 'Cyclone Advice', image: HAZARD_MARKERS['ew-cyclone--advice'] },

      // Damaging Winds
      { label: 'Damaging Winds Emergency Warning', image: HAZARD_MARKERS['ew-damaging-winds--emergency-warning'] },
      { label: 'Damaging Winds Watch and Act', image: HAZARD_MARKERS['ew-damaging-winds--watch-and-act'] },
      { label: 'Damaging Winds Advice', image: HAZARD_MARKERS['ew-damaging-winds--advice'] },

      // Earthquake
      { label: 'Earthquake Alert', image: HAZARD_MARKERS['ew-earthquake-alert'] },

      // Flood
      { label: 'Flood Emergency Warning', image: HAZARD_MARKERS['ew-flood--emergency-warning'] },
      { label: 'Flood Watch and Act', image: HAZARD_MARKERS['ew-flood--watch-and-act'] },
      { label: 'Flood Advice', image: HAZARD_MARKERS['ew-flood--advice'] },

      // Hazardous Materials
      { label: 'Hazardous Materials Warning', image: HAZARD_MARKERS['ew-hazmat-general-warning'] },

      // Heatwave
      { label: 'Heatwave Emergency Warning', image: HAZARD_MARKERS['ew-heatwave--emergency-warning'] },
      { label: 'Heatwave Watch and Act', image: HAZARD_MARKERS['ew-heatwave--watch-and-act'] },
      { label: 'Heatwave Advice', image: HAZARD_MARKERS['ew-heatwave--advice'] },

      // Human Pandemic Warning
      { label: 'Human Pandemic Warning', image: HAZARD_MARKERS['ew-state-wide-warning'] },

      // Smoke or Other Alert
      { label: 'Smoke or Other Alert', image: HAZARD_MARKERS['ew-other-warning'] },

      // Storm
      { label: 'Storm Emergency Warning', image: HAZARD_MARKERS['ew-storm--emergency-warning'] },
      { label: 'Storm Watch and Act', image: HAZARD_MARKERS['ew-storm--watch-and-act'] },
      { label: 'Storm Advice', image: HAZARD_MARKERS['ew-storm--advice'] },

      // Storm Surge
      { label: 'Storm Surge Emergency Warning', image: HAZARD_MARKERS['ew-storm-surge--emergency-warning'] },
      { label: 'Storm Surge Watch and Act', image: HAZARD_MARKERS['ew-storm-surge--watch-and-act'] },
      { label: 'Storm Surge Advice', image: HAZARD_MARKERS['ew-storm-surge--advice'] },

      // Structure Fire
      { label: 'Structure Fire Warning', image: HAZARD_MARKERS['ew-structure-fire-warning'] },

      // Tsunami
      { label: 'Tsunami Warning', image: HAZARD_MARKERS['ew-tsunami-all-clear'] },
    ],
  },
  {
    label: 'Incident icons',
    items: [
      { label: 'Coastal Hazard', image: HAZARD_MARKERS['ew-coastal-hazard'] },
      { label: 'Cyclone', image: HAZARD_MARKERS['ew-cyclone'] },
      { label: 'Damaging Winds', image: HAZARD_MARKERS['ew-damaging-winds'] },
      { label: 'Earthquake', image: HAZARD_MARKERS['ew-earthquake'] },
      { label: 'Fire Active', image: HAZARD_MARKERS['ew-other-fire'] },
      { label: 'Flood', image: HAZARD_MARKERS['ew-flood'] },
      { label: 'Hazardous Materials', image: HAZARD_MARKERS['ew-hazardous-materials'] },
      { label: 'Human Pandemic', image: HAZARD_MARKERS['ew-human-pandemic'] },
      { label: 'Rescue', image: HAZARD_MARKERS['ew-rescue'] },
      { label: 'Storm', image: HAZARD_MARKERS['ew-storm'] },
      { label: 'Storm Damage', image: HAZARD_MARKERS['ew-storm-damage'] },
      { label: 'Storm Surge', image: HAZARD_MARKERS['ew-storm-surge'] },
      { label: 'Tsunami', image: HAZARD_MARKERS['ew-tsunami'] },
      { label: 'Other incident', image: HAZARD_MARKERS['ew-other-incident'] },
      { label: 'Prescribed Burn/Burn off', image: HAZARD_MARKERS['ew-prescribed-burn-or-burn-off'] },
    ],
  },
  {
    label: 'Centres',
    items: [{ label: 'Evacuation Centre', image: HAZARD_MARKERS['ew-evacuation-centre'] }],
  },
  {
    label: 'Outages and Facility Closures',
    items: [
      { label: 'Facility or Park Closure', image: HAZARD_MARKERS['ew-facility-or-park-closure'] },
      { label: 'Power Outage', image: HAZARD_MARKERS['ew-power-outage'] },
      { label: 'Road Closure', image: HAZARD_MARKERS['ew-road-closure'] },
      { label: 'School Closure', image: HAZARD_MARKERS['ew-school-closure'] },
    ],
  },
  {
    label: 'Fire Danger Ratings',
    items: [
      { label: 'Catastrophic', image: FireRatingCatastrophic },
      { label: 'Extreme', image: FireRatingExtreme },
      { label: 'High', image: FireRatingHigh },
      { label: 'Moderate', image: FireRatingModerate },
      { label: 'No rating', image: FireRatingNoRating },
    ],
  },
  {
    label: 'Total Fire Bans',
    items: [
      { label: 'Total Fire Ban', image: HAZARD_MARKERS['ew-total-fire-ban'] },
      { label: 'No Total Fire Ban', image: HAZARD_MARKERS['ew-no-total-fire-ban'] },
    ],
  },
];
