import { Configuration } from './types';

/**
 * The base configuration targets the production environment.
 *
 * This can be overridden by the environment-specific configurations.
 */
export const config: Partial<Configuration> = {
  mapbox: {
    token: 'pk.eyJ1Ijoicm9iZ2VudmlzIiwiYSI6ImNqajVqN2x4bDBjMzMzbHBlNHE3MGh3NjUifQ.KjxadPDKl38aMhzH3jVWpQ',
    overlaysTileset: 'mapbox://robgenvis.overlays-tileset',
    overlaysCentresTileset: 'mapbox://robgenvis.overlays-centres-tileset',
  },
  remoteHazardAssets: {
    iconAssetsPathSvg: 'https://genvis.com/assets/icons/map/pins',
    iconAssetsPathPng: 'https://genvis.com/assets/icons/map/pins/40x40',
  },
  geospatialBucket: 'https://d3d5cp23hc6r4p.cloudfront.net',
  externalTileBaseUrl: 'https://d2gpn6hl4gh0ni.cloudfront.net',
  webTemplateStyleSheetUrl: 'https://genvis.com/assets/styles/ewa-webtemplate.css',
  sentry: {
    dsn: 'https://2946dfe99e66df21f5f92a4655a0b647@o4507253793816576.ingest.de.sentry.io/4507255077339216',
  },
};
