<template>
  <span class="loader animate-spin rounded-full border-[5px] border-primary border-b-white" :class="[sizeMap[size]]"></span>
</template>

<script lang="ts" setup>
import { Size } from './types';

withDefaults(
  defineProps<{
    size: Size;
  }>(),
  {
    size: 'md',
  }
);

const sizeMap: Record<Size, string> = {
  '2xs': 'w-4 h-4',
  xs: 'w-8 h-8',
  sm: 'w-10 h-10 ',
  md: 'w-12 h-12',
  lg: 'w-16 h-16',
  xl: 'w-24 h-24',
  '2xl': 'w-32 h-32',
  full: 'w-full h-full',
  custom: '',
};
</script>
