import { BREAKPOINTS } from '@/constants';
import { MapMode, useHazardsStore } from '@/features';
import { useBreakpoints } from '@vueuse/core';
import { computed } from 'vue';

const breakpoints = useBreakpoints(BREAKPOINTS);

export const MOBILE_DATE_SELECTOR_HEIGHT = 39.5;

export const HeaderHeight = computed<number>(() => {
  return !breakpoints.xs.value ? 64 : 72;
});

export const ToolbarHeight = computed<number>(() => {
  const hazardsStore = useHazardsStore();
  const additionalHeight =
    isMobile.value && [MapMode.FireBan, MapMode.FireDanger].includes(hazardsStore.mapMode) ? MOBILE_DATE_SELECTOR_HEIGHT : 0;

  if (!breakpoints.md.value) return 112 + additionalHeight;

  if (!breakpoints['2xs'].value) return 104 + additionalHeight;

  return 64;
});

export const showBothHazardView = computed<boolean>(() => {
  return breakpoints.greaterOrEqual('sm').value;
});

export const showDateSelector = computed<boolean>(() => {
  return breakpoints.greaterOrEqual('md').value;
});

export const isMobile = computed<boolean>(() => {
  return breakpoints.smaller('xs').value;
});
