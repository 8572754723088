import { FdrSubType } from '@/features';

export const extraTextHeader: Record<FdrSubType, string> = {
  [FdrSubType.CatastrophicFdrEntity]: 'For your survival, leave bushfire risk areas',
  [FdrSubType.ExtremeFdrEntity]: 'Take action now to protect your life and property',
  [FdrSubType.HighFdrEntity]: 'Be ready to act',
  [FdrSubType.ModerateFdrEntity]: 'Plan and prepare',
  [FdrSubType.NoRatingFdrEntity]: 'No rating',
};

export const noRatingText =
  'Where no rating is declared, fires are unlikely to spread in a dangerous or life-threatening way. On these days you still need to remain alert and follow local laws and regulations. A bushfire plan will help you decide what you will do if a fire starts. Make one today by visiting <a href="https://mybushfireplan.wa.gov.au/" class="text-indigo-500 underline">My Bushfire Plan</a>.';

export const extraText: Record<string, string> = {
  [FdrSubType.CatastrophicFdrEntity]:
    'If a fire starts and takes hold in these conditions, lives are likely to be lost. These are the most dangerous conditions for a fire. For your survival, leave bushfire risk areas early. Homes cannot withstand fires in these conditions. You may not be able to leave, and help may not be available.',
  [FdrSubType.ExtremeFdrEntity]:
    'Fires will spread quickly and be extremely dangerous in these conditions. Take immediate action if a fire starts. If you and your property are not prepared to the highest level, plan to leave early.',
  [FdrSubType.HighFdrEntity]: "Fires can be dangerous in these conditions, so it's important to stay up to date and be ready to act.",
  [FdrSubType.ModerateFdrEntity]:
    "Most fires are likely to be controlled in these conditions, but it's important to stay up to date and plan what you will do if conditions change.",
};

export const whatToDoText: Record<string, string[]> = {
  [FdrSubType.CatastrophicFdrEntity]: [
    'Go to a safer location early in the morning or the night before.',
    'Prepare your emergency kit and choose where you will go and how you will get there.',
    'Take immediate action if needed. Do not wait for a warning.',
    'If you see smoke or flames call triple zero.',
  ],
  [FdrSubType.ExtremeFdrEntity]: [
    'Put your bushfire plan into action. If you do not have a plan, visit <a href="https://mybushfireplan.wa.gov.au/" class="text-indigo-500 underline">My Bushfire Plan</a> to decide when you will leave and where you will go.',
    'Avoid travel through bushfire risk areas.',
    'Take action if needed. Do not wait for a warning.',
    'If you see smoke or flames call triple zero.',
    'Monitor conditions and stay up to date.',
  ],
  [FdrSubType.HighFdrEntity]: [
    'Review your bushfire plan. If you do not have plan, make one by visiting <a href="https://mybushfireplan.wa.gov.au/" class="text-indigo-500 underline">My Bushfire Plan</a>.',
    'Monitor conditions and stay up to date.',
    'Take action if needed. Do not wait for a warning.',
    'If you see smoke or flames call triple zero.',
    'Leave bushfire risk areas if necessary.',
  ],
  [FdrSubType.ModerateFdrEntity]: [
    'Check your bushfire plan. If you do not have plan, make one by visiting <a href="https://mybushfireplan.wa.gov.au/" class="text-indigo-500 underline">My Bushfire Plan</a>.',
    'Stay up to date and be alert for fires in your area.',
    'Take action if needed. Do not wait for a warning.',
    'If you see smoke or flames call triple zero.',
    'Planned burning may occur in these conditions if it is safe to do so - check to see if permits apply.',
  ],
};
