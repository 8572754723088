export enum BushfireSubType {
  BushfireAdviceWarningEntity = 'warnings_bushfire--advice',
  BushfireWatchAndActWarningEntity = 'warnings_bushfire--watch-and-act',
  BushfireEmergencyWarningWarningEntity = 'warnings_bushfire--emergency-warning',
}

export enum CoastalHazardSubType {
  CoastalHazardAdviceWarningEntity = 'warnings_coastal-hazard--advice',
  CoastalHazardWatchAndActWarningEntity = 'warnings_coastal-hazard--watch-and-act',
  CoastalHazardEmergencyWarningWarningEntity = 'warnings_coastal-hazard--emergency-warning',
}

export enum CycloneSubType {
  CycloneAdviceWarningEntity = 'warnings_cyclone--advice',
  CycloneWatchAndActWarningEntity = 'warnings_cyclone--watch-and-act',
  CycloneEmergencyWarningWarningEntity = 'warnings_cyclone--emergency-warning',
}

export enum DamagingWindsSubType {
  DamagingWindsAdviceWarningEntity = 'warnings_damaging-winds--advice',
  DamagingWindsWatchAndActWarningEntity = 'warnings_damaging-winds--watch-and-act',
  DamagingWindsEmergencyWarningWarningEntity = 'warnings_damaging-winds--emergency-warning',
}

export enum EarthquakeSubType {
  EarthquakeAlertWarningEntity = 'warnings_earthquake-alert',
  EarthquakeFinalAlertWarningEntity = 'warnings_earthquake-final-alert',
}

export enum FloodSubType {
  FloodAdviceWarningEntity = 'warnings_flood--advice',
  FloodWatchAndActWarningEntity = 'warnings_flood--watch-and-act',
  FloodEmergencyWarningWarningEntity = 'warnings_flood--emergency-warning',
}

export enum HazardSubType {
  HazmatGeneralWarningWarningEntity = 'warnings_hazmat-general-warning',
  HazmatControlledContainedWarningEntity = 'warnings_hazmat-controlled-contained',
}

export enum HeatwaveSubType {
  HeatwaveAdviceWarningEntity = 'warnings_heatwave--advice',
  HeatwaveWatchAndActWarningEntity = 'warnings_heatwave--watch-and-act',
  HeatwaveEmergencyWarningWarningEntity = 'warnings_heatwave--emergency-warning',
}

export enum HumanPandemicSubType {
  CommunityInformationWarningEntity = 'warnings_community-information',
  AdviceWarningEntity = 'warnings_advice',
  AlertWarningEntity = 'warnings_alert',
  WarningStateWideWarningEntity = 'warnings_state-wide-warning',
  WarningLocationSpecificWarningEntity = 'warnings_location-specific-warning',
}

export enum PowerSubType {
  PowerOutageWarningEntity = 'warnings_power-outage',
}

export enum SmokeSubType {
  SmokeAlertWarningEntity = 'warnings_smoke-alert',
}

export enum StormSubType {
  StormAdviceWarningEntity = 'warnings_storm--advice',
  StormWatchAndActWarningEntity = 'warnings_storm--watch-and-act',
  StormEmergencyWarningWarningEntity = 'warnings_storm--emergency-warning',
}

export enum StormSurgeSubType {
  StormSurgeAdviceWarningEntity = 'warnings_storm-surge--advice',
  StormSurgeWatchAndActWarningEntity = 'warnings_storm-surge--watch-and-act',
  StormSurgeEmergencyWarningWarningEntity = 'warnings_storm-surge--emergency-warning',
}

export enum StructureFireSubType {
  StructureFireAdviceWarningEntity = 'warnings_structure-fire-advice',
  StructureFireAllClearWarningEntity = 'warnings_structure-fire-all-clear',
}

export enum TotalFireBanSubType {
  TotalFireBanWarningEntity = 'warnings_total-fire-ban',
  NoTotalFireBanWarningEntity = 'warnings_no-total-fire-ban',
}

export enum TsunamiSubType {
  TsunamiLandWarningWarningEntity = 'warnings_tsunami-land-warning',
  TsunamiMarineWarningWarningEntity = 'warnings_tsunami-marine-warning',
  TsunamiWatchWarningEntity = 'warnings_tsunami-watch',
  TsunamiAllClearWarningEntity = 'warnings_tsunami-all-clear',
}

export enum OtherSubType {
  OtherWarningWarningEntity = 'warnings_other',
}

export const WarningSubTypes = {
  ...BushfireSubType,
  ...CoastalHazardSubType,
  ...CycloneSubType,
  ...DamagingWindsSubType,
  ...EarthquakeSubType,
  ...FloodSubType,
  ...HazardSubType,
  ...HeatwaveSubType,
  ...HumanPandemicSubType,
  ...PowerSubType,
  ...SmokeSubType,
  ...StormSubType,
  ...StormSurgeSubType,
  ...StructureFireSubType,
  ...TotalFireBanSubType,
  ...TsunamiSubType,
  ...OtherSubType,
};

export type WarningSubType = (typeof WarningSubTypes)[keyof typeof WarningSubTypes];
