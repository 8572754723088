import IncidentNoTotalFireBan from '@/common/assets/icons/hazards/incidents/ew-no-fire-ban.svg?url';

import { useConfig } from '@/composables';

const remoteHazardAssetsUrl = useConfig().value.remoteHazardAssets.iconAssetsPathSvg;

export const INCIDENT_HAZARD_ICONS = {
  // Bushfire
  'ew-bushfire-active': `${remoteHazardAssetsUrl}/ew-bushfire-active.svg?url`,
  'ew-bushfire': `${remoteHazardAssetsUrl}/ew-bushfire.svg?url`,

  // Coastal Hazards
  'ew-coastal-hazard': `${remoteHazardAssetsUrl}/ew-coastal-hazard.svg?url`,

  // Cyclone
  'ew-cyclone': `${remoteHazardAssetsUrl}/ew-cyclone.svg?url`,

  // Damaging Winds
  'ew-damaging-winds': `${remoteHazardAssetsUrl}/ew-damaging-winds.svg?url`,

  // Earthquake
  'ew-earthquake': `${remoteHazardAssetsUrl}/ew-earthquake.svg?url`,

  // Fire Active
  'ew-other-fire': `${remoteHazardAssetsUrl}/ew-other-fire.svg?url`,

  // Flood
  'ew-flood': `${remoteHazardAssetsUrl}/ew-flood.svg?url`,

  // Hazardous Materials
  'ew-hazardous-materials': `${remoteHazardAssetsUrl}/ew-hazardous-materials.svg?url`,

  // Human Epidemic
  'ew-human-epidemic': `${remoteHazardAssetsUrl}/ew-human-epidemic.svg?url`,

  // Human Pandemic
  'ew-human-pandemic': `${remoteHazardAssetsUrl}/ew-human-pandemic.svg?url`,

  // Rescue
  'ew-rescue': `${remoteHazardAssetsUrl}/ew-rescue.svg?url`,

  // Storm
  'ew-storm': `${remoteHazardAssetsUrl}/ew-storm.svg?url`,

  // Storm Surge
  'ew-storm-surge': `${remoteHazardAssetsUrl}/ew-storm-surge.svg?url`,

  // Storm Damage
  'ew-storm-damage': `${remoteHazardAssetsUrl}/ew-storm-damage.svg?url`,

  // Tsunami
  'ew-tsunami': `${remoteHazardAssetsUrl}/ew-tsunami.svg?url`,

  // Other incident
  'ew-other-incident': `${remoteHazardAssetsUrl}/ew-other-incident.svg?url`,

  // Prescribed Burn or Burn Off
  'ew-prescribed-burn-or-burn-off': `${remoteHazardAssetsUrl}/ew-prescribed-burn-or-burn-off.svg?url`,

  // Smoke Alert
  'ew-smoke-alert': `${remoteHazardAssetsUrl}/ew-smoke-alert.svg?url`,

  // Structure Fire
  'ew-structure-fire': `${remoteHazardAssetsUrl}/ew-structure-fire.svg?url`,

  // Other
  'ew-other': `${remoteHazardAssetsUrl}/ew-other.svg?url`,
  'ew-other-active-alarm': `${remoteHazardAssetsUrl}/ew-other-active-alarm.svg?url`,
  'ew-other-burn-off': `${remoteHazardAssetsUrl}/ew-other-burn-off.svg?url`,
  'ew-other-fuel-leak': `${remoteHazardAssetsUrl}/ew-other-fuel-leak.svg?url`,
  'ew-other-prescribed-burn': `${remoteHazardAssetsUrl}/ew-other-prescribed-burn.svg?url`,
  'ew-other-report-of-smoke': `${remoteHazardAssetsUrl}/ew-other-report-of-smoke.svg?url`,
  'ew-other-road-crash': `${remoteHazardAssetsUrl}/ew-other-road-crash.svg?url`,
  'ew-other-smell-of-gas': `${remoteHazardAssetsUrl}/ew-other-smell-of-gas.svg?url`,
  'ew-other-structure-damage': `${remoteHazardAssetsUrl}/ew-other-structure-damage.svg?url`,
  'ew-other-vehicle-fire': `${remoteHazardAssetsUrl}/ew-other-vehicle-fire.svg?url`,
  'ew-other-flood': `${remoteHazardAssetsUrl}/ew-other-flood.svg?url`,
  'ew-other-cyclone': `${remoteHazardAssetsUrl}/ew-other-cyclone.svg?url`,
  'ew-other-earthquake': `${remoteHazardAssetsUrl}/ew-other-earthquake.svg?url`,
  'ew-other-tsunami': `${remoteHazardAssetsUrl}/ew-other-tsunami.svg?url`,
  // Total Fire Ban
  'ew-total-fire-ban': `${remoteHazardAssetsUrl}/ew-total-fire-ban.svg?url`,
  'ew-no-total-fire-ban': IncidentNoTotalFireBan,
};
