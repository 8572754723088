import { init } from '@sentry/vue';
import { Options, TracingOptions, Vue } from '@sentry/vue/build/types/types';
import { useConfig } from './config';

export type SentryConfigOptions = Partial<
  Omit<Options, 'tracingOptions'> & {
    tracingOptions: Partial<TracingOptions>;
  }
>;

export const initSentry = (app?: Vue) => {
  const config = useConfig();

  if (!config.value.environment.startsWith('local')) {
    init({
      app,
      dsn: config.value.sentry?.dsn,
      environment: config.value.environment,
      release: `ewa-web-app@${import.meta.env.PACKAGE_VERSION}`,
      sampleRate: 1.0,
      tracesSampleRate: 1.0,
    });
  }
};
