import { CentreSubType, HAZARD_MARKERS, Scheme } from '@/features/hazards';

type CentresToSchemeMap = Record<CentreSubType, Scheme>;

export const centresToSchemeMap: CentresToSchemeMap = {
  [CentreSubType.EvacuationCentreEntity]: {
    icon: HAZARD_MARKERS['ew-evacuation-centre'],
  },
  [CentreSubType.EvacuationRespiteCentreEntity]: {
    icon: HAZARD_MARKERS['ew-evacuation-centre'],
  },
};
