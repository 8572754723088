export enum RouteNames {
  Hazards = 'hazards',
  HazardById = 'hazard-by-id',
  HazardByType = 'hazard-by-type',
  TextVersion = 'text-version',
  TextVersionById = 'text-version-by-id',
  About = 'about',
  MapIcons = 'map-icons',
  MapGuide = 'map-guide',
}
