import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { Dropdown, dropdowns, ToolbarMode } from '@/features';

export const useToolbarStore = defineStore('toolbarStore', () => {
  const toolbarMode = ref(ToolbarMode.None);

  const dropdown = computed<Dropdown | undefined>(() => {
    if (toolbarMode.value === ToolbarMode.None) return undefined;
    return dropdowns[toolbarMode.value];
  });

  return { toolbarMode, dropdown };
});
