<template>
  <ul>
    <li id="skip-to-content">
      <a
        class="absolute m-2 h-10 w-48 items-center rounded-lg bg-primary-100 px-10 py-2 text-base font-semibold leading-snug text-white outline-none"
        href="#main-page-content-view"
        >Skip to content</a
      >
    </li>
  </ul>
</template>
<style>
#skip-to-content a {
  position: absolute;
  top: -100px;
  left: 0px;
  -webkit-transition: top 0.5s ease-out;
  transition: top 0.5s ease-out;
  z-index: 999;
  overflow: hidden;
}

#skip-to-content a:focus,
#skip-to-content a:active {
  left: 0px;
  top: 0px;
  position: absolute;
  outline-color: transparent;
  -webkit-transition: top 0.05s ease-in;
  transition: top 0.05s ease-in;
}

@media (prefers-reduced-motion: reduce) {
  #skip-to-content a {
    transition-duration: 0.001ms !important;
  }
}
</style>
