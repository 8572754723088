import { CentreSubType } from './centres';
import { ClosureSubType } from './closures';
import { EventSubType } from './events';
import { FdrSubType } from './fire-danger-ratings';
import { IncidentSubType } from './incidents';
import { WarningSubTypes } from './warnings';

export const EntitySubType = {
  ...EventSubType,
  ...WarningSubTypes,
  ...IncidentSubType,
  ...CentreSubType,
  ...ClosureSubType,
  ...FdrSubType,
};

export * from './centres';
export * from './closures';
export * from './events';
export * from './fire-danger-ratings';
export * from './incidents';
export * from './warnings';
