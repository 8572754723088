// identical to entityType
export enum HazardType {
  Warning = 'warnings',
  Incident = 'incidents',
  Closure = 'closures',
  Centre = 'centres',
  Event = 'events',
  TotalFireBans = 'totalFireBans',
  FireDangerRating = 'fireDangerRatings',
  PrescribedBurns = 'prescribedBurns',
}
