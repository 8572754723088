<template>
  <div>
    <slot v-if="isLoading" name="loading" />
    <slot v-else-if="isFallback" name="fallback" />
    <img
      v-if="!isFallback"
      v-show="isSuccess"
      :key="`${currentSource}-${sourceIndex}`"
      :src="currentSource"
      :alt="alt"
      class="size-full"
      :class="`${
        {
          fill: 'object-fill',
          contain: 'object-contain',
          cover: 'object-cover',
        }[fit]
      } ${imgClass}`"
      @error="onError"
      @load="onLoad"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watch } from 'vue';

import { Status, Fit } from './types';

import { name } from './constants';

export default defineComponent({
  name,
  props: {
    imgClass: {
      type: String,
    },
    src: {
      type: String,
    },
    srcs: {
      type: Array as PropType<string[]>,
    },
    fit: {
      type: String as PropType<Fit>,
      default: 'cover',
    },
    alt: {
      type: String,
    },
  },
  setup(props) {
    const status = ref<Status>();
    const sourceIndex = ref<number>(0);

    const sources = computed(() => [props.src, ...(props?.srcs || [])].filter((source) => source));
    const currentSource = computed(() => sources.value[sourceIndex.value]);

    const isSuccess = computed(() => status.value === Status.SUCCESS);
    const isLoading = computed(() => status.value === Status.LOADING);
    const isFallback = computed(() => status.value === Status.FALLBACK);

    const resetSource = () => {
      sourceIndex.value = 0;

      if (sources.value.length <= 0) {
        status.value = Status.FALLBACK;
      } else {
        status.value = Status.LOADING;
      }
    };

    watch(
      () => [props.src, props.srcs],
      () => {
        resetSource();
      },
      {
        immediate: true,
      }
    );

    const incrementSource = () => {
      sourceIndex.value += 1;
    };

    const onError = () => {
      if (sourceIndex.value < sources.value.length - 1) {
        incrementSource();
      } else {
        status.value = Status.FALLBACK;
      }
    };

    const onLoad = () => {
      status.value = Status.SUCCESS;
    };

    return {
      status,
      sourceIndex,
      currentSource,
      isSuccess,
      isLoading,
      isFallback,
      onError,
      onLoad,
    };
  },
});
</script>
