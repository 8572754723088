import { computed } from 'vue';
import { DEFAULT_ENVIRONMENT, ENVIRONMENTS, Environment, HOSTNAMES_TO_ENVIRONMENTS } from '../config/types';

export const useEnvironment = () => {
  return computed(() => {
    // Use VITE_ENV as an override, if it is available.
    if (import.meta.env.VITE_ENV && ENVIRONMENTS.includes(import.meta.env.VITE_ENV)) {
      return import.meta.env.VITE_ENV as Environment;
    }

    return HOSTNAMES_TO_ENVIRONMENTS[window.location.hostname] ?? DEFAULT_ENVIRONMENT;
  });
};
