<template>
  <div class="flex flex-row items-center justify-between">
    <div class="size-6" />
    <gv-type uplifted-variant="subtitle-sm">Call emergency services</gv-type>
    <gv-button class="flex" variant="text" scheme="custom" size="custom" type="button" @click="bottomSheet.setClosed">
      <gv-icon name="close" aria-label="Close" />
    </gv-button>
  </div>

  <div class="mt-4 flex flex-col items-center gap-4 !text-center">
    <div class="flex w-full flex-row items-start gap-2 rounded bg-[#5C7699] p-2 !text-white 2xs:items-center">
      <gv-icon name="error" size="sm" class="justify-self-end fill-white" />
      <gv-type uplifted-variant="body-2" class="!text-white">
        For emergency information call <a href="tel:133337"><b>13 33 37.</b></a>
        <br />
        For SES emergency assistance call <a href="tel:132500"><b>132 500.</b></a>
      </gv-type>
    </div>
    <div class="flex flex-col !items-center gap-2">
      <gv-type uplifted-variant="body-1"><b>For fire or life-threatening emergencies</b></gv-type>
      <div v-if="geocodedPosition || position" class="flex flex-col items-center gap-2">
        <gv-type uplifted-variant="body-1" class="text-center">Please provide the call operator with your current address:</gv-type>
        <gv-type v-if="geocodedPosition" uplifted-variant="body-1" class="w-full whitespace-pre !text-center font-semibold">
          {{ formatAddress(geocodedPosition, '\n') }}
        </gv-type>

        <gv-type v-if="position" uplifted-variant="body-1" class="!text-center text-sm !text-grey-500">
          {{
            `(LAT: ${position?.coords.latitude}, LONG:
            ${position?.coords.longitude})`
          }}
        </gv-type>
      </div>
      <div v-else>
        <gv-type uplifted-variant="body-1" class="!text-center">Location permission is required to display your current address.</gv-type>
        <gv-button variant="text" :disabled="loadingLocation" @click="getCurrentPosition()">
          <ui-spinner v-if="loadingLocation" size="xs" />
          <gv-type v-else uplifted-variant="heading" class="!text-indigo-450 underline">Allow location services</gv-type>
        </gv-button>
      </div>
    </div>
    <a href="tel:000">
      <button class="flex h-12 w-[280px] flex-row items-center justify-center gap-1 rounded-md bg-red text-white 2xs:w-[360px]">
        <div>Call 000</div>
      </button>
    </a>
  </div>
</template>

<script lang="ts" setup>
import * as Symbols from '@/common/constants/symbols';
import { formatAddress, GeocodingResponseFeatures, useGeocodeStore } from '@/features';
import { inject, ref, watch } from 'vue';

const bottomSheet = inject(Symbols.EmergencyBottomSheet)!;

const geocode = useGeocodeStore();

const position = ref<GeolocationPosition>();
const loadingLocation = ref(false);
const geocodedPosition = ref<GeocodingResponseFeatures>();

watch(
  () => geocode.geocodedPosition,
  () => {
    geocodedPosition.value = geocode.geocodedPosition?.features.at(0);
    loadingLocation.value = false;
  }
);

const foundPosition = (pos: GeolocationPosition) => {
  position.value = pos;
  geocode.getReverseGeocoding(position.value.coords.latitude, position.value.coords.longitude);
};

const getCurrentPosition = () => {
  loadingLocation.value = true;
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(foundPosition, () => {}, { enableHighAccuracy: true });
  }
};
</script>
