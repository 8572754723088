export const localAreaGovernments = [
  'MOUNT MAGNET, SHIRE OF',
  'GNOWANGERUP, SHIRE OF',
  'COCOS ISLANDS, SHIRE OF',
  'BROOMEHILL-TAMBELLUP, SHIRE OF',
  'COOROW, SHIRE OF',
  'RAVENSTHORPE, SHIRE OF',
  'KULIN, SHIRE OF',
  'KONDININ, SHIRE OF',
  'CRANBROOK, SHIRE OF',
  'DUNDAS, SHIRE OF',
  'SHARK BAY, SHIRE OF',
  'JERRAMUNGUP, SHIRE OF',
  'DENMARK, SHIRE OF',
  'DANDARAGAN, SHIRE OF',
  'KALGOORLIE-BOULDER, CITY OF',
  'COOLGARDIE, SHIRE OF',
  'KARRATHA, CITY OF',
  'ESPERANCE, SHIRE OF',
  'MUKINBUDIN, SHIRE OF',
  'NUNGARIN, SHIRE OF',
  'CUE, SHIRE OF',
  'CARNAMAH, SHIRE OF',
  'COLLIE, SHIRE OF',
  'TAMMIN, SHIRE OF',
  'TRAYNING, SHIRE OF',
  'CHRISTMAS ISLAND, SHIRE OF',
  'MURCHISON, SHIRE OF',
  'CHITTERING, SHIRE OF',
  'MERREDIN, SHIRE OF',
  'KATANNING, SHIRE OF',
  'WILLIAMS, SHIRE OF',
  'NAREMBEEN, SHIRE OF',
  'CAPEL, SHIRE OF',
  'NARROGIN, SHIRE OF',
  'BROOKTON, SHIRE OF',
  'AUGUSTA MARGARET RIVER, SHIRE OF',
  'BUSSELTON, CITY OF',
  'BROOME, SHIRE OF',
  'NANNUP, SHIRE OF',
  'DONNYBROOK-BALINGUP, SHIRE OF',
  'GINGIN, SHIRE OF',
  'ASHBURTON, SHIRE OF',
  'EXMOUTH, SHIRE OF',
  'BEVERLEY, SHIRE OF',
  'BODDINGTON, SHIRE OF',
  'WANDERING, SHIRE OF',
  'WEST ARTHUR, SHIRE OF',
  'WYALKATCHEM, SHIRE OF',
  'KOJONUP, SHIRE OF',
  'WOODANILLING, SHIRE OF',
  'PORT HEDLAND, TOWN OF',
  'EAST PILBARA, SHIRE OF',
  'DOWERIN, SHIRE OF',
  'PLANTAGENET, SHIRE OF',
  'ALBANY, CITY OF',
  'BUNBURY, CITY OF',
  'LEONORA, SHIRE OF',
  'LAVERTON, SHIRE OF',
  'MINGENEW, SHIRE OF',
  'HARVEY, SHIRE OF',
  'CLAREMONT, TOWN OF',
  'CUNDERDIN, SHIRE OF',
  'IRWIN, SHIRE OF',
  'MORAWA, SHIRE OF',
  'BOYUP BROOK, SHIRE OF',
  'KENT, SHIRE OF',
  'LAKE GRACE, SHIRE OF',
  'KELLERBERRIN, SHIRE OF',
  'BRUCE ROCK, SHIRE OF',
  'THREE SPRINGS, SHIRE OF',
  'HALLS CREEK, SHIRE OF',
  'WYNDHAM-EAST KIMBERLEY, SHIRE OF',
  'FREMANTLE, CITY OF',
  'DERBY/WEST KIMBERLEY, SHIRE OF',
  'PERENJORI, SHIRE OF',
  'WAGIN, SHIRE OF',
  'DUMBLEYUNG, SHIRE OF',
  'DARDANUP, SHIRE OF',
  'CAMBRIDGE, TOWN OF',
  'QUAIRADING, SHIRE OF',
  'YORK, SHIRE OF',
  'KOORDA, SHIRE OF',
  'DALWALLINU, SHIRE OF',
  'ARMADALE, CITY OF',
  'SERPENTINE-JARRAHDALE, SHIRE OF',
  'VICTORIA PARK, TOWN OF',
  'PERTH, CITY OF',
  'SOUTH PERTH, CITY OF',
  'WAROONA, SHIRE OF',
  'BRIDGETOWN-GREENBUSHES, SHIRE OF',
  'MANJIMUP, SHIRE OF',
  'CARNARVON, SHIRE OF',
  'UPPER GASCOYNE, SHIRE OF',
  'EAST FREMANTLE, TOWN OF',
  'JOONDALUP, CITY OF',
  'WANNEROO, CITY OF',
  'BELMONT, CITY OF',
  'KALAMUNDA, CITY OF',
  'GOSNELLS, CITY OF',
  'CANNING, CITY OF',
  'STIRLING, CITY OF',
  'VINCENT, CITY OF',
  'CHAPMAN VALLEY, SHIRE OF',
  'NORTHAMPTON, SHIRE OF',
  'PINGELLY, SHIRE OF',
  'CUBALLING, SHIRE OF',
  'TOODYAY, SHIRE OF',
  'NORTHAM, SHIRE OF',
  'ROCKINGHAM, CITY OF',
  'PEPPERMINT GROVE, SHIRE OF',
  'MOSMAN PARK, TOWN OF',
  'COTTESLOE, TOWN OF',
  'MOUNT MARSHALL, SHIRE OF',
  'MELVILLE, CITY OF',
  'YALGOO, SHIRE OF',
  'GREATER GERALDTON, CITY OF',
  'KWINANA, CITY OF',
  'COCKBURN, CITY OF',
  'WICKEPIN, SHIRE OF',
  'CORRIGIN, SHIRE OF',
  'SANDSTONE, SHIRE OF',
  'MOORA, SHIRE OF',
  'VICTORIA PLAINS, SHIRE OF',
  'MENZIES, SHIRE OF',
  'MEEKATHARRA, SHIRE OF',
  'WILUNA, SHIRE OF',
  'NGAANYATJARRAKU, SHIRE OF',
  'MUNDARING, SHIRE OF',
  'BAYSWATER, CITY OF',
  'BASSENDEAN, TOWN OF',
  'SWAN, CITY OF',
  'MANDURAH, CITY OF',
  'MURRAY, SHIRE OF',
  'WONGAN-BALLIDU, SHIRE OF',
  'GOOMALLING, SHIRE OF',
  'NEDLANDS, CITY OF',
  'SUBIACO, CITY OF',
  'WESTONIA, SHIRE OF',
  'YILGARN, SHIRE OF',
];
