<template>
  <div class="flex w-full flex-row overflow-y-auto">
    <div v-if="!asModal && selectedFireBan?.isActive" class="w-2 rounded-l-lg bg-red"></div>

    <div
      class="flex w-full flex-col gap-y-4"
      :class="{
        'p-4': !asModal,
      }"
    >
      <div class="flex flex-row justify-between">
        <div class="flex items-center gap-4">
          <img class="size-10" :src="selectedFireBan?.icon" alt="" />
          <div class="flex flex-1 flex-col">
            <gv-type uplifted-variant="subtitle-sm" :text="selectedFireBan?.description" breakpoint="none" />
            <div class="text-xs text-type-secondary xs:text-base">
              <gv-type uplifted-variant="body-2"> Local Government District of {{ (selectedFireBan as TotalFireBanEntity)?.lga }} </gv-type>
            </div>
          </div>
        </div>
        <button
          v-if="!hideCloseButton"
          class="flex size-6 min-h-6 min-w-6 cursor-pointer items-center justify-center"
          @click="mapStore.closePopup()"
        >
          <close-rounded aria-label="Close" class="*:fill-type-primary" />
        </button>
      </div>

      <div class="h-px w-full bg-grey-35"></div>

      <div class="flex max-h-[344px] flex-col gap-y-4 overflow-y-auto">
        <div>
          <gv-type uplifted-variant="heading" text="Status" breakpoint="none" />
          <ul class="ml-2 grow list-inside list-disc text-base text-type-secondary">
            <li>{{ selectedFireBan?.description }}</li>
          </ul>
        </div>

        <div>
          <gv-type uplifted-variant="heading" text="Declared for" breakpoint="none" />
          <ul class="ml-2 grow list-inside list-disc text-base text-type-secondary">
            <li>{{ dateOfBanContent }}</li>
            <li>{{ (selectedFireBan as TotalFireBanEntity)?.banDuration || 'All day' }}</li>
          </ul>
        </div>

        <div
          v-if="selectedFireBanEvent?.banInformation && selectedFireBan?.isActive"
          class="tfb-prose text-base"
          v-html="useSanitiseHtml(selectedFireBanEvent.banInformation)"
        ></div>
      </div>

      <ui-map-popup-footer
        v-if="showFooter && coordinates"
        left-text="Zoom map"
        right-text="More information"
        :left-callback="
          () => {
            flyToPosition({
              zoom: 13,
              center: coordinates,
            });
          }
        "
        :right-callback="navigateToMoreInfo"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { TotalFireBanEntity, useHazardsStore, useMapStore } from '@/features';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import CloseRounded from '@/common/assets/icons/close-rounded.svg';
import { LngLatLike } from 'mapbox-gl';
import { useSanitiseHtml } from '@/common';
import { DateTime } from 'luxon';

withDefaults(
  defineProps<{
    showFooter?: boolean;
    asModal?: boolean;
    coordinates?: LngLatLike;
    hideCloseButton?: boolean;
  }>(),
  {
    showFooter: true,
    asModal: false,
    coordinates: undefined,
    hideCloseButton: false,
  }
);

const mapStore = useMapStore();
const { selectedPopupHazard } = storeToRefs(mapStore);
const { flyToPosition } = mapStore;
const hazardStore = useHazardsStore();

const { filteredSectionHazards, selectedHazard, hazards, selectedDateIndex, dates } = storeToRefs(hazardStore);

const selectedFireBan = computed(() => {
  return filteredSectionHazards?.value.totalFireBans?.find((fitreban) =>
    [selectedPopupHazard.value?.hazardId, selectedHazard.value?.hazardId].includes(fitreban.id)
  );
});

const selectedFireBanEvent = computed(() => {
  return hazards?.value.events?.find((event) => selectedFireBan.value?.eventId === event.id);
});

const dateOfBanContent = computed(() => {
  if (!selectedFireBan.value?.isActive) {
    const selectedDate = dates.value[selectedDateIndex.value];
    switch (selectedDate) {
      case 'Today':
        return DateTime.now().startOf('day').toFormat('d LLLL yyyy');
      case 'Tomorrow':
        return DateTime.now().startOf('day').plus({ day: 1 }).toFormat('d LLLL yyyy');
    }
  }

  if (selectedFireBanEvent?.value?.dateOfBan) {
    return selectedFireBanEvent?.value?.dateOfBan.toFormat('d LLLL yyyy');
  }

  return '';
});

const navigateToMoreInfo = () => {
  window.open('https://www.dfes.wa.gov.au/hazard-information/bushfire/total-fire-ban');
};
</script>

<style>
.tfb-prose ul {
  list-style: disc !important;
  list-style-position: outside !important;
  margin-left: 8px;
}

.tfb-prose a {
  color: #0070f3;
  text-decoration: underline;
}
</style>
