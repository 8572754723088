import { HAZARD_MARKERS, IncidentSubType, Scheme } from '@/features/hazards';

type IncidentsToSchemeMap = Record<IncidentSubType, Scheme>;

export const incidentsToSchemeMap: IncidentsToSchemeMap = {
  // Bushfire
  [IncidentSubType.BushfireIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-bushfire-active'],
  },

  // Coastal Hazards
  [IncidentSubType.CoastalHazardIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-coastal-hazard'],
  },

  // Cyclone
  [IncidentSubType.CycloneIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-cyclone'],
  },

  // Damaging Winds
  [IncidentSubType.DamagingWindsIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-damaging-winds'],
  },

  // Earthquake
  [IncidentSubType.EarthquakeIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-earthquake'],
  },

  // Flood
  [IncidentSubType.FloodIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-flood'],
  },

  // Hazardous Materials
  [IncidentSubType.HazardousMaterialsIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-hazardous-materials'],
  },

  // Human e=Epidemic
  [IncidentSubType.HumanEpidemicIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-human-epidemic'],
  },

  // Human Pandemic
  [IncidentSubType.HumanPandemicIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-human-pandemic'],
  },

  // Other Incident
  [IncidentSubType.OtherIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-other-incident'],
  },

  // Rescue
  [IncidentSubType.RescueIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-rescue'],
  },

  // Storm
  [IncidentSubType.StormIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-storm'],
  },

  // Storm Surge
  [IncidentSubType.StormSurgeIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-storm-surge'],
  },

  // Storm Damage
  [IncidentSubType.StormDamageIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-storm-damage'],
  },

  // Structure Fire
  [IncidentSubType.StructureFireIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-structure-fire'],
  },

  // Tsunami
  [IncidentSubType.TsunamiIncidentEntity]: {
    icon: HAZARD_MARKERS['ew-tsunami'],
  },
};
