import { EntitySubType, HazardPriorityMapping, PriorityHazardFeature } from '@/features/hazards';
import { Feature, Geometry, GeoJsonProperties } from 'geojson';
import { DateTime } from 'luxon';

export const getSortedHazardFeatures = (hazards: Feature<Geometry, GeoJsonProperties>[]) => {
  const priorityHazardFeatures: PriorityHazardFeature[] = hazards
    .filter((feature) => feature.geometry.type === 'Point' || feature.geometry.type === 'Polygon')
    .map((feature) => {
      const entitySubType = feature.properties?.entitySubType as (typeof EntitySubType)[keyof typeof EntitySubType];
      const priority = HazardPriorityMapping[entitySubType] ?? 0;
      return { priority, hazardFeature: feature };
    });

  priorityHazardFeatures.sort((a, b) => {
    if (a.priority !== b.priority) {
      return b.priority - a.priority;
    }

    const aUpdatedAt = a.hazardFeature.properties?.updatedAt as string;
    const bUpdatedAt = b.hazardFeature.properties?.updatedAt as string;

    const aMillis = DateTime.fromISO(aUpdatedAt).toMillis();
    const bMillis = DateTime.fromISO(bUpdatedAt).toMillis();

    return bMillis - aMillis;
  });

  return priorityHazardFeatures.map((priorityHazardFeature) => priorityHazardFeature.hazardFeature);
};
