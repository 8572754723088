import { createRouter, createWebHistory } from 'vue-router';

import { routes } from '../router/routes';
import { useTitle } from '@vueuse/core';
import { captureException, captureMessage } from '@sentry/vue';
import { getSanitisedHazardType, HazardTypeToRouteTitleMap } from '@/features';

export const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')) {
    if (!to?.fullPath) return window.location.reload();

    window.location.href = to.fullPath;
    return;
  }

  captureException(error);
});

export const pageTitleHandler = useTitle();

router.beforeEach((to, _, next) => {
  const { hazardId, hazardType } = to.params;

  // Addresses a weird issue where there is a double navigation to the same route
  // which causes the hazard type to be set as the hazard ID
  if (hazardId && hazardType && hazardId === hazardType) {
    captureMessage('Route navigation to the same route with the same params', {});
    return;
  }

  const sanitisedHazardType = getSanitisedHazardType(hazardType)
    ? HazardTypeToRouteTitleMap[getSanitisedHazardType(hazardType)!]
    : undefined;

  pageTitleHandler.value = sanitisedHazardType ?? (to?.meta?.title as string) ?? 'Unknown Page';

  if (to?.meta?.description) {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) metaDescription.setAttribute('content', to.meta.description as string);
    else {
      const meta = document.createElement('meta');
      meta.name = 'description';
      meta.content = to.meta.description as string;
      document.head.appendChild(meta);
    }
  }

  next();
});

export default router;

export * from './types';
