<template>
  <nav class="flex h-header">
    <ui-header-nav-item
      v-for="appRoute in headerNavBarRoutes"
      :key="appRoute.title"
      :route="appRoute"
      class="h-full"
      :is-active="activeRoute === appRoute.link"
    />
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { headerNavBarRoutes } from './index';

const route = useRoute();

const activeRoute = computed(() => route.path);
</script>
