import { defineStore } from 'pinia';
import { DatasourceSingleton } from '@/common/di';
import {
  CentreEntity,
  DropdownSelected,
  EntitySubType,
  HazardEntity,
  HazardsEntity,
  HazardsRepositoryImpl,
  HazardType,
  hazardTypeRoute,
  HazardView,
  IncidentEntity,
  MapMode,
  naturalHazardTypes,
  SelectedHazard,
  SelectedHazardViewType,
  typeSections,
  useMapStore,
  WarningEntity,
  MapGuideFireDanger,
  UiListTileLeadingColor,
  UiListTileBackgroundColor,
  HAZARD_MARKERS,
  WarningSubTypes,
} from '@/features/hazards';
import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { DateTime } from 'luxon';
import { container } from 'tsyringe';
import { computed, ref } from 'vue';
import { showBothHazardView } from '@/common/helpers/sizes';
import { uniqueBy } from 'remeda';
import { useRouter } from 'vue-router';
import { RouteNames, pageTitleHandler } from '@/router';
import { useOnline } from '@vueuse/core';

export const useHazardsStore = defineStore('hazardsStore', () => {
  // This is to check if the user is online and gracefully handle the offline state
  // @see - https://vueuse.org/core/useOnline/
  const isOnline = useOnline();

  // TODO: datasource and repository should be injected as arguments
  const datasource = container.resolve(DatasourceSingleton).hazards;
  const s3Datasource = container.resolve(DatasourceSingleton).s3Hazards;

  const router = useRouter();

  const mapStore = useMapStore();

  const hazardRepository = new HazardsRepositoryImpl(datasource, s3Datasource);

  const searchTerm = ref<string>('');
  const mapMode = ref<MapMode>(MapMode.Default);
  const selectedDateIndex = ref<number>(0);

  const dates = computed(() => {
    const datesArray = ['Today', 'Tomorrow'];

    const today = DateTime.now();

    if (mapMode.value !== MapMode.FireDanger) return datesArray;

    const in2Days = today.plus({ days: 2 });
    const in3Days = today.plus({ days: 3 });

    datesArray.push(in2Days.toFormat('dd MMM'));
    datesArray.push(in3Days.toFormat('dd MMM'));

    return datesArray;
  });

  const selectedHazard = ref<SelectedHazard | null>(null);

  const typesFilters = ref<DropdownSelected>(
    Object.fromEntries(typeSections.map((section) => [section.label, Object.fromEntries(section.items.map((item) => [item.label, false]))]))
  );

  const isSomeTypeFilterActive = computed<boolean>(() =>
    Object.values(typesFilters.value).some((section) => Object.values(section).some((checked) => checked))
  );

  const hazardView = ref<HazardView>(!showBothHazardView.value ? HazardView.List : HazardView.Both);

  const activeView = computed<HazardView>(() =>
    !showBothHazardView.value && hazardView.value === HazardView.Both ? HazardView.Map : hazardView.value
  );

  const setActiveView = (view: HazardView) => {
    if (view === hazardView.value) return;

    let sanitisedView = view;

    if (!showBothHazardView.value && sanitisedView === HazardView.Both) {
      sanitisedView = HazardView.Map;
    }

    router.push({ query: { ...(router.currentRoute.value.query ?? {}), view: sanitisedView.toLowerCase() } });

    hazardView.value = sanitisedView;
  };

  const setMapMode = (mode: MapMode) => {
    if (mode === mapMode.value) return;

    const query = { view: hazardView.value.toLowerCase() };

    if ([MapMode.FireBan, MapMode.FireDanger].includes(mode)) {
      router.push({
        path: mode === MapMode.FireBan ? hazardTypeRoute(HazardType.TotalFireBans) : hazardTypeRoute(HazardType.FireDangerRating),
        query,
      });
    } else {
      router.push({ name: RouteNames.Hazards, query });
    }

    mapMode.value = mode;

    // Reset the selected date index to prevent the date from being out of bounds
    selectedDateIndex.value = 0;
  };

  const setSelectedDateIndex = (index: number) => {
    if (index === selectedDateIndex.value || index < 0 || index > 3) return;

    let sanitisedIndex = index;

    if (mapMode.value === MapMode.FireBan) {
      sanitisedIndex = Math.min(1, index);
    } else if (mapMode.value === MapMode.FireDanger) {
      sanitisedIndex = Math.min(3, index);
    }

    router.push({ query: { ...(router.currentRoute.value.query ?? {}), date: sanitisedIndex.toString() } });

    selectedDateIndex.value = sanitisedIndex;
  };

  const setSelectedHazard = (hazard: SelectedHazard | null) => {
    if (selectedHazard.value?.hazardId === hazard?.hazardId) {
      selectedHazard.value = null;
      return;
    }

    let eventId = hazard?.eventId;

    if (hazard?.hazardType) {
      const hazardEntity = (hazards.value[hazard?.hazardType] as HazardEntity[])?.find(
        (h: HazardEntity) => h.id === hazard?.hazardId
      ) as HazardEntity;

      if (hazardEntity) {
        const { title } = hazardEntity;

        if (!eventId?.length) {
          eventId = hazardEntity?.eventId;
        }

        if (title?.length) {
          const pageTitle = `${title} - ${pageTitleHandler.value}`;

          pageTitleHandler.value = pageTitle;
        }
      }
    }

    selectedHazard.value = hazard ? { ...hazard, eventId: eventId ?? '' } : null;
  };

  const retryButton = ref(false);

  const isLoadingHazards = ref(false);
  const isLoadingEnrichedWarning = ref(false);

  const hazards = ref<HazardsEntity>({
    incidents: [],
    warnings: [],
    centres: [],
    closures: [],
    events: [],
    totalFireBans: [],
    fireDangerRatings: [],
    prescribedBurns: [],
  });

  const filteredSectionHazards = computed(() => {
    const activeFilters: Set<(typeof EntitySubType)[keyof typeof EntitySubType]> = new Set();

    //! Warnings filters
    const warningSection = typeSections.find((typeSection) => typeSection.label === 'Warnings');
    Object.entries(typesFilters.value.Warnings)
      .filter((warningFilter) => warningFilter[1])
      .forEach(([filter]) =>
        warningSection?.items.find((item) => item.label === filter)?.hazardSubType?.forEach((warningType) => activeFilters.add(warningType))
      );

    // Filter out total fire bans (until the API is update to not include them in warnings endpoint)
    const warnings = hazards.value.warnings.filter((warning) => warning.entitySubType !== WarningSubTypes.TotalFireBanWarningEntity);
    const filteredWarnings = !isSomeTypeFilterActive.value
      ? warnings
      : warnings.filter((warning) => activeFilters.has(warning.entitySubType!));

    //! Natural hazards
    const naturalHazardsChecked = typesFilters.value['Natural Hazards']?.['Default'];
    const naturalHazards = hazards.value.incidents.filter((incident) => naturalHazardTypes.includes(incident.incidentType));
    const filteredNaturalHazards = !isSomeTypeFilterActive.value || naturalHazardsChecked ? naturalHazards : [];

    //! Incidents filters
    const incidentChecked = typesFilters.value.Incidents?.['Default'];

    const nonSpecialIncidents = hazards.value.incidents.filter(
      (incident) => incident.title !== 'Burn Off' && !naturalHazardTypes.includes(incident.incidentType)
    );
    const filteredIncidents = !isSomeTypeFilterActive.value || incidentChecked ? nonSpecialIncidents : [];

    //! Centres and closures
    const centresAndClosuresSection = typeSections.find((typeSection) => typeSection.label === 'Centres and Closures');
    Object.entries(typesFilters.value['Centres and Closures'])
      .filter((centreOrClosureFilter) => centreOrClosureFilter[1])
      .forEach(([filter]) =>
        centresAndClosuresSection?.items
          .find((item) => item.label === filter)
          ?.hazardSubType?.forEach((centreOrClosureType) => activeFilters.add(centreOrClosureType))
      );

    const centresAndClosures = hazards.value.closures.concat(hazards.value.centres);
    const filteredCentresAndClosures = !isSomeTypeFilterActive.value
      ? centresAndClosures
      : centresAndClosures.filter((centreAndClosure) => activeFilters.has(centreAndClosure.entitySubType!));

    //! Prescribed burns and burn offs
    const prescribedBurnsAndBurnOffsChecked = typesFilters.value['Prescribed Burns/Burn Offs']?.['Default'];

    const prescribedBurnsAndBurnOffs = [
      ...hazards.value.prescribedBurns,
      ...hazards.value.incidents.filter((incident) => incident.title === 'Burn Off'),
    ];

    const filteredPrescribedBurnsAndBurnOffs =
      !isSomeTypeFilterActive.value || prescribedBurnsAndBurnOffsChecked ? prescribedBurnsAndBurnOffs : [];

    //! Fire Danger Ratings
    const filtered = {
      incidents: filteredIncidents,
      warnings: filteredWarnings,
      centresAndClosures: filteredCentresAndClosures,
      prescribedBurnsAndBurnOffs: filteredPrescribedBurnsAndBurnOffs,
      naturalHazards: filteredNaturalHazards,
      totalFireBans: getTotalFireBansByDateIndex(selectedDateIndex.value),
      fireDangerRatings: hazards.value.fireDangerRatings,
    };

    return filtered;
  });

  const totalFireBanForToday = computed(() => {
    if (!filteredSectionHazards.value) return;

    const fireBansForToday = filteredSectionHazards.value.totalFireBans.filter((hazard) => {
      if (!hazard.dateOfBan || !hazard.isActive) {
        return false;
      }

      return DateTime.now().startOf('day').diff(hazard.dateOfBan.startOf('day')).as('days') === 0;
    });

    if (!fireBansForToday.length) {
      return;
    }

    return {
      ...(fireBansForToday.at(0) as HazardEntity),
      title: 'Total Fire Bans',
      description: 'are declared for today',
    };
  });

  const extremeOrCatastrophicFireDangerRatingForToday = computed(() => {
    const fireDangerRatingForToday = filteredSectionHazards?.value.fireDangerRatings.find((hazard) => {
      return [MapGuideFireDanger.Catastrophic, MapGuideFireDanger.Extreme].includes(
        `${hazard.pointFeature?.properties?.forecasts?.[0]?.fireDanger}` as MapGuideFireDanger
      );
    });
    if (!fireDangerRatingForToday) return;

    return {
      ...fireDangerRatingForToday,
      entityType: HazardType.FireDangerRating,
      title: 'Extreme or Catastrophic Fire Danger Ratings',
      description: 'are declared for today',
      backgroundColor: UiListTileBackgroundColor.Orange,
      leadingColor: UiListTileLeadingColor.Orange,
      icon: HAZARD_MARKERS['ew-extreme-or-catastrophic-fdr'],
    } as HazardEntity;
  });

  const getHazardsByEvent = (eventId?: string) => {
    return {
      incidents: filteredSectionHazards.value.incidents.filter((incident) => incident.eventId === eventId) as IncidentEntity[],
      warnings: filteredSectionHazards.value.warnings.filter((warning) => warning.eventId === eventId) as WarningEntity[],
      centresAndClosures: filteredSectionHazards.value.centresAndClosures.filter(
        (centreAndClosure) => centreAndClosure.eventId === eventId
      ) as CentreEntity[],
      prescribedBurnsAndBurnOffs: filteredSectionHazards.value.prescribedBurnsAndBurnOffs.filter(
        (burnOrBurnOff) => burnOrBurnOff.eventId === eventId
      ) as IncidentEntity[],
      naturalHazards: filteredSectionHazards.value.naturalHazards.filter(
        (naturalHazard) => naturalHazard.eventId === eventId
      ) as IncidentEntity[],
    };
  };

  const fetchAllHazards = async (hazardTypes: HazardType[]) => {
    if (isOnline.value) {
      isLoadingHazards.value = true;
      const fetchPromises = hazardTypes.map((hazardType) => fetchHazards(hazardType));
      await Promise.all(fetchPromises);
      isLoadingHazards.value = false;
    }
  };

  const fetchHazards = async (hazardType: HazardType) => {
    const maybeHazards = await hazardRepository.getHazards(hazardType);
    pipe(
      maybeHazards,
      fold(
        () => {
          // toast.addToast({
          //   text: mapErrorToMessage(error),
          //   scheme: 'error',
          // });
        },
        (hazard) => {
          // Guaranteed to be a hazard of the correct type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          hazards.value[hazardType] = hazard as any[];
        }
      )
    );
  };

  /**
   * This will filter the fire bans based on the selected date as well as the ban level.
   *
   * i.e. if the ban level is 'No ban' (inactive fire ban) then it will return all the fire bans.
   * If the ban level is 'Ban' (active fire ban) then it will return the fire bans for the selected date.
   *
   * @param fireBans - The fire bans to filter
   * @returns - The filtered fire bans.
   */
  const getTotalFireBansByDateIndex = (dateIndex: number) => {
    const filteredTotalFireBans = hazards.value.totalFireBans.filter((fireBan) => {
      if (fireBan.banLevel === 'No ban' || !fireBan.dateOfBan) {
        return true;
      }

      const currentDate = DateTime.now().startOf('day');
      const dateOfBan = fireBan.dateOfBan.startOf('day');
      const daysDiff = dateOfBan.diff(currentDate).as('days');

      switch (dateIndex) {
        case 0:
          return daysDiff === 0;
        case 1:
          return daysDiff === 1;
      }

      return false;
    });

    // Ensure that the fire bans are unique by LGA.
    // This is because there can be multiple fire bans for the same LGA
    // which should not be displayed multiple times on the map/list
    return uniqueBy(filteredTotalFireBans, (fireBanItem) => fireBanItem.lga);
  };

  /**
   * Handles the selected hazard.
   * @param selectedHazard - The selected hazard
   * @returns
   */
  const handleSelectedHazard = (hazard: SelectedHazard) => {
    if (selectedHazard.value?.hazardId === hazard.hazardId) {
      return;
    }

    let eventId = hazard?.eventId;

    if (!eventId && hazard?.hazardType) {
      eventId =
        ((hazards.value[hazard?.hazardType] as HazardEntity[])?.find((h: HazardEntity) => h.id === hazard?.hazardId) as HazardEntity)
          ?.eventId ?? '';
    }

    if (activeView.value !== HazardView.List && hazard.viewType === SelectedHazardViewType.Popup) {
      mapStore.setSelectedPopupHazard({ ...hazard, eventId }, true, true);
      return;
    }

    if (hazard.viewType === SelectedHazardViewType.Modal) {
      mapStore.setSelectedPopupHazard(null);
    }

    setSelectedHazard({ ...hazard, eventId });
  };

  /**
   * Toggles the selected hazard by navigating to the hazard route.
   * If the hazard is not null, it will navigate to the hazard page.
   * If the hazard is null, it will navigate to the hazards page (and clear the selected hazard).
   *
   * @param hazard - The selected hazard or null
   */
  const toggleSelectHazard = (hazard: SelectedHazard | null) => {
    if (hazard) {
      router.push({
        name: RouteNames.HazardById,
        params: {
          hazardType: hazardTypeRoute(hazard.hazardType),
          hazardId: hazard.hazardId,
        },
        query: {
          display: hazard.viewType,
        },
      });
    } else {
      if (selectedHazard.value) {
        setSelectedHazard(null);
      }

      const currentParams = router.currentRoute.value?.params;
      const currentHazardType = currentParams?.hazardType;

      if (currentHazardType && [MapMode.FireBan, MapMode.FireDanger].includes(mapMode.value)) {
        router.push({
          name: RouteNames.HazardByType,
          params: {
            hazardType: currentParams?.hazardType,
          },
        });
      } else {
        router.push({
          name: RouteNames.Hazards,
        });
      }
    }
  };

  // Sources
  const getHazardPolygonsGeoSource = computed((): FeatureCollection<Geometry, GeoJsonProperties> => {
    const warningsPolygonFeatures = filteredSectionHazards.value.warnings
      .flatMap((warning) => warning.polygonFeatures)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const mergedPolygonGeoSource: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: warningsPolygonFeatures,
    };

    return mergedPolygonGeoSource;
  });

  const getHazardFireShapePolygonsGeoSource = computed((): FeatureCollection<Geometry, GeoJsonProperties> => {
    const incidentsPolygonFeatures = hazards.value.incidents
      .flatMap((incident) => incident.polygonFeatures)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const mergedPolygonGeoSource: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: incidentsPolygonFeatures,
    };

    return mergedPolygonGeoSource;
  });

  const getHazardPointGeoSource = computed((): FeatureCollection<Geometry, GeoJsonProperties> => {
    const warningsPointFeatures = filteredSectionHazards.value.warnings
      .map((warning) => warning.pointFeature)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const incidentsPointFeatures = filteredSectionHazards.value.incidents
      .map((incident) => incident.pointFeature)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const centresAndClosuresPointFeatures = filteredSectionHazards.value.centresAndClosures
      .map((centreAndClosure) => centreAndClosure.pointFeature)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const prescribedBurnsAndBurnOffsPointFeatures = filteredSectionHazards.value.prescribedBurnsAndBurnOffs
      .map((prescribedBurnAndBurnOff) => prescribedBurnAndBurnOff.pointFeature)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const naturalHazardsPointFeatures = filteredSectionHazards.value.naturalHazards
      .map((naturalHazard) => naturalHazard.pointFeature)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const mergedPointGeoSource: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: warningsPointFeatures
        .concat(incidentsPointFeatures)
        .concat(centresAndClosuresPointFeatures)
        .concat(prescribedBurnsAndBurnOffsPointFeatures)
        .concat(naturalHazardsPointFeatures),
    };

    return mergedPointGeoSource;
  });

  const getFireDangerRatingsPointGeoSource = computed((): FeatureCollection<Geometry, GeoJsonProperties> => {
    const fireDangerRatingPointFeatures = hazards.value.fireDangerRatings
      .flatMap((fireDangerRatings) => fireDangerRatings.pointFeature)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const mergedPointGeoSource: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: fireDangerRatingPointFeatures,
    };

    return mergedPointGeoSource;
  });

  const getFireDangerRatingsPolygonsGeoSource = computed((): FeatureCollection<Geometry, GeoJsonProperties> => {
    const fireDangerRatingPolygonFeatures = hazards.value.fireDangerRatings
      .flatMap((fireDangerRatings) => fireDangerRatings.polygonFeatures)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const mergedPolygonGeoSource: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: fireDangerRatingPolygonFeatures,
    };

    return mergedPolygonGeoSource;
  });

  const getPrescribedBurnsPointGeoSource = computed((): FeatureCollection<Geometry, GeoJsonProperties> => {
    const prescribedBurnsPointFeatures = hazards.value.prescribedBurns
      .flatMap((prescribedBurns) => prescribedBurns.pointFeature)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const mergedPointGeoSource: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: prescribedBurnsPointFeatures,
    };

    return mergedPointGeoSource;
  });

  const getPrescribedBurnsPolygonsGeoSource = computed((): FeatureCollection<Geometry, GeoJsonProperties> => {
    const prescribedBurnsPolygonFeatures = hazards.value.prescribedBurns
      .flatMap((prescribedBurns) => prescribedBurns.polygonFeatures)
      .filter((feature) => !!feature) as Feature<Geometry, GeoJsonProperties>[];

    const mergedPolygonGeoSource: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: prescribedBurnsPolygonFeatures,
    };

    return mergedPolygonGeoSource;
  });

  return {
    hazards,
    mapMode,
    setMapMode,
    searchTerm,
    activeView,
    typesFilters,
    isSomeTypeFilterActive,
    retryButton,
    selectedHazard,
    isLoadingHazards,
    isLoadingEnrichedWarning,
    fetchHazards,
    setActiveView,
    getHazardsByEvent,
    fetchAllHazards,
    handleSelectedHazard,
    getHazardPointGeoSource,
    getHazardPolygonsGeoSource,
    getHazardFireShapePolygonsGeoSource,
    filteredSectionHazards,
    selectedDateIndex,
    setSelectedDateIndex,
    dates,
    getFireDangerRatingsPointGeoSource,
    getFireDangerRatingsPolygonsGeoSource,
    getPrescribedBurnsPolygonsGeoSource,
    getPrescribedBurnsPointGeoSource,
    toggleSelectHazard,
    totalFireBanForToday,
    extremeOrCatastrophicFireDangerRatingForToday,
    getTotalFireBansByDateIndex,
  };
});
