<template>
  <div
    :data-cy="`gvToast-${scheme}`"
    class="flex rounded border bg-white shadow-md"
    :class="
      {
        info: 'border-primary-500',
        success: 'border-green-500',
        warning: 'border-status-warning',
        error: 'border-status-error',
      }[scheme]
    "
  >
    <div
      class="flex items-center justify-center p-3"
      :class="
        {
          info: 'bg-primary-500',
          success: 'bg-green-500',
          warning: 'bg-status-warning',
          error: 'bg-status-error',
        }[scheme]
      "
    >
      <gv-icon
        :name="
          {
            info: 'info',
            success: 'check_circle',
            warning: 'warning',
            error: 'error',
          }[scheme]
        "
        class="fill-white"
        fill
      />
    </div>

    <div class="flex w-full items-center gap-x-4 px-4 py-2">
      <gv-type class="font-medium">
        {{ text }}
      </gv-type>

      <button class="ml-auto flex items-center justify-center" @click="emit('close')">
        <gv-icon name="close" class="fill-grey-400" size="sm" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import { ToastScheme } from './types';

const emit = defineEmits<{
  (event: 'close'): void;
}>();

defineProps({
  text: {
    type: String,
    required: true,
  },
  scheme: {
    type: String as PropType<ToastScheme>,
    default: 'success',
  },
});
</script>
