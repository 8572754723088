export const BREAKPOINTS = {
  '5xs': '320px',
  '4xs': '375px',
  '3xs': '414px',
  '2xs': '480px',
  xs: '640px',
  sm: '768px',
  md: '1024px',
  lg: '1280px',
  xl: '1536px',
  '2xl': '1920px',
};

export const BREAKPOINTS_INT = { '3xs': 375, '2xs': 480, xs: 640, sm: 768, md: 1024, lg: 1280, xl: 1536, '2xl': 1920 };

export const enum Height {
  Header = 72,
  Footer = 48,
  Toolbar = 112,
  ToolbarMd = 64,
}

export const enum HazardsListWidth {
  Md = 480,
  Xl = 684,
}
