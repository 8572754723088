<template>
  <component
    :is="is"
    class="gvButton inline-flex items-center rounded-lg transition-[box-shadow_background-color] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-default"
    :class="`${schemeVariantClass} ${variantClass} ${sizeClass} ${paddingClass} ${squareClass}`"
    :href="href"
    :to="to"
    :disabled="disabled"
  >
    <slot>
      <gv-type class="w-full" scheme="custom" align="center" wrap="nowrap">
        {{ text }}
      </gv-type>
    </slot>
  </component>
</template>

<script setup lang="ts">
import { ButtonHTMLAttributes, computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';

import { Scheme, Variant, Size, Padding } from './types';

export interface GvButtonProps extends /* @vue-ignore */ ButtonHTMLAttributes {
  name?: string;
  text?: string;
  href?: string;
  to?: RouteLocationRaw;
  disabled?: boolean;
  scheme?: Scheme;
  variant?: Variant;
  size?: Size;
  padding?: Padding;
  square?: boolean;
}

const props = withDefaults(defineProps<GvButtonProps>(), {
  disabled: false,
  scheme: 'primary',
  variant: 'solid',
  size: 'md',
  square: false,
});

const is = computed(() => {
  if (props.to) {
    return 'router-link';
  } else if (props.href) {
    return 'a';
  }
  return 'button';
});

const schemeVariantClasses: Record<Scheme, Record<Variant, string>> = {
  primary: {
    solid: 'bg-primary text-primary-on hover:bg-primary-hover focus-visible:ring-primary',
    outline: 'border border-solid border-primary text-primary hover:bg-primary-bg focus-visible:ring-primary',
    text: 'text-primary hover:text-primary-hover focus-visible:ring-primary',
    custom: 'focus-visible:ring-primary',
  },
  secondary: {
    solid: 'bg-secondary text-secondary-on hover:bg-secondary-hover focus-visible:ring-secondary',
    outline: 'border border-solid border-secondary text-secondary hover:bg-secondary-bg focus-visible:ring-secondary',
    text: 'text-secondary hover:text-secondary-hover focus-visible:ring-primary',
    custom: 'focus-visible:ring-primary',
  },
  accent: {
    solid: 'bg-accent text-accent-on hover:bg-accent-hover focus-visible:ring-accent',
    outline: 'border border-solid border-accent text-accent hover:bg-accent-bg focus-visible:ring-accent',
    text: 'text-accent hover:text-accent-hover focus-visible:ring-accent',
    custom: 'focus-visible:ring-accent',
  },
  grey: {
    solid: 'bg-grey-50 text-grey-500 hover:bg-grey-100 focus-visible:ring-primary',
    outline: 'border border-solid border-grey-300 text-grey-600 hover:bg-grey-50 focus-visible:ring-primary',
    text: 'text-grey-400 hover:text-grey-600 focus-visible:ring-primary',
    custom: 'focus-visible:ring-primary',
  },
  custom: {
    solid: 'focus-visible:ring-primary',
    outline: 'focus-visible:ring-primary',
    text: 'focus-visible:ring-primary',
    custom: 'focus-visible:ring-primary',
  },
};

const variantClasses: Record<Variant, string> = {
  solid: 'disabled:bg-grey-50 disabled:hover:bg-grey-50 disabled:text-grey-200',
  outline: 'disabled:border-grey-50 disabled:bg-transparent disabled:text-grey-200',
  text: 'bg-none',
  custom: '',
};

const sizeClasses: Record<Size, string> = {
  sm: 'h-8',
  md: 'h-10',
  lg: 'h-12',
  custom: '',
};

const paddingClasses: Record<Padding, string> = {
  sm: 'px-4',
  md: 'px-6',
  lg: 'px-7',
  xl: 'px-10',
  custom: '',
};

const squareClasses: Record<Size, string> = {
  sm: 'w-8',
  md: 'w-10',
  lg: 'w-12',
  custom: '',
};

const schemeVariantClass = computed(() => schemeVariantClasses[props.scheme][props.variant]);

const variantClass = computed(() => variantClasses[props.variant]);

const sizeClass = computed(() => sizeClasses[props.size]);

const paddingClass = computed(() => (!props.square ? paddingClasses[props.padding ?? props.size] : ''));

const squareClass = computed(() => (props.square ? squareClasses[props.size] : ''));
</script>

<style scoped>
.gvButton {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
