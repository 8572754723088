import { LngLatBoundsLike } from 'mapbox-gl';

import FireBan from '@/common/assets/icons/fire_ban.png';
import Pin from '@/common/assets/icons/pin.png';
import ExtremeOrCatastrophicFDR from '@/common/assets/icons/hazards/ew-extreme-or-catastrophic-fdr.svg?url';
import { CentreSubType, ClosureSubType, EntitySubType, EventSubType, FdrSubType, IncidentSubType, WarningSubTypes } from './domain';

// Incidents
import { CENTRE_HAZARD_ICONS, CLOSURE_HAZARD_ICONS, FDR_HAZARD_ICONS, INCIDENT_HAZARD_ICONS, WARNING_HAZARD_ICONS } from './icons';

export const MAPBOX_STYLE = 'mapbox://styles/robgenvis/clur0nqqy00n001pw43ww2g0u'; // new style without LGA etc.
export const MAPBOX_SATELLITE_STYLE = 'mapbox://styles/robgenvis/clluqt788006001rafc456kkq';
export const WESTERN_AUSTRALIA_BOUNDING_BOX: LngLatBoundsLike = [111.750011, -35.811549, 129.020519, -12.589916];

export const HAZARD_MARKERS = {
  ...INCIDENT_HAZARD_ICONS,
  ...WARNING_HAZARD_ICONS,
  ...CENTRE_HAZARD_ICONS,
  ...CLOSURE_HAZARD_ICONS,
  ...FDR_HAZARD_ICONS,
  'ew-fire-ban': FireBan,
  'ew-extreme-or-catastrophic-fdr': ExtremeOrCatastrophicFDR,
};

export const PIN_IMAGE = {
  id: 'pin',
  url: Pin,
};

export const TOTAL_FIRE_BAN_MARKER = {
  id: 'total-fire-ban',
  url: FireBan,
};

// Sources
export const enum MapboxSource {
  totalFireBanSource = 'total-fire-ban-source',

  addressPinSource = 'address-pin-source',
  osmSource = 'osm-source',
  satelliteSource = 'satellite-source',

  hazardsMarkersSource = 'hazards-markers-source',
  hazardsPolygonsSource = 'hazards-polygons-source',
  hazardsFireShapePolygonsSource = 'hazards-fire-shape-polygons-source',

  totalFireBanMarkersSource = 'total-fire-ban-markers-source',
  totalFireBanPolygonsSource = 'total-fire-ban-polygons-source',

  fireDangerRatingsMarkersSource = 'fire-danger-ratings-markers-source',
  fireDangerRatingsPolygonsSource = 'fire-danger-ratings-polygons-source',

  // Overlays tileset source
  overlaysSource = 'overlays-source',
  overlaysCentresSource = 'overlays-centres-source',

  // BOM sources
  cycloneTrackSource = 'cyclone-track-source',
  rainRadarSource = 'rain-radar-source',
  weatherSatelliteSource = 'weather-satellite-source',
  thunderstormTrackerSource = 'thunderstorm-tracker-source',
  windDirectionSource = 'wind-direction-source',
  floodCatchmentSource = 'flood-catchment-source',
}

// Layers
export const enum MapboxLayer {
  invisibleLayer = 'invisible-layer',
  addressPinLayer = 'address-pin-layer',
  osmLayer = 'osm-layer',

  hazardsPolygonsLayer = 'hazard-polygons-layer',
  hazardsPolygonsStrokeLayer = 'hazard-polygons-stroke-layer',
  hazardsFireShapePolygonsLayer = 'hazard-fire-shape-polygons-layer',
  hazardsFireShapePolygonsStrokeLayer = 'hazard-fire-shape-polygons-stroke-layer',

  // Fire bans layers
  totalFireBanPolygonsLayer = 'total-fire-ban-polygons-layer',
  totalFireBanPolygonsStrokeLayer = 'total-fire-ban-polygons-stroke-layer',
  totalFireBanMarkersLayer = 'total-fire-ban-markers-layer',

  // Fire danger rating layers
  fireDangerRatingsPolygonsLayer = 'fire-danger-ratings-polygons-layer',
  fireDangerRatingsPolygonsStrokeLayer = 'fire-danger-ratings-polygons-stroke-layer',
  fireDangerRatingsMarkersLayer = 'fire-danger-ratings-markers-layer',

  // Overlays layers
  dfesRegionsLayer = 'dfes-regions-layer',
  lgaLayer = 'lga-layer',
  pastoralLayer = 'pastoral-layer',

  // Bom layers
  cycloneTrackLayer = 'cyclone-track-layer',
  rainRadarLayer = 'rain-radar-layer',
  weatherSatelliteLayer = 'weather-satellite-layer',
  thunderstormTrackerLayer = 'thunderstorm-tracker-layer',
  windDirectionLayer = 'wind-direction-layer',
  floodCatchmentLayer = 'flood-catchment-layer',
}

export const enum MapboxSourceLayer {
  pastoralStations = 'pastoral-stations-tileset-layer',
  lga = 'lga-tileset-layer',
  lgaCentres = 'lga-centres-tileset-layer',
  dfesRegions = 'dfes-regions-tileset-layer',
}

// Styles
export const enum MapboxStyle {
  default = 'default',
  satellite = 'satellite',
}

// Pollers
export const enum Poller {
  fetchAllHazards = 'fetch-all-hazards',
}

export const HazardPriorityMapping: Record<(typeof EntitySubType)[keyof typeof EntitySubType], number> = {
  // Warnings
  [WarningSubTypes.BushfireEmergencyWarningWarningEntity]: 6.99,
  [WarningSubTypes.CycloneEmergencyWarningWarningEntity]: 6.98,
  [WarningSubTypes.FloodEmergencyWarningWarningEntity]: 6.97,
  [WarningSubTypes.StormEmergencyWarningWarningEntity]: 6.96,
  [WarningSubTypes.StormSurgeEmergencyWarningWarningEntity]: 6.95,
  [WarningSubTypes.CoastalHazardEmergencyWarningWarningEntity]: 6.94,
  [WarningSubTypes.DamagingWindsEmergencyWarningWarningEntity]: 6.93,
  [WarningSubTypes.HeatwaveEmergencyWarningWarningEntity]: 6.92,
  [FdrSubType.CatastrophicFdrEntity]: 6.91,
  [WarningSubTypes.HazmatGeneralWarningWarningEntity]: 6.9,
  [WarningSubTypes.TsunamiLandWarningWarningEntity]: 6.89,
  [WarningSubTypes.TsunamiMarineWarningWarningEntity]: 6.88,

  [WarningSubTypes.BushfireWatchAndActWarningEntity]: 5.99,
  [WarningSubTypes.CycloneWatchAndActWarningEntity]: 5.98,
  [WarningSubTypes.FloodWatchAndActWarningEntity]: 5.97,
  [WarningSubTypes.StormWatchAndActWarningEntity]: 5.96,
  [WarningSubTypes.StormSurgeWatchAndActWarningEntity]: 5.95,
  [WarningSubTypes.CoastalHazardWatchAndActWarningEntity]: 5.94,
  [WarningSubTypes.DamagingWindsWatchAndActWarningEntity]: 5.93,
  [WarningSubTypes.HeatwaveWatchAndActWarningEntity]: 5.92,
  [FdrSubType.ExtremeFdrEntity]: 5.91,
  [WarningSubTypes.TsunamiWatchWarningEntity]: 5.9,

  [WarningSubTypes.BushfireAdviceWarningEntity]: 4.99,
  [WarningSubTypes.CycloneAdviceWarningEntity]: 4.98,
  [WarningSubTypes.FloodAdviceWarningEntity]: 4.97,
  [WarningSubTypes.StormAdviceWarningEntity]: 4.96,
  [WarningSubTypes.StormSurgeAdviceWarningEntity]: 4.95,
  [WarningSubTypes.CoastalHazardAdviceWarningEntity]: 4.94,
  [WarningSubTypes.DamagingWindsAdviceWarningEntity]: 4.93,
  [WarningSubTypes.HeatwaveAdviceWarningEntity]: 4.92,
  [FdrSubType.HighFdrEntity]: 4.91,
  [WarningSubTypes.EarthquakeAlertWarningEntity]: 4.9,
  [WarningSubTypes.AdviceWarningEntity]: 4.89,
  [WarningSubTypes.CommunityInformationWarningEntity]: 4.88,
  [WarningSubTypes.SmokeAlertWarningEntity]: 4.87,
  [WarningSubTypes.StructureFireAdviceWarningEntity]: 4.86,
  [WarningSubTypes.TotalFireBanWarningEntity]: 4.85,

  [WarningSubTypes.EarthquakeFinalAlertWarningEntity]: 3.97,
  [WarningSubTypes.HazmatControlledContainedWarningEntity]: 3.95,
  [WarningSubTypes.TsunamiAllClearWarningEntity]: 3.93,
  [WarningSubTypes.StructureFireAllClearWarningEntity]: 3.92,
  [CentreSubType.EvacuationCentreEntity]: 3.91,
  [CentreSubType.EvacuationRespiteCentreEntity]: 3.9,
  [FdrSubType.ModerateFdrEntity]: 3.89,

  [WarningSubTypes.OtherWarningWarningEntity]: 0,
  [WarningSubTypes.AlertWarningEntity]: 0,
  [WarningSubTypes.WarningStateWideWarningEntity]: 0,
  [WarningSubTypes.WarningLocationSpecificWarningEntity]: 0,
  [FdrSubType.NoRatingFdrEntity]: 0,

  [IncidentSubType.BushfireIncidentEntity]: -1,
  [IncidentSubType.HazardousMaterialsIncidentEntity]: -1,
  [IncidentSubType.CoastalHazardIncidentEntity]: -1,
  [IncidentSubType.DamagingWindsIncidentEntity]: -1,
  [IncidentSubType.StormSurgeIncidentEntity]: -1,
  [IncidentSubType.OtherIncidentEntity]: -1,
  [IncidentSubType.StormIncidentEntity]: -1,
  [IncidentSubType.StructureFireIncidentEntity]: -1,
  [IncidentSubType.TsunamiIncidentEntity]: -1,
  [IncidentSubType.CycloneIncidentEntity]: -1,
  [IncidentSubType.EarthquakeIncidentEntity]: -1,
  [IncidentSubType.FloodIncidentEntity]: -1,
  [IncidentSubType.HumanPandemicIncidentEntity]: -1,
  [IncidentSubType.HumanEpidemicIncidentEntity]: -1,
  [IncidentSubType.RescueIncidentEntity]: -1,
  [IncidentSubType.StormDamageIncidentEntity]: -1,
  [ClosureSubType.SchoolClosureEntity]: -1,
  [ClosureSubType.RoadClosureEntity]: -1,
  [ClosureSubType.FacilityOrParkClosureEntity]: -1,

  [WarningSubTypes.PowerOutageWarningEntity]: -2,
  [WarningSubTypes.NoTotalFireBanWarningEntity]: -2,

  // Events - These do not need to be displayed on the map
  [EventSubType.BushfireEventEntity]: -3,
  [EventSubType.HazardousMaterialsEventEntity]: -3,
  [EventSubType.OtherEventEntity]: -3,
  [EventSubType.StormEventEntity]: -3,
  [EventSubType.StructureFireEventEntity]: -3,
  [EventSubType.TsunamiEventEntity]: -3,
  [EventSubType.CycloneEventEntity]: -3,
  [EventSubType.EarthquakeEventEntity]: -3,
  [EventSubType.FloodEventEntity]: -3,
  [EventSubType.HumanPandemicEventEntity]: -3,
  [EventSubType.HumanEpidemicEventEntity]: -3,
  [EventSubType.RescueEventEntity]: -3,
  [EventSubType.StormDamageEventEntity]: -3,
  [EventSubType.SmokeAlertEventEntity]: -3,
  [EventSubType.TotalFireBanEventEntity]: -3,
  [EventSubType.HeatwaveEventEntity]: -3,
  [EventSubType.PandemicEventEntity]: -3,
  [EventSubType.SchoolClosureEventEntity]: -3,
  [EventSubType.PowerOutageEventEntity]: -3,
  [EventSubType.EvacuationCentreEventEntity]: 0,
  [EventSubType.OtherDBCAEventEntity]: 0,
  [EventSubType.OtherDFESEventEntity]: 0,
};
