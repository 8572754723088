export enum MapGuidePage {
  Polygons = 'Polygon areas',
  Weather = 'Weather layers',
  FireDanger = 'Fire Danger Ratings',
  TotalFireBans = 'Total Fire Bans',
}

export enum MapGuideFireDanger {
  Moderate = 'Moderate',
  High = 'High',
  Extreme = 'Extreme',
  Catastrophic = 'Catastrophic',
}

export const sanitizePageId = (page: string) => {
  return page.toLowerCase().replace(/\W/g, '_');
};

export interface FireDangerGuide {
  whatDoesItMean: string;
  whatShouldIDoPreLink: string;
  displayLink: boolean;
  whatShouldIDoPostLink?: string;
  extraText?: string;
}

export const fireDangerGuides: Record<MapGuideFireDanger, FireDangerGuide> = {
  [MapGuideFireDanger.Moderate]: {
    whatDoesItMean:
      'A Moderate Fire Danger Rating means that most fires can be controlled but you still need to stay up to date and be alert for fires in your area.',
    whatShouldIDoPreLink: 'Check your bushfire plan. If you do not have a plan, make one by visiting',
    displayLink: true,
    whatShouldIDoPostLink: '.',
  },
  [MapGuideFireDanger.High]: {
    whatDoesItMean:
      'A High Fire Danger Rating means that fires can be dangerous and you must stay alert and be ready to act. If a fire starts, your life and property may be at risk.',
    whatShouldIDoPreLink: 'Review your bushfire plan and if you do not have a plan, make one by visiting',
    displayLink: true,
    whatShouldIDoPostLink: '. A bushfire plan will help you decide what you will do if a fire starts.',
    extraText: 'If a fire starts, be ready to act.',
  },
  [MapGuideFireDanger.Extreme]: {
    whatDoesItMean:
      'An Extreme Fire Danger Rating means that fires will spread quickly and can be extremely dangerous. If a fire starts and takes hold, it will be uncontrollable, unpredictable and fast moving.',
    whatShouldIDoPreLink: 'Put your bushfire plan into action. If you do not have a plan, visit ',
    displayLink: true,
    whatShouldIDoPostLink:
      ' to decide when you will leave and where you will go. If you and your property are not prepared to the highest level, plan to leave early, and avoid travel through bushfire risk areas.',
    extraText: 'If a fire starts, take action immediately to protect your life and property.',
  },
  [MapGuideFireDanger.Catastrophic]: {
    whatDoesItMean:
      "A Catastrophic Fire Danger Rating presents the worst conditions for a fire. If a fire starts and takes hold, lives are likely to be lost as homes cannot withstand fires in these conditions and help may not be available. For your survival, it's best to leave bushfire risk areas early.",
    whatShouldIDoPreLink:
      'The safest option is to leave bushfire risk areas the night before or early in the day - do not wait and see. In these conditions, it may be too late to make a bushfire plan. Prepare your emergency kit and decide early where you will go and how you will get there.',
    displayLink: false,
    extraText: 'If a fire starts near you, take action immediately to protect your life. Do not wait for a warning.',
  },
};
