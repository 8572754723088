<template>
  <div class="flex items-center rounded-t-lg border-b border-grey-50 bg-grey-25 px-6 py-4">
    <div class="flex flex-1 items-center">
      <gv-button variant="text" scheme="custom" size="custom" type="button" @click="onClose">
        <gv-icon name="close" aria-label="Close" size="sm" class="fill-grey-300" />
      </gv-button>
    </div>

    <gv-type scheme="secondary">
      {{ title }}
    </gv-type>

    <div class="flex flex-1 items-center">
      <slot name="right" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UseModal } from '@/common/composables/useModal';
import { PropType } from 'vue';

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const props = defineProps({
  modal: {
    type: Object as PropType<UseModal>,
    required: true,
  },
  title: {
    type: String,
  },
});

const onClose = () => {
  props.modal.setClosed();
  emit('close');
};
</script>
