<template>
  <header>
    <ui-skip-to-main-link />
    <div class="flex h-header items-center justify-between bg-primary-800">
      <div class="ml-6 flex items-center gap-6">
        <a class="flex items-center gap-6" href="/">
          <wa-government-logo class="hidden 2xs:flex" aria-hidden="true" />
          <emergency-wa-partial-logo class="2xs:hidden" aria-label="EmergencyWA" aria-hidden="true" />

          <div class="hidden h-10 w-px bg-primary-400 2xs:flex" />
          <emergency-wa-full-logo class="hidden 2xs:flex" aria-label="EmergencyWA" aria-hidden="true" />

          <span class="sr-only">Government of Western Australia - EmergencyWA</span>
        </a>
        <ui-header-nav-bar class="hidden md:flex" />
      </div>

      <ui-emergency-button class="flex xs:hidden" />

      <div class="flex items-center gap-12 md:mr-6">
        <ui-emergency-button class="hidden xs:flex" />
        <nav class="md:hidden">
          <ui-header-hamburger-menu-button v-model="menuOpen" aria-controls="hamburger-menu" />
          <ui-header-hamburger-menu id="hamburger-menu" v-model="menuOpen" />
        </nav>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import WaGovernmentLogo from '@/common/assets/logos/wa-government-logo.svg';
import EmergencyWaFullLogo from '@/common/assets/logos/emergency-wa-full-logo.svg';
import EmergencyWaPartialLogo from '@/common/assets/logos/emergency-wa-partial-logo.svg';
import { onUnmounted, ref, watch } from 'vue';
import { useEventBus } from '@/composables';

const menuOpen = ref(false);

const eventBus = useEventBus();

watch(menuOpen, () => {
  eventBus.emit('action-with-parameters', {
    action: 'mobile-nav-menu-state',
    params: { value: menuOpen.value },
  });
});

onUnmounted(() => {
  eventBus.offActionWithParameters('mobile-nav-menu-state');
});
</script>
