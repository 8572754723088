<template>
  <div class="flex max-w-96 flex-col justify-between rounded-lg px-4 pb-4 pt-2">
    <div class="flex w-full">
      <button class="ml-auto flex size-6 min-h-6 min-w-6 cursor-pointer items-center justify-center" @click="map.closePopup()">
        <close-rounded aria-label="Close" class="*:fill-type-primary" />
      </button>
    </div>
    <p>
      The Incident Area indicates where a fire or other incident has been active. It is not a real-time reflection of current incident
      activity and does not indicate that property damage or loss has occurred. While this information is updated regularly, emergencies can
      escalate quickly and the Incident Area may not show the full extent of the incident. Always check the date and time listed on the
      incident area, follow the advice in official warnings and do not rely on solely the Incident Area shape to guide your decision-making.
      If in doubt, act immediately to stay safe.
    </p>
  </div>
</template>

<script setup lang="ts">
import CloseRounded from '@/common/assets/icons/close-rounded.svg';
import { useMapStore } from '@/features';

const map = useMapStore();
</script>
