import {
  HAZARD_MARKERS,
  Scheme,
  UiListTileBackgroundColor,
  UiListTileLeadingColor,
  WarningSubTypes,
  WarningSubType,
} from '@/features/hazards';

type WarningsToSchemeMap = Record<WarningSubType, Scheme>;

export const warningsToSchemeMap: WarningsToSchemeMap = {
  [WarningSubTypes.BushfireAdviceWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Yellow,
    icon: HAZARD_MARKERS['ew-bushfire--advice'],
  },
  [WarningSubTypes.BushfireWatchAndActWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Orange,
    icon: HAZARD_MARKERS['ew-bushfire--watch-and-act'],
  },
  [WarningSubTypes.BushfireEmergencyWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-bushfire--emergency-warning'],
  },

  // Coastal Hazards
  [WarningSubTypes.CoastalHazardAdviceWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Yellow,
    icon: HAZARD_MARKERS['ew-coastal-hazard--advice'],
  },
  [WarningSubTypes.CoastalHazardWatchAndActWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Orange,
    icon: HAZARD_MARKERS['ew-coastal-hazard--watch-and-act'],
  },
  [WarningSubTypes.CoastalHazardEmergencyWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-coastal-hazard--emergency-warning'],
  },

  // Cyclone
  [WarningSubTypes.CycloneAdviceWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Yellow,
    icon: HAZARD_MARKERS['ew-cyclone--advice'],
  },
  [WarningSubTypes.CycloneWatchAndActWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Orange,
    icon: HAZARD_MARKERS['ew-cyclone--watch-and-act'],
  },
  [WarningSubTypes.CycloneEmergencyWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-cyclone--emergency-warning'],
  },

  // Damaging Winds
  [WarningSubTypes.DamagingWindsAdviceWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Yellow,
    icon: HAZARD_MARKERS['ew-damaging-winds--advice'],
  },
  [WarningSubTypes.DamagingWindsWatchAndActWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Orange,
    icon: HAZARD_MARKERS['ew-damaging-winds--watch-and-act'],
  },
  [WarningSubTypes.DamagingWindsEmergencyWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-damaging-winds--emergency-warning'],
  },

  // Earthquake
  [WarningSubTypes.EarthquakeAlertWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Blue,
    icon: HAZARD_MARKERS['ew-earthquake-alert'],
  },
  [WarningSubTypes.EarthquakeFinalAlertWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Gray,
    icon: HAZARD_MARKERS['ew-earthquake-final-alert'],
  },

  // Flood
  [WarningSubTypes.FloodAdviceWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Yellow,
    icon: HAZARD_MARKERS['ew-flood--advice'],
  },
  [WarningSubTypes.FloodWatchAndActWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Orange,
    icon: HAZARD_MARKERS['ew-flood--watch-and-act'],
  },
  [WarningSubTypes.FloodEmergencyWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-flood--emergency-warning'],
  },

  // Hazardous Materials
  [WarningSubTypes.HazmatControlledContainedWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Gray,
    icon: HAZARD_MARKERS['ew-hazmat-controlled-contained'],
  },
  [WarningSubTypes.HazmatGeneralWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-hazmat-general-warning'],
  },

  // Heatwave
  [WarningSubTypes.HeatwaveAdviceWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Yellow,
    icon: HAZARD_MARKERS['ew-heatwave--advice'],
  },
  [WarningSubTypes.HeatwaveWatchAndActWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Orange,
    icon: HAZARD_MARKERS['ew-heatwave--watch-and-act'],
  },
  [WarningSubTypes.HeatwaveEmergencyWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-heatwave--emergency-warning'],
  },

  // Smoke
  [WarningSubTypes.SmokeAlertWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Gray,
    icon: HAZARD_MARKERS['ew-smoke-alert'],
  },

  // Storm
  [WarningSubTypes.StormAdviceWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Yellow,
    icon: HAZARD_MARKERS['ew-storm--advice'],
  },
  [WarningSubTypes.StormWatchAndActWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Orange,
    icon: HAZARD_MARKERS['ew-storm--watch-and-act'],
  },
  [WarningSubTypes.StormEmergencyWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-storm--emergency-warning'],
  },

  // Storm Surge
  [WarningSubTypes.StormSurgeAdviceWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Yellow,
    icon: HAZARD_MARKERS['ew-storm-surge--advice'],
  },
  [WarningSubTypes.StormSurgeWatchAndActWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Orange,
    icon: HAZARD_MARKERS['ew-storm-surge--watch-and-act'],
  },
  [WarningSubTypes.StormSurgeEmergencyWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-storm-surge--emergency-warning'],
  },

  // Structure Fire
  [WarningSubTypes.StructureFireAdviceWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Black,
    icon: HAZARD_MARKERS['ew-structure-fire-warning'],
  },
  [WarningSubTypes.StructureFireAllClearWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Gray,
    icon: HAZARD_MARKERS['ew-structure-fire-warning'],
  },

  // Tsunami
  [WarningSubTypes.TsunamiAllClearWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Gray,
    icon: HAZARD_MARKERS['ew-tsunami-all-clear'],
  },
  [WarningSubTypes.TsunamiLandWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-tsunami-land-warning'],
  },
  [WarningSubTypes.TsunamiMarineWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Red,
    icon: HAZARD_MARKERS['ew-tsunami-marine-warning'],
  },
  [WarningSubTypes.TsunamiWatchWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Yellow,
    icon: HAZARD_MARKERS['ew-tsunami-watch'],
  },

  // Total Fire Ban
  [WarningSubTypes.TotalFireBanWarningEntity]: {
    icon: HAZARD_MARKERS['ew-total-fire-ban'],
    leadingColor: UiListTileLeadingColor.Red,
    backgroundColor: UiListTileBackgroundColor.Pink,
  },
  [WarningSubTypes.NoTotalFireBanWarningEntity]: {
    icon: HAZARD_MARKERS['ew-no-total-fire-ban'],
  },

  // Other
  [WarningSubTypes.OtherWarningWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Black,
    icon: HAZARD_MARKERS['ew-other'],
  },

  // Miscellaneous
  [WarningSubTypes.WarningLocationSpecificWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Black,
    icon: HAZARD_MARKERS['ew-warning-location-specific'],
  },
  [WarningSubTypes.PowerOutageWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Black,
    icon: HAZARD_MARKERS['ew-power-outage'],
  },
  [WarningSubTypes.WarningStateWideWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Black,
    icon: HAZARD_MARKERS['ew-state-wide-warning'],
  },
  [WarningSubTypes.CommunityInformationWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Black,
    icon: HAZARD_MARKERS['ew-community-information'],
  },
  [WarningSubTypes.AdviceWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Black,
    icon: HAZARD_MARKERS['ew-advice'],
  },
  [WarningSubTypes.AlertWarningEntity]: {
    leadingColor: UiListTileLeadingColor.Black,
    icon: HAZARD_MARKERS['ew-alert'],
  },
};
