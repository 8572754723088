import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';

import { Ref, ref } from 'vue';
import { UseToggle } from './useToggle';

export type UseBottomSheet = {
  setOpen: UseToggle['setOn'];
  setClosed: UseToggle['setOff'];
  setRef: (bottomSheet: InstanceType<typeof VueBottomSheet>) => void;
};

let bottomSheet: UseBottomSheet;

export const useBottomSheet = (): UseBottomSheet => {
  const bottomSheetRef: Ref<null | InstanceType<typeof VueBottomSheet>> = ref(null);

  const setRef = (bottomSheet: InstanceType<typeof VueBottomSheet>) => {
    bottomSheetRef.value = bottomSheet;
  };

  const setOpen = () => {
    bottomSheetRef.value?.open();
  };
  const setClosed = () => {
    bottomSheetRef.value?.close();
  };

  bottomSheet = {
    setOpen,
    setClosed,
    setRef,
  };

  return bottomSheet;
};
