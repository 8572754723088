<template>
  <vue-bottom-sheet ref="bottomSheetRef" :transition-duration="0.1" :max-width="null as unknown as undefined">
    <div class="p-4 pt-0">
      <slot />
    </div>
  </vue-bottom-sheet>
</template>

<script lang="ts" setup>
import { UseBottomSheet } from '@/common';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import '@webzlodimir/vue-bottom-sheet/dist/style.css';
import { onMounted, ref } from 'vue';

const props = defineProps<{
  bottomSheet: UseBottomSheet;
}>();

const bottomSheetRef = ref<InstanceType<typeof VueBottomSheet>>();

onMounted(() => {
  props.bottomSheet.setRef(bottomSheetRef.value!);
});
</script>

<style>
.bottom-sheet__draggable-thumb {
  background-color: #d4d4d8 !important;
}

.bottom-sheet__content {
  height: unset !important;
}
</style>
