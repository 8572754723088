<template>
  <div
    class="flex w-[327px] flex-col items-center justify-center font-proxima-nova 3xs:w-[366px] 2xs:w-[400px] xs:w-[496px]"
    :style="maxPopupElementSize"
  >
    <ui-map-incident-popup v-if="hazardType === HazardType.Incident" :event-id="eventId" />
    <ui-map-prescribed-burn-popup v-else-if="hazardType === HazardType.PrescribedBurns" />
    <ui-fire-danger-rating-popup v-else-if="hazardType === HazardType.FireDangerRating" />
    <ui-fire-ban-popup v-else-if="hazardType === HazardType.TotalFireBans" :coordinates="coordinates" />
    <ui-map-warnings-popup v-else :event-id="eventId" :hazard-id="hazardId" :hazard-type="hazardType" />
  </div>
</template>

<script setup lang="ts">
import { HazardType, useMapStore } from '@/features/hazards';
import { LngLatLike } from 'mapbox-gl';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

defineProps<{
  hazardType: HazardType;
  hazardId: string;
  eventId: string;
  coordinates: LngLatLike;
}>();

const mapStore = useMapStore();

const { mapElementSize } = storeToRefs(mapStore);

const maxPopupElementSize = computed(() => {
  return {
    'max-height': mapElementSize.value.height - 48 + 'px',
  };
});
</script>
