/**
 * Determines whether to user DateTime.toRelative() or DateTime.toRelativeCalendar()
 * when formatting the date.
 *
 * default: Use DateTime.toRelative()
 * calendar: Use DateTime.toRelativeCalendar()
 */
export enum RelativeDateTimeFormat {
  default = 'default',
  calendar = 'calendar',
}
