import { Configuration } from './types';

export const config: Partial<Configuration> = {
  portalApi: 'https://ewa.api.dev.portal.genvis.com/v1',
  remoteHazardAssets: {
    iconAssetsPathSvg: 'https://dev.kudo.genvis.com/assets/icons/map/pins',
    iconAssetsPathPng: 'https://dev.kudo.genvis.com/assets/icons/map/pins/40x40',
  },
  geospatialBucket: 'https://d1ro7pgfug9vfo.cloudfront.net',
  externalTileBaseUrl: 'https://d32hjt8pptb5el.cloudfront.net',
  webTemplateStyleSheetUrl: 'https://dev.kudo.genvis.com/assets/styles/ewa-webtemplate.css',
  environment: 'local',
};
