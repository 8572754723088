import sanitizeHtml from 'sanitize-html';
import { ALLOWED_TAGS } from '../constants';

export const useSanitiseHtml = (html?: string) => {
  return sanitizeHtml(html ?? '', {
    allowedTags: ALLOWED_TAGS,
    allowedAttributes: {
      a: ['href', 'name', 'target', 'class'],
    },
  });
};
